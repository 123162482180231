import { useContext } from 'react';
import { DataContext } from '@visx/xychart';

export type LineAnnotationProps = {
    xLines?: { value: number; color: string }[];
    yLines?: { value: number; color: string }[];
};

export function LineAnnotation({ xLines = [], yLines = [] }: LineAnnotationProps) {
    const { xScale, yScale, width, height } = useContext(DataContext);

    if (xLines.length + yLines.length === 0 || !xScale || !yScale) {
        return null;
    }

    return (
        <g transform="translate(100,0)">
            {xLines.map(({ value, color }) => (
                <line
                    x1={0}
                    y1={yScale(value) as number}
                    x2={(width || 140) - 140}
                    y2={yScale(value) as number}
                    stroke={color}
                />
            ))}
            {yLines.map(({ value, color }) => (
                <line x1={xScale(value) as number} y1={0} x2={xScale(value) as number} y2={height} stroke={color} />
            ))}
        </g>
    );
}
