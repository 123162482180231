import { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import cn from 'classnames';
import styles from './ColumnHeaderName.module.css';

interface ColumnHeaderNameProps extends PropsWithChildren {
    className?: string;
}

export const ColumnHeaderName = ({ children, className }: ColumnHeaderNameProps) => {
    return <Typography className={cn(styles.columnHeader, className)}>{children}</Typography>;
};
