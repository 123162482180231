import { gql } from '@apollo/client';

// 'status' field temporary removed
export const CREATE_CASE_FORM = gql`
    mutation createCaseForm($input: CaseFormInput!) {
        createCaseForm(input: $input) {
            caseForm {
                id
                title
                content
                version
                comments {
                    id
                    text
                    timestamp
                    user {
                        id
                        email
                        firstName
                        lastName
                    }
                }
                createdAt
                createdBy {
                    email
                    firstName
                    lastName
                }
                lastEditedAt
                lastEditedBy {
                    email
                    firstName
                    lastName
                }
                lastReviewedAt
                lastReviewedBy {
                    email
                    firstName
                    lastName
                }
            }
        }
    }
`;
