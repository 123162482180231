import { useState, useEffect, useCallback, useMemo } from 'react';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import useC4Auth, { LoginCredentials, AuthUris } from '@c4ads/c4auth';
import { GET_USER } from '../apollo/query';
import AuthContext, { AuthContextInterface, LoginCallback } from './AuthContext';
import { User } from '../../types/user';

export interface AuthProviderProps {
    context?: React.Context<AuthContextInterface>;
    children?: React.ReactNode;
}

export const authUris: AuthUris = {
    login: process.env.REACT_APP_LOGIN_URI || '/api/users/login/',
    resetPassword: process.env.REACT_APP_RESET_PASSWORD_URI || '/api/users/reset_password/',
    signup: process.env.REACT_APP_SIGNUP_URI || '/api/users/',
};

const AuthProvider = ({ context = AuthContext, children }: AuthProviderProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [state, setState] = useState<Pick<AuthContextInterface, 'isAuthenticated' | 'user'>>(() => ({
        isAuthenticated: false,
        user: undefined,
    }));
    const { login, logout, resetPassword, signup } = useC4Auth({ uris: authUris });

    const { refetch } = useQuery<{ user?: User }>(GET_USER, {
        skip: state.isAuthenticated,
        onCompleted: (data) => {
            setState((prev) => ({ ...prev, user: data.user, isAuthenticated: true }));
            setIsLoading(false);
        },
        onError: () => {
            setState((prev) => ({ ...prev, isAuthenticated: false }));
            setIsLoading(false);
        },
        fetchPolicy: 'network-only',
    });

    const handleLogin = useCallback(
        (data: LoginCredentials, callback?: LoginCallback) => {
            login<any, string>(data, true).then((res) => {
                let userResponse: Promise<ApolloQueryResult<{ user?: User }>> | undefined;
                if (res && !res.error) {
                    setIsLoading(true);
                    userResponse = refetch();

                    userResponse.finally(() => {
                        setIsLoading(false);
                    });
                }
                if (callback && res) {
                    callback(res, userResponse);
                }
            });
        },
        [login, setState]
    );

    const handleLogout = useCallback(() => {
        logout();
        setState({ isAuthenticated: false, user: undefined });
    }, [logout, setState]);

    const handleResetPassword = useCallback(
        (email: string) => {
            resetPassword({ email: email });
        },
        [resetPassword]
    );

    const handleSignup = useCallback(
        async (data) => {
            const res = await signup(data);
            if (res && !res.error) {
                return true;
            }
            return false;
        },
        [signup]
    );

    useEffect(() => {}, [setState]);

    const contextValue = useMemo(() => {
        return {
            ...state,
            isLoading,
            updateUser: refetch,
            login: handleLogin,
            logout: handleLogout,
            resetPassword: handleResetPassword,
            signup: handleSignup,
        };
    }, [state, isLoading, handleLogin, handleLogout, handleResetPassword, handleSignup]);

    return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default AuthProvider;
