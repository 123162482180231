import React, { useMemo, useState } from 'react';
import { sortBy } from 'lodash';
import { Box, Checkbox, FormControlLabel, FormGroup, Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PermissionTitle } from './PermissionTitle';
import styles from './PermissionDropdown.module.css';

export interface Permission {
    id: string;
    code: string;
    name: string;
    checked: boolean;
}

interface PermissionDropdownProps {
    emptyTitle: string;
    permissions: Permission[];
    isAdmin?: boolean;
    onChange: (status: boolean, codeName: string) => void;
}

const getPermissionsState = (permissions: Permission[]) => {
    // state = Record<code, checked>
    const state: Record<string, boolean> = {};
    permissions.forEach((permission) => {
        state[permission.code] = permission.checked;
    });

    return state;
};

export const PermissionDropdown = ({ emptyTitle, isAdmin, permissions, onChange }: PermissionDropdownProps) => {
    const [initialPermissionsState] = useState(getPermissionsState(permissions));
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [permissionsState, setPermissionsState] = useState(initialPermissionsState);

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSelect = (code: string, checked: boolean) => {
        onChange(checked, code);
        setPermissionsState({ ...permissionsState, [code]: checked });
    };

    const sortedPermissions = useMemo(() => sortBy(permissions, ['name']), [permissions]);

    const open = Boolean(anchorEl);
    const id = open ? 'permission-dropdown' : undefined;

    return (
        <div className={styles.container}>
            <button className={styles.dropdownButton} onClick={onClick} aria-describedby={id}>
                <PermissionTitle
                    permissionsState={permissionsState}
                    permissions={permissions}
                    emptyTitle={emptyTitle}
                />
                <ArrowDropDownIcon className={styles.dropdownArrow} />
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <FormGroup>
                    <Box display="flex" flexDirection="column" className={styles.permissions}>
                        {sortedPermissions.map((permission) => (
                            <FormControlLabel
                                className={styles.permissionObject}
                                key={permission.id}
                                control={
                                    <Checkbox
                                        onChange={(e) => {
                                            onSelect(permission.code, e.target.checked);
                                        }}
                                        checked={
                                            permission.code === 'isAdmin' ? isAdmin : permissionsState[permission.code]
                                        }
                                        sx={{
                                            '& .MuiSvgIcon-root': { fontSize: 20 },
                                            color: '#B8BFC7',
                                            mr: 1,
                                            '&.Mui-checked': {
                                                color: '#122945',
                                            },
                                        }}
                                    />
                                }
                                label={permission.name}
                            />
                        ))}
                    </Box>
                </FormGroup>
            </Popover>
        </div>
    );
};
