import { gql } from '@apollo/client';

const GET_COURT_CASE = gql`
 query courtCase($id: String!) {
  courtCase(id: $id) {
    id
    docket
    name
    court {
      id
      name
    }
    caseDurationDays
    isConcluded
    isConviction
    defenseAttorneyCount
    firstEvent {
      id
      date
      stage
    }
    latestEvent {
      id
      date
      stage
    }
    seizures {
      id
      date
      locationName
      administrativeLevel1 {
        id
        name
      }
      officers {
        id
        firstName
        lastName
      }
      seizedCommodities {
        id
        species {
          id
          commonName
        }
        unit
        protectionLevel {
          step
          label
        }
        count
        weightGrams
        value
        valueCurrency
      }
    }
  }
  defendants {
    arrestDate
    person {
      id
      firstName
      lastName
      birthdate
      sex
    }
    nationality {
      id
      name
    }
    streetAddress
    addressAdministrativeLevel2 {
      name
    }
    addressAdministrativeLevel1 {
      name
    }
    addressRegion {
      name
    }
    addressCountry {
      name
    }
    defenseAttorneys {
      id
      firstName
      lastName
    }
    charges {
      id
      crime {
        legislation
        part
      }
      withdrawn
      plea
      verdict
    }
    fine
    suspendedFine
    custodialMonths
    suspendedMonths
    communityServiceHours
    bailEvents {
      date
      bailGranted
      bondAmount
    }
    pleaBargain
    outcomeFactors {
      impact
      factor
    }
  }
  hearings {
    id
    date
    stage
    happened
    court {
      id
      name
    }
    judge {
      id
      firstName
      lastName
    }
    courtClerk {
      id
      firstName
      lastName
    }
    prosecutorsPresent {
      id
      firstName
      lastName
    }
    defenseAttorneysPresent {
      id
      firstName
      lastName
    }
    defendantsPresent {
      id
      person {
        id
        firstName
        lastName
      }
    }
    notes
  }
}
`;

export default GET_COURT_CASE;