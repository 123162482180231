import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { Box, IconButton, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseIcon from '@mui/icons-material/Close';
import styles from './SearchInput.module.css';

interface SearchInputProps {
    placeholder?: string;
    onChange: (search: string) => void;
}

export const SearchInput = ({ placeholder, onChange }: SearchInputProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState('');
    const [searchInputOpen, setSearchInputOpen] = useState(false);

    const onOpenSearchInput = () => {
        const inputElement = ref.current;
        if (inputElement) {
            inputElement.querySelector('input')?.focus();
        }

        setSearchInputOpen(true);
    };

    const onCloseSearchInput = () => {
        setSearchInputOpen(false);
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchStr = e.target.value;

        setSearch(searchStr);
        onChange?.(searchStr);
    };

    return (
        <Box className={styles.searchInputContainer}>
            <Box
                display="flex"
                alignItems="center"
                className={cn(styles.searchContainer, searchInputOpen && styles.searchContainerOpen)}
            >
                <TextField
                    ref={ref}
                    autoFocus
                    placeholder={placeholder}
                    value={search}
                    onChange={onSearch}
                    className={styles.searchTextField}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={onCloseSearchInput} size="small">
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        ),
                    }}
                    variant="outlined"
                />
            </Box>
            <IconButton onClick={onOpenSearchInput}>
                <SearchOutlinedIcon className={styles.searchIcon} />
            </IconButton>
        </Box>
    );
};
