import React from 'react';
import cn from 'classnames';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { Box } from '@mui/material';
import { Label } from '../FormItem/Label';
import { Value } from '../FormItem/Value';
import { ERROR_CLASSNAME } from '../../constants';
import styles from './PhoneNumberInput.module.css';

interface PhoneNumberInputProps<T extends FieldValues> {
    name: Path<T>;
    label: string;
    control: Control<T, any>;
    errorMessage?: string;
    editMode: boolean;
}

export const PhoneNumberInput = <T extends FieldValues>({
    name,
    label,
    editMode,
    control,
    errorMessage,
}: PhoneNumberInputProps<T>) => {
    return (
        <Box display="flex" flexDirection="column" className={cn(styles.container, errorMessage && ERROR_CLASSNAME)}>
            <Label type={errorMessage ? 'error' : 'default'} content={label} />
            <Controller
                name={name}
                control={control}
                rules={{ validate: (value) => matchIsValidTel(value ?? '') }}
                render={({ field: { ref: fieldRef, value, ...fieldProps } }) => (
                    <>
                        {!editMode ? (
                            <Value content={value || 'N/A'} />
                        ) : (
                            <MuiTelInput
                                {...fieldProps}
                                className={styles.phoneNumberInput}
                                value={value ?? ''}
                                inputRef={fieldRef}
                                defaultCountry="US"
                                helperText={errorMessage}
                                error={!!errorMessage}
                                inputProps={{
                                    maxLength: 20,
                                }}
                            />
                        )}
                    </>
                )}
            />
        </Box>
    );
};
