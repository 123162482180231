import { useMemo, useState, useEffect } from 'react';
import { scaleQuantize } from '@visx/scale';
import { Mercator } from '@visx/geo';
import { Group } from '@visx/group';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

import { topology } from 'topojson-server';
import { feature } from 'topojson-client';

import Typography from '@mui/material/Typography';
import Legend from './components/Legend';
import { ChartWrapper } from '../ChartWrapper';
import { CHART_CONTENT_CLASSNAME, CHOROPLETH_LEGEND_CLASSNAME } from '../constants';

export default function Choropleth({ title, axes, description, width, height, data, palette }) {
    const [colorMarkup, setColorMarkup] = useState({});

    const geoData = useMemo(() => {
        const formatted = {
            type: 'FeatureCollection',
            features: data.map(({ geometry, ...properties }) => ({
                id: properties.key,
                properties: properties,
                geometry,
            })),
        };
        const topojson = topology({ regions: formatted });
        return feature(topojson, topojson.objects.regions);
    }, [data]);

    const color = scaleQuantize({
        domain: [
            Math.min(...geoData.features.map((f) => f.properties.data)),
            Math.max(...geoData.features.map((f) => f.properties.data)),
        ],
        range: palette,
    });

    const getFill = (data) => {
        return data ? color(data) : '#DBDBDB';
    };

    useEffect(() => {
        const features = geoData.features || [];

        const colorMarkup = {};
        features.forEach((feature) => {
            colorMarkup[feature.id] = getFill(feature.properties.data);
        });

        setColorMarkup(colorMarkup);
    }, [geoData]);

    const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        detectBounds: true,
        scroll: true,
    });

    const handleMouseEnter = (event, feature) => {
        const coords = localPoint(event.target.ownerSVGElement, event);
        showTooltip({
            tooltipLeft: coords?.x,
            tooltipTop: coords?.y,
            tooltipData: feature.properties,
        });
    };

    const handleMouseLeave = () => {
        hideTooltip();
    };

    const { showLegend, svgWidth, legendWidth } = useMemo(() => {
        let svgWidth = width;
        let legendWidth = 0;

        svgWidth = width * 0.6;
        legendWidth = width * 0.4;
        return { showLegend: true, svgWidth, legendWidth };
    }, [width]);

    const legendHeight = useMemo(() => {
        return Math.max(height - 112, 100);
    }, [height]);

    return (
        <ChartWrapper ref={containerRef} width={width} height={height} chartLabel={{ title, description }}>
            {({ chartTop, chartHeight }) => (
                <foreignObject y={chartTop} width={width} height={chartHeight}>
                    <svg className={CHART_CONTENT_CLASSNAME} width={width} height={chartHeight}>
                        <Mercator
                            data={geoData.features}
                            fitExtent={[
                                [
                                    [32, 32],
                                    [svgWidth, chartHeight],
                                ],
                                geoData,
                            ]}
                        >
                            {(mercator) => {
                                return (
                                    <Group>
                                        {mercator.features.map(({ feature, path }, i) => (
                                            <path
                                                key={`map-feature-${i}`}
                                                d={path || ''}
                                                fill={getFill(feature.properties.data)}
                                                stroke="white"
                                                strokeWidth={0.5}
                                                onMouseEnter={(event) => handleMouseEnter(event, feature)}
                                                onMouseLeave={() => handleMouseLeave()}
                                            />
                                        ))}
                                    </Group>
                                );
                            }}
                        </Mercator>
                        {tooltipOpen && (
                            <TooltipInPortal
                                key={Math.random()}
                                top={tooltipTop}
                                left={tooltipLeft}
                                style={{
                                    ...defaultStyles,
                                    padding: 8,
                                    width: 144,
                                    zIndex: 3,
                                    backgroundColor: palette[palette.length - 1],
                                    color: 'white',
                                }}
                            >
                                <Typography>{tooltipData?.label}</Typography>
                                <Typography>{tooltipData?.data}</Typography>
                            </TooltipInPortal>
                        )}
                        {showLegend && (
                            <foreignObject
                                className={CHOROPLETH_LEGEND_CLASSNAME}
                                x={svgWidth}
                                y={0}
                                height={legendHeight}
                                width={legendWidth}
                            >
                                <Legend
                                    colorMarkup={colorMarkup}
                                    axes={axes}
                                    data={geoData}
                                    height={legendHeight}
                                    width={legendWidth}
                                />
                            </foreignObject>
                        )}
                    </svg>
                </foreignObject>
            )}
        </ChartWrapper>
    );
}
