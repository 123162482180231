import { gql } from '@apollo/client';

const GET_PERSON = gql`
    query getPerson($id: ID!) {
        person(id: $id) {
            id
            firstName
            lastName
            birthdate
            sex
            roles {
                courtCase {
                    id
                    name
                    docket
                }
                role
            }
        }
    }
`;

export default GET_PERSON;
