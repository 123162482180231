import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { usePostHog } from 'posthog-js/react';
import Header, { HeaderContext } from './components/Header';
import Main from './components/Main';
import { routes, Login, ResetPassword } from './routes';
import ProtectedRoute from './components/ProtectedRoute';
import { RouteType } from './routes/types';
import { useAuth } from './api/auth';

function App() {
    const posthog = usePostHog();
    const { isAuthenticated, isLoading, user } = useAuth();
    const [hide, setHide] = useState<boolean>(false);

    useEffect(() => {
        if (isAuthenticated && user) {
            posthog?.identify(user.id, {
                email: user.email,
            });
        }
    }, [isAuthenticated, user]);

    return (
        <HeaderContext.Provider value={{ hide, setHide }}>
            <BrowserRouter>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    <Header />
                    <Main>
                        <Routes>
                            <Route key="Login" path="/login" element={<Login />} />
                            <Route key="RestorePassword" path="/reset-password" element={<ResetPassword />} />
                            <Route
                                element={
                                    <ProtectedRoute
                                        isAuthenticated={isAuthenticated}
                                        isLoading={isLoading}
                                        // redirectTo='/login'
                                    />
                                }
                            >
                                {routes.map(({ routeProps, name }: RouteType) => (
                                    <Route key={name} {...routeProps} />
                                ))}
                            </Route>
                        </Routes>
                    </Main>
                </LocalizationProvider>
            </BrowserRouter>
        </HeaderContext.Provider>
    );
}

export default App;
