import React, { ReactNode, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Dialog, DialogContent, DialogProps, DialogTitle, IconButton } from '@mui/material';
import styles from './CustomDialog.module.css';

interface CustomDialogProps {
    onClose: () => void;
    open: boolean;
    loading: boolean;
    children: ReactNode;
    title: string | ReactNode;
    disableBodyPadding?: boolean;
    maxWidth?: DialogProps['maxWidth'];
}

export const CustomDialog = ({
    open,
    loading,
    title,
    children,
    onClose,
    disableBodyPadding,
    maxWidth = 'xs',
}: CustomDialogProps) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        setDialogOpen(open);
    }, [open]);

    const handleClose = () => {
        setDialogOpen(false);
        onClose();
    };

    return (
        <Dialog
            sx={
                disableBodyPadding
                    ? {
                          '& .MuiDialog-container': {
                              '& .MuiDialogContent-root': {
                                  padding: 0,
                              },
                          },
                      }
                    : {}
            }
            open={dialogOpen}
            onClose={handleClose}
            fullWidth
            maxWidth={maxWidth}
        >
            {loading && (
                <div className={styles.loaderMask}>
                    <CircularProgress />
                </div>
            )}
            <IconButton className={styles.closeIcon} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};
