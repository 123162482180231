import { useEffect } from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import styles from './FormCheckbox.module.css';

interface FormCheckboxProps extends Omit<CheckboxProps, 'onChange'> {
    onChange?: (value: boolean) => void;
}

export const FormCheckbox = ({ onChange, ...props }: FormCheckboxProps) => {
    useEffect(() => {
        onChange && onChange(props.checked || false);
    }, []);

    return (
        <Checkbox
            {...props}
            onChange={(_e, newValue) => {
                onChange && onChange(newValue);
            }}
            size="small"
            className={styles.formCheckbox}
        />
    );
};
