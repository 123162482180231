import { Suspense } from 'react';
import { styled } from '@mui/material/styles';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { ChartProps } from '../types';
import ChartRenderer from '../ChartRenderer';

const StyledDiv = styled('div')(({theme}) => ({
    height: 'calc(100% - 32px)',
}));

const ChartContent = (chartProps: ChartProps) => {

    return (
        <Suspense fallback='Loading charts...'>
            <StyledDiv id="chart-content">
                    <ParentSize>
                        {({width, height}) => (
                            <ChartRenderer
                                {...chartProps}
                                width={width}
                                height={height}
                            />
                        )}
                    </ParentSize>
            </StyledDiv>
        </Suspense>
    )
};

export default ChartContent;