import { Grid } from '@mui/material';
import { Loader } from './index';

interface LoaderProps {
    className?: string;
    absolute?: boolean;
}

export const LoaderWrapper = ({ className, absolute }: LoaderProps) => {
    return (
        <Grid
            container
            className={className}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: '100%',
                position: absolute ? 'absolute' : 'fixed',
                top: 0,
                zIndex: 1,
                background: 'rgba(125,125,125,0.3)',
            }}
        >
            <Grid item>
                <Loader />
            </Grid>
        </Grid>
    );
};
