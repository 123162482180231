import { gql } from '@apollo/client';

export const GET_LOOKUPS = gql`
    query lookups {
        lookups {
            currency
            agencies {
                id
                name
            }
            hearingStages
            outcomeFactors {
                impact
                factor
            }
            crimes {
                id
                legislation
                part
                penaltyPart
            }
            courts {
                id
                name
            }
            administrativeLevel2s {
                localName
                values {
                    id
                    name
                }
            }
            administrativeLevel1s {
                localName
                values {
                    id
                    name
                }
            }
            regions {
                localName
                values {
                    id
                    name
                }
            }
            countries {
                id
                name
            }
            species {
                id
                tracked
                kingdom
                phylum
                Class
                order
                family
                genus
                scientificName
                commonName
                aliases
                speciesGroup {
                    id
                    name
                }
            }
            speciesGroups {
                id
                name
            }
            protectionLevels {
                id
                step
                label
            }
        }
    }
`;
