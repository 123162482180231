import { Typography } from '@mui/material';
import cn from 'classnames';
import styles from './FormItem.module.css';

interface LabelProps {
    type?: 'default' | 'error';
    content: string;
    onClick?: () => void;
    className?: string;
}

export const Label = ({ type, content, onClick, className }: LabelProps) => {
    return (
        <Typography onClick={onClick} className={cn(styles.label, type === 'error' && styles.errorLabel, className)}>
            {content}
        </Typography>
    );
};

export const EmptyLabel = () => {
    return <Label content="&emsp;" />;
};
