import { Tooltip, Typography, TooltipProps } from '@mui/material';
import styles from './CustomTooltip.module.css';

export const CustomTooltip = ({ title, children, ...props }: TooltipProps) => {
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    className: styles.tooltip,
                },
            }}
            title={<Typography className={styles.title}>{title}</Typography>}
            {...props}
        >
            {children}
        </Tooltip>
    );
};
