import { gql } from '@apollo/client';

const CREATE_PERSON = gql`
    mutation createPerson($input: CreatePersonInputType!) {
        createPerson(input: $input) {
            person {
                id
                firstName
                lastName
                birthdate
                sex
            }
        }
    }
`;

export default CREATE_PERSON;
