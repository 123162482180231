import {
    createContext,
    forwardRef,
    HTMLAttributes,
    ReactNode,
    ReactElement,
    SyntheticEvent,
    useContext,
    useRef,
    useEffect,
} from 'react';
import { Autocomplete, Typography } from '@mui/material';
import cn from 'classnames';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TextField } from '../TextField';
import styles from './Autocomplete.module.css';

// Virtualized autocomplete, example from https://mui.com/material-ui/react-autocomplete/#virtualization

type AutocompleteOnChangeCallback<T> = (e: SyntheticEvent, value: T | null) => void;

type FormAutocompleteBaseProps<T> = {
    disabled?: boolean;
    label?: ReactNode;
    options: T[];
    className?: string;
    disablePortal?: boolean;
    groupBy?: (option: T) => string;
    getOptionLabel?: (option: T) => string;
    getOptionKey?: (option: T) => string;
    errorMessage?: string;
    noOptionsText?: string;
    disableVirtualization?: boolean;
};

type FormAutoCompleteProps<T> =
    | (FormAutocompleteBaseProps<T> & {
          value?: T;
          multiple?: false;
          onChange?: AutocompleteOnChangeCallback<T>;
      })
    | (FormAutocompleteBaseProps<T> & {
          value?: T[];
          multiple?: true;
          onChange?: AutocompleteOnChangeCallback<T[]>;
      });

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    };
    const { key, ...optionProps } = dataSet;

    return <Typography key={key} component="li" {...optionProps.props} noWrap style={inlineStyle} />;
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
};

// Adapter for react-window
const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ReactElement[] = [];
    (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: ReactElement) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref} style={{ zIndex: 9999 }}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export const FormAutocomplete = <T,>({
    disabled,
    label,
    options,
    className,
    value,
    onChange,
    groupBy,
    getOptionKey: propGetOptionKey,
    getOptionLabel,
    multiple,
    disablePortal = true,
    errorMessage,
    noOptionsText = 'No options',
    disableVirtualization = false,
}: FormAutoCompleteProps<T>) => {
    const getOptionKey = (option: any) => {
        return option?.id as string;
    };

    return (
        <Autocomplete
            noOptionsText={noOptionsText}
            disabled={disabled}
            disableCloseOnSelect
            disablePortal={disablePortal}
            multiple={multiple}
            options={options}
            groupBy={groupBy}
            getOptionKey={propGetOptionKey || getOptionKey}
            className={cn(styles.autocomplete, className)}
            ListboxComponent={disableVirtualization ? undefined : ListboxComponent}
            onChange={(e, value) => {
                // @ts-ignore
                onChange && onChange(e, value);
            }}
            ChipProps={{
                size: 'small',
                variant: 'outlined',
                className: styles.chip,
            }}
            value={value}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => <TextField errorMessage={errorMessage} {...params} label={label} />}
        />
    );
};
