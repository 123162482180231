import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';
import { Alert, Snackbar } from '@mui/material';
import { formatAllDates, mergeCaseForms, removeAll, saveCaseForm } from './caseFormUtils';
import apolloClient from '../../../../../api/apollo';
import { GET_LOOKUPS } from '../../../../../api/apollo/query/GetLookups';
import { DELETE_CASE_FORM, UPDATE_CASE_FORM } from '../../../../../api/apollo/mutation';
import { GET_CASE_FORM, GET_HEARINGS_PRESENT } from '../../../../../api/apollo/query';
import { CaseFormStatuses } from '../../../../../constants/local';
import { camelize, deeplyReplaceValues } from '../../../../../helpers';
import { parseDate } from '../../../../../helpers/datetime';
import { CaseFormHearingsPresentResponse } from '../../../../../types/CaseFormHearingsPresent';
import { useFormsStore } from '../store';
import { defaultSectionFormInfo } from './constants';
import { Callback, CleanCallback, SectionKey } from './types';
import { PartialRecord, CaseFormsLookupsResponse, CaseFormType } from '../../../../../types';

interface FormsContextInterface {
    edit: () => void;
    save: () => Promise<void>;
    requestReview: () => Promise<void>;
    approve: () => Promise<void>;
    requestChanges: () => Promise<void>;
    removeLocalChanges: (reloadPage?: boolean) => void;
    onSubmitListener: (sectionKey: SectionKey, callback: Callback) => CleanCallback;
    removeCaseForm: () => Promise<void>;
    setSectionStatus: (sectionName: SectionKey, isValid: boolean) => void;
}

interface FormsContextProviderProps extends PropsWithChildren {
    id: string;
    caseForm: CaseFormType;
}

const prepareServerSectionFormsContent = (content: string) => {
    let parsed: any;
    try {
        parsed = camelize(JSON.parse(content));
    } catch (e) {
        throw new Error('Case forms response content is broken.');
    }

    parsed = deeplyReplaceValues(parsed, null, (currentValue: any) => {
        try {
            const date = parseDate(currentValue);
            if (!date || date.toString() === 'Invalid Date') {
                return currentValue;
            }
            return date;
        } catch {
            return currentValue;
        }
    });

    return mergeCaseForms(
        {
            hearings: defaultSectionFormInfo,
            defendants: defaultSectionFormInfo,
            seizures: defaultSectionFormInfo,
            'court-case': defaultSectionFormInfo,
        },
        {
            hearings: {
                status: 'sync',
                payload: parsed['hearings'],
            },
            defendants: {
                status: 'sync',
                payload: parsed['defendants'],
            },
            seizures: {
                status: 'sync',
                payload: parsed['seizures'],
            },
            'court-case': {
                status: 'sync',
                payload: parsed['courtCase'],
            },
        }
    );
};

const initialState: FormsContextInterface = {
    edit: () => {},
    save: () => Promise.resolve(),
    requestReview: () => Promise.resolve(),
    approve: () => Promise.resolve(),
    requestChanges: () => Promise.resolve(),
    onSubmitListener: () => () => {},
    removeLocalChanges: () => {},
    removeCaseForm: () => Promise.resolve(),
    setSectionStatus: () => {},
};

export const FormsContext = createContext<FormsContextInterface>(initialState);

export const useFormContext = () => useContext(FormsContext);

export const FormsContextProvider = ({ id, caseForm, children }: FormsContextProviderProps) => {
    const sectionForms = useFormsStore((state) => state.sectionForms);
    const editMode = useFormsStore((state) => state.editMode);
    const autoSaved = useFormsStore((state) => state.autoSaved);
    const sectionFormsToRemove = useFormsStore((state) => state.sectionFormsToRemove);

    const setSectionForms = useFormsStore((state) => state.setSectionForms);
    const setOriginalSectionForms = useFormsStore((state) => state.setOriginalSectionForms);
    const setLookups = useFormsStore((state) => state.setLookups);
    const setHearings = useFormsStore((state) => state.setHearings);
    const setLocalStateIsDifferent = useFormsStore((state) => state.setLocalStateIsDifferent);
    const setEditMode = useFormsStore((state) => state.setEditMode);
    const setIsLoading = useFormsStore((state) => state.setIsLoading);
    const setAutoSaved = useFormsStore((state) => state.setAutoSave);
    const setCaseForm = useFormsStore((state) => state.setCaseForm);

    const setSectionFormsStatusState = useFormsStore((state) => state.setSectionFormsStatusState);

    const navigate = useNavigate();
    const listeners = useRef<PartialRecord<SectionKey, Callback[]>>({});
    const [inited, setInited] = useState(false);
    const [snackMessage, setSnackMessage] = useState<string | undefined>();
    const [snackVariant, setSnackVariant] = useState<'success' | 'info' | 'warning' | 'error' | undefined>();
    const { data: lookupsResponse, loading: lookupsIsLoading } = useQuery<CaseFormsLookupsResponse>(GET_LOOKUPS);
    const { data: hearings, loading: hearingsLoading } =
        useQuery<CaseFormHearingsPresentResponse>(GET_HEARINGS_PRESENT);
    const [updateCaseForm] = useMutation(UPDATE_CASE_FORM);
    const [deleteCaseForm] = useMutation(DELETE_CASE_FORM, {
        refetchQueries: ['caseForms'],
    });

    useEffect(() => {
        setSectionForms(
            id,
            prepareServerSectionFormsContent(caseForm.content)
            /*
            version with auto-save to localStorage:
            getCaseForms(id)
                ? getAutoSavedSectionForms(getCaseForms(id))
                : prepareServerSectionFormsContent(caseForm.content)
             */
        );
        setOriginalSectionForms(prepareServerSectionFormsContent(caseForm.content));
    }, [caseForm, id]);

    useEffect(() => {
        setCaseForm(caseForm);
    }, [caseForm]);

    useEffect(() => {
        setLookups({ lookups: lookupsResponse?.lookups, lookupsIsLoading });
    }, [lookupsResponse, lookupsIsLoading]);

    useEffect(() => {
        setHearings({ hearings: hearings?.hearings, hearingsLoading });
    }, [hearings, hearingsLoading]);

    const autoSave = useMemo(() => {
        let lastActualData: PartialRecord<SectionKey, any> = sectionForms;
        const handler = debounce(() => {
            saveToLocalStorage(lastActualData);
            setAutoSaved(true);
            setLocalStateIsDifferent(true);
        }, 200);

        return (data: PartialRecord<SectionKey, any>) => {
            lastActualData = data;
            handler();
        };
    }, []);

    useEffect(() => {
        setInited(true);
    }, []);

    useEffect(() => {
        if (!inited) {
            return;
        }

        if (autoSaved.active && editMode === 'edit') {
            autoSave(sectionForms);
        }
    }, [JSON.stringify(sectionForms), autoSaved.active, editMode]);

    const saveToLocalStorage = useCallback((data?: PartialRecord<SectionKey, any>) => {
        Object.entries(data ? data : sectionForms).forEach(([sectionKey, { payload }]) => {
            saveCaseForm(id, sectionKey as SectionKey, payload);
        });
    }, []);

    const edit = useCallback(() => {
        setEditMode('edit');
    }, []);

    const validate = useCallback(async () => {
        const callbacks = Object.values(listeners.current).flat();

        const validateResults = await Promise.all(callbacks.map((callback) => callback()));

        const groupedSections: Partial<Record<SectionKey, any[]>> = {};
        await Promise.all(
            Object.entries(listeners.current).map(async ([sectionKey, sectionCallbacks]) => {
                if (!groupedSections[sectionKey]) {
                    groupedSections[sectionKey] = [];
                }

                return await Promise.all(
                    sectionCallbacks.map(async (sectionCallback) => {
                        const result = await sectionCallback();
                        if (result.type === 'payload' && !!result.result) {
                            groupedSections[sectionKey].push(result.result);
                        }
                        return result;
                    })
                );
            })
        );

        return validateResults.length === validateResults.filter((result) => Boolean(result.result)).length
            ? groupedSections
            : null;
    }, []);

    const onSubmitListener = useCallback((sectionKey: SectionKey, callback: Callback): CleanCallback => {
        if (!listeners.current[sectionKey]) {
            listeners.current[sectionKey] = [];
        }

        const index = listeners.current[sectionKey]?.findIndex((item) => item === callback);
        if (index !== -1 && index !== undefined) {
            listeners.current[sectionKey]?.splice(index, 1);
        }

        listeners.current[sectionKey]?.push(callback);

        return () => {
            const index = listeners.current[sectionKey]?.findIndex((item) => item === callback);
            if (index !== -1 && index !== undefined) {
                listeners.current[sectionKey]?.splice(index, 1);
            }
        };
    }, []);

    const save = useCallback(async () => {
        setIsLoading(true);

        const result = await validate();
        if (!result) {
            setIsLoading(false);
            return;
        }

        const caseFormContent = {
            courtCase: formatAllDates(result['court-case']?.[0]),
            seizures: (result['seizures'] || []).map(formatAllDates),
            defendants: (result['defendants'] || []).map(formatAllDates),
            hearings: (result['hearings'] || []).map(formatAllDates),
            deleteSeizureIds: sectionFormsToRemove.seizures,
            deleteDefendantIds: sectionFormsToRemove.defendants,
            deleteHearingIds: sectionFormsToRemove.hearings,
        };

        try {
            const response = await updateCaseForm({
                variables: {
                    input: {
                        id,
                        status: CaseFormStatuses.Draft,
                        content: caseFormContent,
                    },
                },
            });

            if (caseForm.status === CaseFormStatuses.Review_Requested) {
                await updateCaseForm({
                    variables: {
                        input: {
                            id,
                            status: CaseFormStatuses.Review_Requested,
                        },
                    },
                });
            }

            if (response.errors?.length) {
                setSnackVariant('error');
                setSnackMessage('Server error: Something went wrong )=');
                return;
            }

            await apolloClient.refetchQueries({
                include: [GET_CASE_FORM],
            });
            removeAll(id);
            setLocalStateIsDifferent(false);
            setEditMode('saved');

            setSnackVariant('success');
            setSnackMessage('Case form successfully updated !');
        } catch (error) {
            console.error(error);
            setSnackVariant('error');
            setSnackMessage('Server error: Something went wrong )=');
        }

        setIsLoading(false);
    }, []);

    const requestReview = useCallback(async () => {
        setIsLoading(true);
        await updateCaseForm({
            variables: {
                input: {
                    id,
                    status: CaseFormStatuses.Review_Requested,
                },
            },
        });
        await apolloClient.refetchQueries({
            include: [GET_CASE_FORM],
        });

        setIsLoading(false);
    }, []);

    const approve = useCallback(async () => {
        setIsLoading(true);
        await updateCaseForm({
            variables: {
                input: {
                    id,
                    status: CaseFormStatuses.Approved,
                },
            },
        });
        await apolloClient.refetchQueries({
            include: [GET_CASE_FORM],
        });

        setIsLoading(false);
    }, []);

    const requestChanges = useCallback(async () => {
        setIsLoading(true);
        await updateCaseForm({
            variables: {
                input: {
                    id,
                    status: CaseFormStatuses.Changes_Requested,
                },
            },
        });
        await apolloClient.refetchQueries({
            include: [GET_CASE_FORM],
        });

        setIsLoading(false);
    }, []);

    const removeLocalChanges = useCallback((reloadPage?: boolean) => {
        removeAll(id);

        if (reloadPage) {
            window.location.reload();
        }

        setLocalStateIsDifferent(false);
    }, []);

    const removeCaseForm = async () => {
        setIsLoading(true);

        try {
            await deleteCaseForm({
                variables: {
                    id,
                },
            });
            navigate('/case-forms', { replace: true });

            setSnackVariant('success');
            setSnackMessage('Case form successfully deleted.');
        } catch (error) {
            console.error(error);
            setSnackVariant('error');
            setSnackMessage('An error occurred while deleting the Case form.');
        }

        setIsLoading(false);
    };

    const value = useMemo(() => {
        return {
            save,
            requestChanges,
            requestReview,
            approve,
            edit,
            onSubmitListener,
            removeLocalChanges,
            removeCaseForm,
            setSectionStatus: setSectionFormsStatusState,
        };
    }, [save, requestChanges, removeCaseForm, requestReview, approve, edit, onSubmitListener, removeLocalChanges]);

    return (
        <FormsContext.Provider value={value}>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={!!snackMessage}
                onClose={() => {
                    setSnackMessage(undefined);
                }}
                key="forms-snack"
                autoHideDuration={2400}
            >
                <Alert
                    onClose={() => {
                        setSnackMessage(undefined);
                    }}
                    severity={snackVariant}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackMessage}
                </Alert>
            </Snackbar>
            {children}
        </FormsContext.Provider>
    );
};
