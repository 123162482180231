export const captureCreateChart = (chartId: string) => {
    return {
        eventName: 'create_chart',
        payload: {
            id: chartId,
        },
    };
};

export const captureRemoveChart = (chartId: string) => {
    return {
        eventName: 'remove_chart',
        payload: {
            id: chartId,
        },
    };
};

export const captureExportChart = (chartTitle?: string) => {
    return {
        eventName: 'export_chart',
        payload: {
            title: chartTitle || 'N/A',
        },
    };
};

export const captureViewTable = (tableTitle?: string) => {
    return {
        eventName: `view_table`,
        payload: {
            table: tableTitle,
        },
    };
};

export const captureExportTable = (title: string, type: string) => {
    return {
        eventName: 'export_table',
        payload: {
            title,
            type,
        },
    };
};

export const captureCreateDashboard = (id: string, title: string) => {
    return {
        eventName: 'create_dashboard',
        payload: {
            id,
            title,
        },
    };
};

export const captureRemoveDashboard = (id: string) => {
    return {
        eventName: 'delete_dashboard',
        payload: {
            id,
        },
    };
};

export const captureViewCourtCase = (id: string) => {
    return {
        eventName: 'view_court_case',
        payload: { id },
    };
};
