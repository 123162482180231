import { Box, BoxProps, styled } from '@mui/material';

interface TableWrapperProps extends BoxProps {
    expand?: boolean;
}

export const TableWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'expand',
})<TableWrapperProps>(({ expand, theme }) => ({
    margin: `0 auto`,
    width: '100%',
    height: 'calc(100vh - 92px)',
    transition: theme.transitions.create(['height'], {
        duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up('xl')]: {
        borderLeft: `1px solid ${theme.palette.divider}`,
        borderRight: `1px solid ${theme.palette.divider}`,
        height: '100%',
    },
    ...(expand && {
        height: '100vh',
    }),
}));
