import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ExportableImage } from '../../types/DashboardContextType';
// @ts-ignore
import Inter from '../../static/fonts/Inter-Regular.ttf';
import { PDF_HEIGHT, PDF_WIDTH } from '../../constants/local';

Font.register({
    family: 'Inter',
    src: Inter,
});

const styles = StyleSheet.create({
    body: {
        paddingHorizontal: 8,
        paddingVertical: 16,
    },
    textBlock: {
        width: '100%',
        marginBottom: 32,
    },
    text: {
        fontFamily: 'Inter',
        lineHeight: 1.57,
        fontWeight: 700,
    },
});

const getSizeByWidth = (aspect: number, width: number, height: number) => {
    return width > PDF_WIDTH
        ? {
              width: PDF_WIDTH,
              height: PDF_WIDTH / aspect,
          }
        : {
              width,
              height,
          };
};

const getSizeByHeight = (aspect: number, width: number, height: number) => {
    return height > PDF_HEIGHT
        ? {
              height: PDF_HEIGHT - 100,
              width: PDF_HEIGHT * aspect,
          }
        : {
              width,
              height,
          };
};

export const PDFRenderer = ({ images }: { images: ExportableImage[] }) => {
    return (
        <Document>
            {images.map((item, index) => {
                const aspect = item.size.width / item.size.height;

                const { width, height } = getSizeByWidth(aspect, item.size.width, item.size.height);

                return (
                    <Page style={styles.body} key={index} size={{ width: PDF_WIDTH, height: PDF_HEIGHT }}>
                        {item.title && (
                            <View style={styles.textBlock}>
                                <Text wrap={false} style={styles.text}>
                                    {item.title}
                                </Text>
                            </View>
                        )}
                        <Image src={item.src} style={getSizeByHeight(aspect, width, height)} />
                    </Page>
                );
            })}
        </Document>
    );
};
