import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Collapse } from '@mui/material';
import { startCase } from 'lodash';
import useSummaryTables from '../../components/Table/useSummaryTables';
import { TableGlobalStyles } from '../../components/Table/TableGlobalStyles';
import { TableWrapper } from '../../components/Table/TableWrapper';
import { usePostHog } from 'posthog-js/react';
import { captureViewTable } from '../../posthog';
import SeizedCommodities from './components/SeizedCommodities';
import CourtCases from './components/CourtCases';
import Defendants from './components/Defendants';
import Seizures from './components/Seizures';
import Hearings from './components/Hearings';
import Charges from './components/Charges';

const SummaryTables = () => {
    const { description, shrinkTitle, hideHeaders, showHeaders } = useSummaryTables();
    const { table } = useParams();
    const posthog = usePostHog();

    useEffect(() => {
        if (table) {
            const { eventName, payload } = captureViewTable(table);
            posthog?.capture(eventName, payload);
        }
    }, [table, posthog]);

    return (
        <>
            <TableGlobalStyles />
            <Collapse appear={false} in={!shrinkTitle} collapsedSize={0}>
                <Box sx={{ textAlign: 'center', p: 4, pb: 2 }}>
                    <Typography variant="h6" color="textPrimary">
                        {startCase(table)}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {description}
                    </Typography>
                </Box>
            </Collapse>
            <TableWrapper expand={shrinkTitle}>
                {table === 'court-cases' && (
                    <CourtCases shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'defendants' && (
                    <Defendants shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'seized-commodities' && (
                    <SeizedCommodities shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'seizures' && (
                    <Seizures shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'hearings' && (
                    <Hearings shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
                {table === 'charges' && (
                    <Charges shrinkTitle={shrinkTitle} hideHeaders={hideHeaders} showHeaders={showHeaders} />
                )}
            </TableWrapper>
        </>
    );
};

const SummaryTablesRoute = {
    routeProps: {
        path: '/summary-tables/:table',
        element: <SummaryTables />,
    },
    name: 'Summary Tools',
};

export default SummaryTablesRoute;
