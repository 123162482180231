import { useState, useEffect, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useGridApiRef, GridEventListener, DataGridPremiumProps } from '@mui/x-data-grid-premium';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import { Hearing } from '../../../../types';
import GET_HEARINGS from '../../../../api/apollo/query/GetHearings';
import TableToolbar from '../../../../components/Table/TableToolbar';
import columns from './columns';
import { useAuth } from '../../../../api/auth';
import { DefaultTableProps } from '../../../../components/Table/types';
import styles from './Hearings.module.css';

function NotesPanel({ row }: { row: Hearing }) {
    return (
        <Box sx={{ height: 300, p: 4 }}>
            <Typography variant="overline" className={styles.hearingsNotesTitle} gutterBottom>
                Hearing Notes
            </Typography>
            <Typography className={styles.notes} variant="body2">
                {row.notes}
            </Typography>
        </Box>
    );
}

const prepareHearings = (hearings: Hearing[]) => {
    return hearings.map((hearing) => {
        let stage = hearing.stage;

        if (stage) {
            stage = stage
                .split('_')
                .map((item, index) => (index === 0 ? item : item.toLowerCase()))
                .join(' ');
        }

        return {
            ...hearing,
            stage,
        };
    });
};

export const Hearings = ({ shrinkTitle, hideHeaders, showHeaders }: DefaultTableProps) => {
    const { user } = useAuth();
    const apiRef = useGridApiRef();
    const [rows, setRows] = useState<Hearing[]>([]);

    const { loading } = useQuery(GET_HEARINGS, {
        onCompleted: ({ hearings }: { hearings: Hearing[] }) => {
            setRows(prepareHearings(hearings));
        },
    });
    useEffect(() => {
        const handleEvent: GridEventListener<'rowsScroll'> = (params) => {
            params.top > 5 ? hideHeaders() : showHeaders();
        };

        apiRef.current.subscribeEvent('rowsScroll', handleEvent);
    }, [apiRef, hideHeaders, showHeaders]);

    const getDetailPanelContent = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
        ({ row }) => <NotesPanel row={row} />,
        []
    );

    const getDetailPanelHeight = useCallback(() => 300, []);

    return (
        <CustomDataGrid
            apiRef={apiRef}
            getRowId={(row) => row.id}
            rowGroupingColumnMode="single"
            columns={columns}
            rows={rows}
            checkboxSelection={false}
            loading={loading}
            hideFooterSelectedRowCount
            hideFooterPagination
            components={{ Toolbar: TableToolbar }}
            componentsProps={{
                toolbar: {
                    title: 'Hearings',
                    showTitle: shrinkTitle,
                    csvOptions: { allColumns: true },
                    csvExportEnabled:
                        !loading && user?.permissions?.map(({ codename }) => codename).includes('can_export_tables'),
                    tableApi: apiRef.current,
                },
            }}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            sx={{
                '& .MuiDataGrid-detailPanel': { bgcolor: '#F8F8F8' },
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        notes: false,
                    },
                },
            }}
        />
    );
};

export default Hearings;
