import { Lookups } from '../../../../../types';
import { SectionFormInfo } from './types';

export const defaultLookups: Lookups = {
    currency: '',
    crimes: [],
    outcomeFactors: [],
    courts: [],
    administrativeLevel1s: {
        localName: 'District',
        values: [],
    },
    administrativeLevel2s: {
        localName: 'State',
        values: [],
    },
    protectionLevels: [],
    agencies: [],
    regions: {
        localName: 'Region',
        values: [],
    },
    units: null,
    species: [],
    speciesGroups: [],
    countries: [],
    hearingStages: [],
};

export const defaultSectionFormInfo: SectionFormInfo = {
    status: 'sync',
};
