import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ChartTooltipContentProps } from './types';
import { DateTime } from 'luxon';

const formatDateFromMillis = (d: number, format: string) => {
    return DateTime.fromMillis(d).toFormat(format);
};

const ChartTooltipContent = ({
    label,
    datum,
    dateFormat
}: ChartTooltipContentProps): JSX.Element => {
    const datumLabel = typeof datum.label == 'number' ? formatDateFromMillis(datum.label, dateFormat || '') : datum.label;
    return (
        <Paper sx={{ p: 1, bgcolor: 'primary.dark', color: 'white' }}>
            <Typography variant='caption'>{`${label}  ·  ${datumLabel}`}</Typography>
            <Typography variant='body1'>{datum.data}</Typography>
        </Paper>
    )
};

export default ChartTooltipContent;
