import { useEffect, useMemo, useState, memo } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { DeleteButton } from '../../../components/DeleteButton';
import { FormItem } from '../../../components/FormItem';
import { FormCheckbox } from '../../../components/FormCheckbox';
import { FormAutocomplete } from '../../../components/Autocomplete';
import { FormRow } from '../../../components/FormTemplates/FormRow';
import { CurrencyValueInput } from '../../../components/CurrencyValueInput';
import { useFormContext } from '../../../context/FormsContext';
import { useFormsStore } from '../../../store';
import { DEBOUNCE_WAIT, EMPTY_PLACEHOLDER } from '../../../constants';
import styles from './SeizedCommodities.module.css';
import { Species, SpeciesGroup } from '../../../../../../../types/Lookups';

export type SeizedCommodityType = z.infer<typeof seizedCommoditySchema>;

const NAME_UNIT_MAP: { [k: string]: string[] } = {
    'Unknown Elephant': [
        'RawIvory',
        'ProcessedIvory',
        'Live',
        'Carcass',
        'Meat',
        'Skin',
        'Head',
        'Foot',
        'Bone',
        'Tail',
    ],
    'Unknown Pangolin': ['Live', 'Carcass', 'Scale'],
    'Unknown Zebra': ['Live', 'Carcass', 'Skin', 'Head', 'Foot', 'Bone', 'Tail', 'Meat'],
    'Unknown Monkey': ['Live', 'Carcass', 'Skin', 'Head', 'Foot', 'Bone', 'Tail', 'Meat'],
    'Unknown Rhino': ['Live', 'Carcass', 'Horn', 'Skin', 'Head', 'Foot', 'Bone', 'Tail', 'Meat'],
    'Unknown Tree': ['Roundwood', 'Charcoal', 'Plank', 'Log'],
    'Unknown Flower': ['Live'],
    'Unknown Mbuna Fish': ['Live', 'Carcass'],
    'Unknown Seahorse': ['Live', 'Carcass'],
    'Unknown Bushmeat': ['Meat'],
};

const ALL_UNITS = [
    'Live',
    'Carcass',
    'Head',
    'Foot',
    'Tail',
    'Skin',
    'Meat',
    'Bone',
    'Tooth',
    'Scale',
    'Claw',
    'Horn',
    'RawIvory',
    'ProcessedIvory',
    'Feather',
    'Shell',
    'Charcoal',
    'Roundwood',
    'Plank',
    'Log',
    'Firearm',
    'Ammunition',
    'Snare',
    'Vehicle',
    'Knife',
    'Axe',
];

const UNIT_MAP: { [k: string]: string[] } = {
    other: ALL_UNITS,
    accessory: ['Firearm', 'Ammunition', 'Snare', 'Vehicle', 'Knife', 'Axe'],
    plantae: ['Roundwood', 'Charcoal'],
    animalia: ['Live', 'Carcass', 'Meat'],
    mammalia: ['Skin', 'Head', 'Foot', 'Bone', 'Tail', 'Tooth'],
    aves: ['Head', 'Foot', 'Claw', 'Bone', 'Tail'],
    reptilia: ['Head', 'Foot', 'Claw', 'Bone', 'Tail', 'Tooth'],
    actinopterygii: [],
    mollusca: ['Shell'],
    elephantidae: ['RawIvory', 'ProcessedIvory'],
    rhinocerotidae: ['Horn'],
    cervidae: ['Horn'],
    pholidota: [],
    testudinidae: ['Shell'],
};

interface SeizedCommodityComponentProps {
    editMode?: boolean;
    defaultValues?: Partial<SeizedCommodityType>;
    onDelete: () => void;
    onChange: (value?: Partial<SeizedCommodityType>) => void;
}

export const seizedCommoditySchema = z.object({
    id: z.coerce.string().optional(),
    speciesId: z.coerce.string(),
    protectionLevelId: z.coerce.string().nullable().optional(),
    originAdministrativeLevel2Id: z.coerce.string().optional(),
    originCountryId: z.coerce.string().optional(),
    unit: z.string(),
    description: z.string().optional().nullable(),
    count: z.number().positive().nullable().optional(),
    weightGrams: z.number().positive().nullable().optional(),

    value: z.number().positive().nullable().optional(),
    originName: z.string().optional().nullable(),

    valueCurrency: z.string().optional(),
    category: z.enum(['Commodity', 'Accessory']).optional(),
});

const inputProps = {
    type: 'number' as 'text' | 'number',
    min: 0,
    step: 1,
};

export const SeizedCommodityComponent = memo(
    ({ editMode, defaultValues, onDelete, onChange }: SeizedCommodityComponentProps) => {
        const { onSubmitListener } = useFormContext();
        const { administrativeLevel2s, countries, species, protectionLevels, currency } = useFormsStore(
            (state) => state.lookups
        );
        const lookupsIsLoading = useFormsStore((state) => state.lookupsIsLoading);
        const {
            control,
            formState: { errors },
            watch,
            handleSubmit,
            setValue,
        } = useForm<z.infer<typeof seizedCommoditySchema>>({
            resolver: zodResolver(seizedCommoditySchema),
            defaultValues: {
                ...defaultValues,
                valueCurrency: defaultValues?.valueCurrency ?? currency,
            },
        });
        const [showOrigin, setShowOrigin] = useState(false);
        const currentSpeciesId = watch('speciesId');

        const toggleOrigin = (value: boolean) => {
            setShowOrigin(value);
            if (!value) {
                setValue('originName', undefined);
                setValue('originAdministrativeLevel2Id', undefined);
                setValue('originCountryId', undefined);
            }
        };

        useEffect(() => {
            return onSubmitListener('seizures', () => {
                return new Promise((resolve) => {
                    const validateFunc = () => {
                        resolve({ type: 'validate', result: true });
                    };
                    handleSubmit(validateFunc, validateFunc)();
                });
            });
        }, []);

        useEffect(() => {
            let currentData: Partial<SeizedCommodityType> = {};
            const onChangeDebounce = debounce(() => {
                onChange(currentData);
            }, DEBOUNCE_WAIT);

            const { unsubscribe } = watch((data) => {
                currentData = data;
                onChangeDebounce();
            });

            return unsubscribe;
        }, []);

        const getSpecies = (id?: string) => {
            return species?.find((item) => `${item.id}` === `${id}`);
        };

        const getProtectionLevel = (id?: string | null) => {
            return protectionLevels?.find((item) => `${item.id}` === `${id}`);
        };

        const getAdministrativeLevel2 = (levelId?: string) => {
            return administrativeLevel2s.values.find((item) => item.id === levelId);
        };

        const getCountry = (id?: string) => {
            return countries.find((item: any) => item.id === id);
        };

        const speciesOptions = useMemo(() => {
            return species
                .map(
                    ({ id, commonName, speciesGroup }) =>
                        ({
                            id,
                            commonName,
                            speciesGroup: speciesGroup
                                ? { id: speciesGroup.id, name: speciesGroup.name }
                                : ({ id: '0', name: 'Other' } as SpeciesGroup),
                        } as Species)
                )
                .sort((a, b) => {
                    const groupNameA = a.speciesGroup?.name ?? 'Other';
                    const groupNameB = b.speciesGroup?.name ?? 'Other';
                    return -groupNameB.localeCompare(groupNameA);
                });
        }, [species]);

        const unitOptions = useMemo(() => {
            let options: string[] = [];

            const currentSpecies = getSpecies(currentSpeciesId) || {};
            const commonName = currentSpecies['commonName'] ?? '';

            if (commonName.startsWith('Unknown ')) {
                options.push(...(NAME_UNIT_MAP[commonName] || ALL_UNITS));
            } else {
                Object.values(currentSpecies).forEach((v) => {
                    if (typeof v === 'string') {
                        const opts = UNIT_MAP[v.toLowerCase()] || [];
                        options.push(...opts);
                    }
                });
            }

            options.push('Other');

            return options;
        }, [currentSpeciesId, species]);

        return (
            <>
                <FormRow>
                    <Controller
                        name="speciesId"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                isLoading={lookupsIsLoading}
                                errorMessage={errors.speciesId?.message}
                                editMode={editMode}
                                label="Species"
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value },
                                    });
                                }}
                                renderValue={(value) => getSpecies(value)?.commonName || EMPTY_PLACEHOLDER}
                                renderInput={(value) => (
                                    <FormAutocomplete
                                        errorMessage={errors.speciesId?.message}
                                        disablePortal={false}
                                        disableVirtualization={true}
                                        label={null}
                                        options={speciesOptions}
                                        groupBy={(option) => option.speciesGroup?.name ?? 'Other'}
                                        getOptionLabel={(option) => option.commonName || EMPTY_PLACEHOLDER}
                                        value={getSpecies(value)}
                                        onChange={(_e, newValue) => {
                                            field.onChange({ target: { value: newValue?.id } });
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="unit"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                isLoading={lookupsIsLoading}
                                disabled={!currentSpeciesId}
                                errorMessage={errors.unit?.message}
                                editMode={editMode}
                                label="Unit"
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value },
                                    });
                                }}
                                renderInput={(value) => (
                                    <FormAutocomplete
                                        disabled={!currentSpeciesId}
                                        errorMessage={errors.unit?.message}
                                        disablePortal={false}
                                        label={null}
                                        options={unitOptions}
                                        value={value}
                                        onChange={(_e, newValue) => {
                                            field.onChange({ target: { value: newValue } });
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="protectionLevelId"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                isLoading={lookupsIsLoading}
                                errorMessage={errors.protectionLevelId?.message}
                                editMode={editMode}
                                label="Protection Level"
                                value={field.value}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value },
                                    });
                                }}
                                renderValue={(value) => getProtectionLevel(value)?.label || EMPTY_PLACEHOLDER}
                                renderInput={(value) => (
                                    <FormAutocomplete
                                        errorMessage={errors.protectionLevelId?.message}
                                        disablePortal={false}
                                        label={null}
                                        options={protectionLevels}
                                        getOptionLabel={(option) => option.label || EMPTY_PLACEHOLDER}
                                        value={getProtectionLevel(value)}
                                        onChange={(_e, newValue) => {
                                            field.onChange({ target: { value: newValue?.id } });
                                        }}
                                    />
                                )}
                            />
                        )}
                    />
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                errorMessage={errors.description?.message}
                                className={styles.headerField}
                                editMode={editMode}
                                label="Description:"
                                value={field.value}
                                renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value },
                                    });
                                }}
                            />
                        )}
                    />
                </FormRow>
                <FormRow>
                    <Controller
                        name="count"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                errorMessage={errors.count?.message}
                                editMode={editMode}
                                label="Count"
                                inputProps={inputProps}
                                value={field.value}
                                renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value: Number(value) },
                                    });
                                }}
                            />
                        )}
                    />
                    <Controller
                        name="weightGrams"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                errorMessage={errors.weightGrams?.message}
                                editMode={editMode}
                                inputProps={{ ...inputProps, step: 0.01 }}
                                label="Weight (kg)"
                                value={field.value ? field.value / 1000 : field.value}
                                renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value: Number(value ? value * 1000 : null) },
                                    });
                                }}
                            />
                        )}
                    />
                    <CurrencyValueInput
                        control={control}
                        errors={errors}
                        editMode={editMode}
                        valueInputConfig={{
                            label: 'Value',
                            name: 'value',
                            getErrorMessage: (errors) => errors.value?.message,
                        }}
                        currencyInputConfig={{ name: 'valueCurrency' }}
                    />
                </FormRow>
                <FormRow>
                    <FormItem
                        className={styles.headerField}
                        editMode={editMode}
                        label="Different Origin:"
                        value={showOrigin}
                        renderInput={(value) => (
                            <FormCheckbox
                                disabled={!editMode}
                                checked={value}
                                onChange={(value) => {
                                    toggleOrigin(value);
                                }}
                            />
                        )}
                        renderValue={(value) => (value ? 'Yes' : 'No')}
                    />
                    {showOrigin && (
                        <>
                            <Controller
                                name="originName"
                                control={control}
                                render={({ field }) => (
                                    <FormItem
                                        errorMessage={errors.originName?.message}
                                        className={styles.headerField}
                                        editMode={editMode}
                                        label="Origin:"
                                        value={field.value}
                                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value },
                                            });
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="originCountryId"
                                control={control}
                                render={({ field }) => (
                                    <FormItem
                                        errorMessage={errors.originCountryId?.message}
                                        editMode={editMode}
                                        label="Origin Country"
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value },
                                            });
                                        }}
                                        renderValue={(value) => getCountry(value)?.name || EMPTY_PLACEHOLDER}
                                        renderInput={(value) => (
                                            <FormAutocomplete
                                                errorMessage={errors.originCountryId?.message}
                                                disablePortal={false}
                                                label={null}
                                                options={countries}
                                                getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                                value={getCountry(value)}
                                                onChange={(_e, newValue) => {
                                                    field.onChange({ target: { value: newValue?.id } });
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="originAdministrativeLevel2Id"
                                control={control}
                                render={({ field }) => (
                                    <FormItem
                                        isLoading={lookupsIsLoading}
                                        errorMessage={errors.originAdministrativeLevel2Id?.message}
                                        editMode={editMode}
                                        label={administrativeLevel2s.localName}
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value },
                                            });
                                        }}
                                        renderValue={(value) =>
                                            getAdministrativeLevel2(value)?.name || EMPTY_PLACEHOLDER
                                        }
                                        renderInput={(value) => (
                                            <FormAutocomplete
                                                errorMessage={errors.originAdministrativeLevel2Id?.message}
                                                disablePortal={false}
                                                label={null}
                                                options={administrativeLevel2s.values}
                                                getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                                value={getAdministrativeLevel2(value)}
                                                onChange={(_e, newValue) => {
                                                    field.onChange({ target: { value: newValue?.id } });
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </>
                    )}
                </FormRow>
                {editMode && <DeleteButton color="gray" onClick={onDelete} />}
            </>
        );
    },
    /*
        This optimization is possible because the props we pass do not necessarily need to be always up-to-date;
        For such components, initialization or rare update is sufficient
     */
    (prevProps, nextProps) => prevProps.editMode === nextProps.editMode
);
