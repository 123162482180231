import { useEffect, useState } from 'react';
import { Login as LoginComponent } from '@c4ads/c4blocks';
import { LoginCredentials } from '@c4ads/c4auth';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import { useAuth } from '../../api/auth';
import { Loader } from '../../components/Loader';
import { openSnackBar } from '../../components/Snackbar';
import backgroundImg from '../../static/img/login_background.jpg';
import loginStyles from './Login.module.css';

export default function Login() {
    const posthog = usePostHog();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { isAuthenticated, login, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated && user) {
            navigate('/dashboards');

            posthog?.identify(user.id, {
                email: user.email,
            });
        }
    }, [isAuthenticated, navigate, user, posthog]);

    const handleLogin = (data: LoginCredentials) => {
        posthog?.capture('clicked_log_in');
        setIsLoading(true);

        login(data, async (e, userResponse) => {
            if (!e.error) {
                if (userResponse) {
                    const userData = await userResponse;
                    const user = userData?.data?.user;

                    if (user) {
                        posthog?.identify(user.id, {
                            email: user.email,
                        });
                        posthog?.group('company', user.organization.id);
                    }
                }
                navigate('/dashboards');
            } else {
                if (e?.response?.status === 400) {
                    openSnackBar({
                        message: 'Wrong email or password.',
                        type: 'error',
                    });
                }
            }
            setIsLoading(false);
        });
    };

    const resetPassword = () => {
        navigate('/reset-password');
    };

    return (
        <>
            <LoginComponent
                title="Welcome back!"
                subtitle="Log in with your credentials to get started"
                backgroundImageSrc={backgroundImg}
                onLogin={handleLogin}
                onResetPassword={resetPassword}
            />
            {isLoading && (
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={loginStyles.loader}
                >
                    <Grid item>
                        <Loader />
                    </Grid>
                </Grid>
            )}
        </>
    );
}
