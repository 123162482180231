import { Box, Typography } from '@mui/material';
import cn from 'classnames';
import { SeizureItem } from './SeizureItem';
import { CourtCase, Seizure } from '../../../../types';
import styles from '../../CourtCaseDialog.module.css';

interface SeizureProps {
    courtCase: CourtCase | null;
}

export const SeizureComponent = ({ courtCase }: SeizureProps) => {
    return (
        <Box>
            <Typography variant="h6" color="primary" className={cn(styles.title, styles.boxHeader)}>
                Seizures
                <div className={styles.boxHeaderUnderLineInnerContainer}>
                    <Typography className={styles.boxHeaderTextUnderline} variant="h6" color="primary">
                        Seizures
                    </Typography>
                    <div className={styles.boxHeaderUnderline} />
                </div>
            </Typography>

            {courtCase?.seizures?.map(({ id, ...props }: Seizure) => (
                <SeizureItem key={id} item={{ id, ...props }} />
            ))}
        </Box>
    );
};
