import { gql } from '@apollo/client';

export const GET_CASE_FORM = gql`
    query caseForm($id: String!) {
        caseForm(id: $id) {
            id
            status
            title
            content
            version
            comments {
                id
                text
                timestamp
                user {
                    id
                    email
                    firstName
                    lastName
                }
            }
            createdAt
            createdBy {
                email
                firstName
                lastName
            }
            lastEditedAt
            lastEditedBy {
                email
                firstName
                lastName
            }
            lastReviewedAt
            lastReviewedBy {
                email
                firstName
                lastName
            }
        }
    }
`;
