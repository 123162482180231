import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { tableHelpers } from '../../../../helpers/tableHelpers';
import { IndictmentCharge } from '../../../../types';

const columns: GridColDef<IndictmentCharge>[] = [
    {
        field: 'docket',
        type: 'string',
        headerName: 'Docket',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.indictment?.courtCase?.docket,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'defendant',
        type: 'string',
        headerName: 'Defendant',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.indictment?.defendant?.name,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'legislation',
        type: 'string',
        headerName: 'Legislation',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 200,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.charge?.legislation,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'part',
        type: 'string',
        headerName: 'Part',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.charge?.part || '',
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'penaltyPart',
        type: 'string',
        headerName: 'Penalty Part',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        valueGetter: ({ row }: GridValueGetterParams<string>) => row.charge?.penaltyPart || '',
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'proposedCustodialMonths',
        type: 'number',
        headerName: 'Custodial Sentence (Months)',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'proposedFine',
        type: 'number',
        headerName: 'Fine (MWK)',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'proposedCommunityServiceHours',
        type: 'number',
        headerName: 'Community Service (Hours)',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 200,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'proposedSuspendedMonths',
        type: 'number',
        headerName: 'Suspended Sentence (Months)',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 200,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'isConcurrent',
        type: 'string',
        headerName: 'Sentence Run',
        headerAlign: 'center',
        width: 140,
        groupable: false,
        aggregable: false,
        valueGetter: ({ row }) =>
            row.isConcurrent === null ? '--' : row.isConcurrent ? 'Concurrently' : 'Consecutively',
    },
    {
        field: 'chargeWithdrawn',
        type: 'boolean',
        headerName: 'Charge Withdrawn',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'guiltyPlea',
        type: 'boolean',
        headerName: 'Guilty Plea',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'guiltyVerdict',
        type: 'boolean',
        headerName: 'Guilty Verdict',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderBoolean(value),
    },
    {
        field: 'outcomeDate',
        type: 'date',
        headerName: 'Outcome Date',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        aggregable: false,
        valueGetter: ({ row }: GridValueGetterParams<Date>) => row.indictment?.outcomeDate,
    },
];

export default columns;
