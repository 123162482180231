import { ReactElement, useMemo } from 'react';
import cn from 'classnames';
import { CustomTooltip } from '../../../../../../components/CustomTooltip';
import { Permission } from '.';
import styles from './PermissionDropdown.module.css';

interface PermissionTitleProps {
    emptyTitle?: string;
    permissions: Permission[];
    permissionsState: Record<string, boolean>;
}

interface WrapperProps {
    title: ReactElement;
    children: ReactElement;
}

export const PermissionTitle = ({ permissions, permissionsState, emptyTitle = 'None' }: PermissionTitleProps) => {
    const permitted = useMemo(
        () => permissions.filter((permission) => permissionsState[permission.code]),
        [permissionsState, permissions]
    );

    const tooltipContent = useMemo(() => {
        return (
            <ul className={styles.permissionsList}>
                {permitted.map((permission) => {
                    return <li className={styles.permissionsListItem} key={permission.id}>{permission.name}</li>;
                })}
            </ul>
        );
    }, [permitted]);

    const Wrapper = useMemo(() => {
        return permitted.length > 1
            ? ({ title, children }: WrapperProps) => <CustomTooltip title={title}>{children}</CustomTooltip>
            : ({ children }: WrapperProps) => <>{children}</>;
    }, [permitted]);

    if (permitted.length === 0) {
        return <span className={styles.titleText}>{emptyTitle}</span>;
    }

    return (
        <Wrapper title={tooltipContent}>
            <div className={styles.titleContainer}>
                <span className={cn(styles.permissionName, styles.titleText)}>{permitted[0].name}</span>
                {permitted.length > 1 && <span className={styles.titleText}>(+{permitted.length - 1})</span>}
            </div>
        </Wrapper>
    );
};
