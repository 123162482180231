import React, { ReactNode, useContext, useMemo, useEffect, useRef, useState, useCallback } from 'react';
import { flatMap, sortBy, startCase } from 'lodash';
import { Icon, IconButton } from '@mui/material';
import { DateTime } from 'luxon';
import html2canvas from 'html2canvas';
import { usePostHog } from 'posthog-js/react';
import { Dataset, Series, Record, AxesProps, ChartProps, usePalette, c4adsPalette } from '@c4ads/c4blocks';
import { openSnackBar } from '../Snackbar';
import { useAuth } from '../../api/auth';
import DashboardContext from '../../routes/Dashboards/Dashboard/DashboardContext';
import { downloadSvg, svgToImageSrc } from '../../helpers/chartHelpers';
import { FullScreenLoader } from '../Loader/FullScreenLoader';
import { ChartConfig } from '../../types/ChartConfig';
import errorImgSrc from '../../static/img/error.png';
import noDataImgSrc from '../../static/img/no_data.png';
import { DiscreteVariable } from '../../types/DiscreteVariable';
import { captureExportChart } from '../../posthog';
import { CHART_CONTENT_CLASSNAME, CHART_LABEL_CLASSNAME, CHOROPLETH_LEGEND_CLASSNAME } from '../Chart';
import styles from './ChartPropProvider.module.css';

export interface ChartPropProviderProps {
    config: ChartConfig;
    loading: boolean;
    dataset?: Dataset;
    error?: any;
    children: (args: { chartProps: ChartProps }) => ReactNode | null;
}

interface AddLegendInterface {
    svg: SVGElement;
    image: HTMLElement;
    container: HTMLDivElement;
}

const ChartPropProvider = ({ config, loading: propsLoading, dataset, error, children }: ChartPropProviderProps) => {
    const { id, x, y, groupby, xLines, yLines } = config;
    const { user } = useAuth();
    const posthog = usePostHog();

    const ref = useRef<HTMLDivElement>(null);
    const { handleEditConfig, handleRemoveChart, onExport } = useContext(DashboardContext);
    const [loading, setLoading] = useState<boolean>(false);

    const { sequentialPalette } = usePalette(c4adsPalette);

    useEffect(() => {
        return onExport(async () => {
            if (!ref.current) {
                return;
            }
            const svgElement = ref.current.querySelector('svg');
            if (!svgElement) {
                return;
            }

            const result = await svgToImageSrc(svgElement);
            if (!result) {
                return;
            }
            const { src, width, height } = result;

            return {
                size: { width, height },
                src,
                title: dataset?.title,
            };
        });
    }, [ref.current]);

    const createSvgWrapper = () => {
        const svg = document.createElement('svg');
        svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

        // @ts-ignore
        return svg as SVGSVGElement;
    };

    const svgElementSize = (el: SVGSVGElement) => {
        const width = Number(el.getAttribute('width'));
        const height = Number(el.getAttribute('height'));

        return { width, height };
    };

    const downloadChart = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        const block = ref.current;
        if (!block) {
            return;
        }

        const svgWrapper = createSvgWrapper();
        const svgContainer = block.querySelector('svg');
        let svgElement = block.querySelector(`.${CHART_CONTENT_CLASSNAME}`);
        if (!svgElement || !svgContainer) {
            return;
        }
        // Getting inner svg for such components as Line Chart, Bar Chart, Scatter Chart
        if (svgElement.children[0]?.tagName === 'svg') {
            svgElement = svgElement.children[0];
        }

        svgElement = svgElement.cloneNode(true) as Element;
        svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

        setLoading(true);

        const cleanupElements: any[] = [];
        let newSvgElement: AddLegendInterface | undefined;
        if (config.mark === 'Choropleth') {
            newSvgElement = await addLegend(svgContainer);
            if (newSvgElement) {
                cleanupElements.push(...Object.values(newSvgElement));
            }
        }

        const label = await getChartLabel(svgContainer);
        if (label) {
            svgWrapper.appendChild(label.svg);
            cleanupElements.push(...Object.values(label));
        }

        let contentElement = svgElement;
        if (newSvgElement) {
            contentElement = newSvgElement.svg;
        }

        contentElement.setAttribute(
            'y',
            `${
                label?.svg && !contentElement.getAttribute('data-chartconfig')
                    ? svgElementSize(label.svg).height + 16
                    : 0
            }`
        );
        svgWrapper.appendChild(contentElement);

        const { width, height } = Array.from(svgWrapper.childNodes).reduce(
            (acc, item) => {
                const { width, height } = svgElementSize(item as SVGSVGElement);

                return { width, height: acc.height + height };
            },
            { width: 0, height: 0 }
        );

        const gap = 12; // gap between label and chart
        svgWrapper.setAttribute('width', `${width}`);
        svgWrapper.setAttribute('height', `${height + gap}`);

        const result = await downloadSvg(
            svgWrapper,
            `${dataset?.title ? dataset?.title : ''}-${new Date().toUTCString()}`,
            'png'
        );

        const { eventName, payload } = captureExportChart(dataset?.title);
        posthog?.capture(eventName, payload);

        cleanupElements.forEach((element) => {
            element?.remove();
        });

        if (!result) {
            openSnackBar({ type: 'error', message: 'The chart could not be loaded, please try again.' });
        }

        setLoading(false);
    };

    const getChartLabel = async (svgContainer: SVGElement) => {
        const labelContainer = svgContainer.querySelector(`.${CHART_LABEL_CLASSNAME}`);
        if (!labelContainer) {
            return;
        }

        const { height, width } = labelContainer.getBoundingClientRect();
        const canvas = await html2canvas(labelContainer as HTMLElement);

        const imgUrl = canvas.toDataURL('image/png', 1);
        if (!imgUrl) {
            canvas.remove();
            return;
        }

        const image = document.createElement('image');
        image.setAttribute('href', imgUrl);
        image.setAttribute('x', '32');
        image.setAttribute('y', '0');
        image.setAttribute('width', `${width}`);
        image.setAttribute('height', `${height}`);

        const svg = createSvgWrapper();
        svg.appendChild(image);
        svg.setAttribute('width', `${width}`);
        svg.setAttribute('height', `${height}`);

        return { svg, canvas, image };
    };

    const addLegend = async (svgContainer: SVGElement) => {
        const foreignObj = svgContainer.querySelector(`.${CHOROPLETH_LEGEND_CLASSNAME}`);
        let choroplethWrapper = svgContainer.querySelector(`.${CHART_CONTENT_CLASSNAME}`)?.parentElement;
        if (!foreignObj || !choroplethWrapper) {
            return;
        }

        const container = document.createElement('div');
        container.innerHTML = foreignObj.innerHTML;

        const parent = svgContainer.parentElement;
        if (!parent) {
            container.remove();
            return;
        }
        const containerFirstChild = container.firstChild as HTMLElement;
        if (!containerFirstChild) {
            container.remove();
            return;
        }
        container.className = styles.svgLegendContainer;
        containerFirstChild.className = styles.svgLegendContent;
        parent.appendChild(container);

        const { height, width } = containerFirstChild.getBoundingClientRect();
        const { height: contentHeight } = choroplethWrapper.getBoundingClientRect();
        const canvas = await html2canvas(containerFirstChild);

        const clonedSvg = svgContainer.cloneNode() as SVGElement;
        clonedSvg.innerHTML = svgContainer.innerHTML;

        const newHeight = contentHeight + height + 16;
        clonedSvg.setAttribute('height', `${newHeight}`);
        clonedSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

        const imgUrl = canvas.toDataURL('image/png', 1);
        if (!imgUrl) {
            container.remove();
            clonedSvg.remove();
            canvas.remove();
            return;
        }

        const image = document.createElement('image');
        image.setAttribute('href', imgUrl);
        image.setAttribute('x', '0');
        image.setAttribute('y', `${contentHeight + 16}`);
        image.setAttribute('width', `${width}`);
        image.setAttribute('height', `${height}`);
        clonedSvg.appendChild(image);

        choroplethWrapper = clonedSvg.querySelector(`.${CHART_CONTENT_CLASSNAME}`)?.parentElement;
        choroplethWrapper?.setAttribute('y', '0');

        return { svg: clonedSvg, image, container, canvas };
    };

    const actions = useMemo(() => {
        const newActions = [
            <IconButton
                key="edit-settings"
                size="small"
                color="inherit"
                onClick={(e) => {
                    e.stopPropagation();
                    handleEditConfig(config);
                }}
            >
                <Icon fontSize="inherit" color="inherit">
                    settings
                </Icon>
            </IconButton>,
        ];

        if (user?.permissions?.map(({ codename }) => codename).includes('can_export_charts')) {
            newActions.push(
                <IconButton key="export" size="small" color="inherit" onClick={downloadChart}>
                    <Icon fontSize="inherit" color="inherit">
                        download
                    </Icon>
                </IconButton>
            );
        }
        newActions.push(
            <IconButton
                key="close"
                size="small"
                color="inherit"
                onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveChart(id);
                }}
            >
                <Icon fontSize="inherit">close</Icon>
            </IconButton>
        );

        return newActions;
    }, [user, config]);

    const formatAxisLabel = useCallback(
        (x: DiscreteVariable) => {
            let label = x as string;
            if (x === 'ADMINISTRATIVE_LEVEL_1') {
                label = user?.organization?.country?.administrativeLevelNames[1] || 'District';
            }
            return startCase(label.toLowerCase());
        },
        [user]
    );

    const axes = {
        bottom: {
            label: formatAxisLabel(x),
            interval: ['MONTH', 'QUARTER', 'YEAR'].includes(x) ? x.toLowerCase() : null,
            isDate: ['MONTH', 'QUARTER', 'YEAR'].includes(x),
        },
        left: {
            label: startCase(y),
        },
    } as AxesProps;

    const palette = ['REGION', 'ADMINISTRATIVE_LEVEL_1'].includes(x)
        ? sequentialPalette('#d2dbe6', 'blue', 10)
        : [c4adsPalette['blue']];

    const data = useMemo(() => {
        let formatted: Series[] = [];

        if (y.endsWith('_RATE')) {
            if (dataset?.data) {
                formatted = dataset.data.map((d: Series) => {
                    return {
                        ...d,
                        data: d.data.map((dd: Record) => {
                            return { ...dd, data: dd.data * 100 };
                        }),
                    };
                });
            }
        } else {
            formatted = dataset?.data || [];
        }

        if (x === 'SPECIES') {
            formatted = formatted.map((d) => ({
                ...d,
                data: sortBy(
                    d.data.map((dd) => ({ ...dd, label: dd.label === '' ? 'Unknown' : dd.label })),
                    'label'
                ),
            }));
        }

        return formatted;
    }, [dataset, x, y, groupby]);

    const domainY = useMemo(() => {
        if (y.endsWith('_RATE')) {
            return [0, 100];
        }

        if (dataset?.markOptions && dataset.markOptions[0] === 'MultiBar') {
            const allValues = flatMap(dataset.data, (dd) => dd.data).map((d) => d.data);
            const min = Math.min(0, Math.min(...allValues));
            const max = Math.max(...allValues);
            return [min, max];
        }
    }, [y, dataset?.markOptions]);

    const legendValues = useMemo(() => {
        switch (groupby) {
            case 'MONTH':
                return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            case 'QUARTER':
                return ['01', '04', '07', '10'];
            default:
                return undefined;
        }
    }, [groupby]);

    const formatLegendLabel = useMemo(() => {
        switch (groupby) {
            case 'MONTH':
                return (label: string) => DateTime.fromISO(`2000-${label}-01`).toFormat('LLL');
            case 'QUARTER':
                return (label: string) => {
                    return `Q${DateTime.fromISO(`2000-${label}-01`).toFormat('q')}`;
                };
            case 'YEAR':
                return (label: string) => DateTime.fromISO(label).toFormat('yyyy');
            default:
                return undefined;
        }
    }, [groupby]);

    const groupAccessor = useMemo(() => {
        switch (groupby) {
            case 'MONTH':
            case 'QUARTER':
                return (key: string) => key.split('-')[1];
            default:
                return undefined;
        }
    }, [groupby]);

    const chartProps = {
        title: dataset?.title,
        description: dataset?.description,
        markOptions: dataset?.markOptions,
        data,
        error,
        errorImgSrc: errorImgSrc,
        noDataImgSrc: noDataImgSrc,
        actions: actions,
        activeMarkIndex: 0,
        width: 400,
        height: 300,
        palette: palette,
        axes: axes,
        domainY: domainY,
        legendValues: legendValues,
        xLines,
        yLines,
        formatLegendLabel: formatLegendLabel,
        groupAccessor: groupAccessor,
    } as ChartProps;

    return (
        <div ref={ref} style={{ height: '100%' }}>
            {loading || (propsLoading && <FullScreenLoader />)}
            {!propsLoading &&
                children({
                    chartProps: chartProps,
                })}
        </div>
    );
};

export default ChartPropProvider;
