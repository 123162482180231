import { useCallback, useMemo } from 'react';
import { indexOf, concat, difference, includes, without } from 'lodash';
import { Box, Stack, Typography } from '@mui/material';

export interface LegendProps {
    seriesLabels: string[];
    hoverSeries: string | null;
    setHoverSeries: React.Dispatch<React.SetStateAction<string | null>>;
    selectedSeries: string[];
    setSelectedSeries: React.Dispatch<React.SetStateAction<string[]>>;
    palette: string[];
    height: number;
    width: number;
    formatLegendLabel?: (label: string) => string;
}

export interface SeriesLabelProps {
    onClick?: (label: string) => void;
    onMouseEnter?: (label: string) => void;
    onMouseOut?: () => void;
    label: string;
    borderColor?: string;
    bgColor?: string;
    formatLegendLabel?: (label: string) => string;
    className?: string;
}

export default function Legend({
    seriesLabels,
    hoverSeries,
    setHoverSeries,
    selectedSeries,
    setSelectedSeries,
    palette,
    height,
    width,
    formatLegendLabel,
}: LegendProps) {
    const onMouseEnter = useCallback(
        (label: string) => {
            if (label !== hoverSeries) {
                setHoverSeries(label);
            }
        },
        [hoverSeries, setHoverSeries]
    );

    const onMouseOut = useCallback(() => {
        setHoverSeries(null);
    }, [setHoverSeries]);

    const onClick = useCallback(
        (label: string) => {
            setSelectedSeries((prev) => {
                if (prev.length < palette.length) {
                    return includes(prev, label) ? without(prev, label) : [...prev, label];
                } else {
                    return includes(prev, label) ? without(prev, label) : prev;
                }
            });
        },
        [setSelectedSeries]
    );

    const orderedSeriesLabels = useMemo(() => {
        const labels = selectedSeries;
        const unselected = difference(seriesLabels, selectedSeries).sort();
        return concat(labels, unselected);
    }, [seriesLabels, selectedSeries]);

    return (
        <Stack
            spacing={1}
            sx={{
                width: width,
                height: height,
                overflow: 'auto',
                backgroundColor: '#ffffff',
                px: 2,
            }}
        >
            {orderedSeriesLabels.map((label: string) => {
                const activeIndex = indexOf(selectedSeries, label);
                const bgColor = activeIndex > -1 ? palette[activeIndex] : 'transparent';
                const borderColor = activeIndex > -1 ? palette[activeIndex] : '#e9edf2';
                return (
                    <SeriesLabel
                        key={label}
                        bgColor={bgColor}
                        borderColor={borderColor}
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        label={label}
                        onMouseOut={onMouseOut}
                        formatLegendLabel={formatLegendLabel}
                    />
                );
            })}
        </Stack>
    );
}

export const SeriesLabel = ({
    onClick,
    onMouseEnter,
    onMouseOut,
    label,
    borderColor,
    bgColor,
    formatLegendLabel,
    className,
}: SeriesLabelProps) => {
    return (
        <Box
            sx={{
                display: 'inline-flex',
                gap: 2,
                alignItems: 'center',
                px: 1,
                py: 0.5,
                borderRadius: 1,
                cursor: 'pointer',
            }}
            onClick={() => onClick && onClick(label)}
            onMouseEnter={() => onMouseEnter && onMouseEnter(label)}
            onMouseOut={() => onMouseOut && onMouseOut()}
            className={className}
        >
            <Box
                sx={{
                    width: 16,
                    height: 16,
                    border: 'solid',
                    borderRadius: 1,
                    borderWidth: 1,
                    borderColor: borderColor,
                    bgcolor: bgColor,
                    flex: 'none',
                }}
            />
            <Typography variant="caption" color="primary">
                {formatLegendLabel ? formatLegendLabel(label) : label}
            </Typography>
        </Box>
    );
};
