export const courtCaseMapper = (payload: any) => {
    if (!payload) {
        return;
    }
    let startDate = payload.startDate ? new Date(payload.startDate) : undefined;
    startDate = startDate?.toString() === 'Invalid Date' ? undefined : startDate;
    let endDate = payload.endDate ? new Date(payload.endDate) : undefined;
    endDate = endDate?.toString() === 'Invalid Date' ? undefined : endDate;

    return {
        name: payload.name || '',
        courtId: payload.courtId || '',
        docket: payload.docket,
        startDate,
        endDate,
        isConcluded: Boolean(payload.isConcluded),
    };
};
