import React, { ReactNode, useEffect, useState } from 'react';
import { ControlledAccordion, ControlledAccordionProps } from '../ControlledAccordion';
import styles from './DropDown.module.css';
import cn from 'classnames';

type DropDownTitle = ReactNode | ((onClick: () => void) => ReactNode);

interface DropDownProps<T> extends Partial<ControlledAccordionProps<T>> {
    trigger: DropDownTitle;
    content: ReactNode;
    defaultOpen?: boolean;
    open?: boolean;
    titleContainerClassName?: string;
}

export const DropDown = <T,>({
    trigger,
    content,
    defaultOpen,
    open,
    titleContainerClassName,
    ...props
}: DropDownProps<T>) => {
    const [activeItem, setActiveItem] = useState<T | undefined>(defaultOpen ? ('dropdown' as T) : undefined);

    useEffect(() => {
        setActiveItem(open ? ('dropdown' as T) : undefined);
    }, [open]);

    return (
        <ControlledAccordion
            titleContainerClassName={titleContainerClassName}
            summaryClassName={styles.accordionSummary}
            detailsClassName={styles.accordionDetails}
            expandIconPosition="left"
            hideUnderline
            activeItem={activeItem}
            onActiveItem={setActiveItem}
            {...props}
            items={[
                {
                    key: 'dropdown' as T,
                    id: 'dropdown',
                    title: trigger,
                    content,
                },
            ]}
        />
    );
};
