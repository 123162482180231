import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';
import styles from './FormItem.module.css';

interface FormItemWrapperProps extends PropsWithChildren {
    className?: string;
}

export const FormItemWrapper = ({ children, className }: FormItemWrapperProps) => {
    return (
        <Box display="flex" flexDirection="column" className={cn(styles.container, className)}>
            {children}
        </Box>
    );
};
