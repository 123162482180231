import { gql } from '@apollo/client';

const DELETE_PERSON = gql`
    mutation deletePerson($input: ID!) {
        deletePerson(input: $input) {
            ok
        }
    }
`;

export default DELETE_PERSON;
