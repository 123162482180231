import { Suspense, useEffect } from 'react';
import { Button } from '@c4ads/c4blocks';
import { Box, Typography, LinearProgress } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Forms } from './Forms';
import { GET_CASE_FORM } from '../../../api/apollo/query/GetCaseForm';
import { CaseFormType } from '../../../types/CaseForm';
import styles from './CaseForm.module.css';

interface CaseFormContentProps {
    id?: string;
}

const CaseForm = () => {
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        if (id === ':id') {
            navigate('/case-forms');
        }
    }, [id]);

    return (
        <Suspense
            fallback={
                <Box className={styles.progressContainer} display="flex" alignItems="center" justifyContent="center">
                    <LinearProgress className={styles.linearProgress} />
                </Box>
            }
        >
            <CaseFormContent id={id} />
        </Suspense>
    );
};

const CaseFormContent = ({ id }: CaseFormContentProps) => {
    const navigate = useNavigate();
    const { data } = useSuspenseQuery<{ caseForm: CaseFormType | null }>(GET_CASE_FORM, {
        variables: { id },
        errorPolicy: 'ignore',
    });

    const onBack = () => {
        navigate('/case-forms', { replace: true });
    };

    return id === ':id' ? null : data?.caseForm ? (
        <Forms caseForm={data.caseForm} />
    ) : (
        <Box>
            <Typography variant="h4">Case form not found.</Typography>
            <Button onClick={onBack}>Back to Case forms page</Button>
        </Box>
    );
};

const CaseFormRoute = {
    routeProps: {
        path: '/case-forms/:id',
        element: <CaseForm />,
    },
    name: 'Case Forms',
};

export default CaseFormRoute;
