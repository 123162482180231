import { createContext } from 'react';
import { User } from '../../../../types/user';
import { Commodity, Lookups, Species } from '../../../../types';
import { CourtCaseFilters } from '../../../../types/CourtCaseFilters';
import { RawAdvancedFilter } from '../../../../components/AdvancedFiltersManager';
import { ADVANCED_FILTER } from '../../../../constants/localStorageConstants';

export interface AdvancedFilterContextInterface {
    user?: User;
    commodityOptions: Commodity[];
    protectionLevelOptions: string[];
    availableFields: string[];
    legislation: string[];
    legislationParts: string[];
    hearingStageOptions: string[];
    defaultFiltersValue: CourtCaseFilters;
    currentFilter?: RawAdvancedFilter;
    trackedSpecies: Species[];
    lookups?: Lookups;
}

export interface StorageConfigInterface {
    config: CourtCaseFilters;
    order: string[];
}

export interface LocalStorageFilter {
    config: StorageConfigInterface;
    filter?: RawAdvancedFilter;
}

export const saveFilter = (data: { config: CourtCaseFilters; order: string[] }, selectedFilter?: RawAdvancedFilter) => {
    localStorage.setItem(ADVANCED_FILTER, JSON.stringify({ filter: selectedFilter, config: data }));
};

export const deleteFilter = () => {
    localStorage.removeItem(ADVANCED_FILTER);
};

export const getFilter = (): LocalStorageFilter | undefined => {
    const storageData = localStorage.getItem(ADVANCED_FILTER) || '{config: {}, order: []}';
    try {
        return JSON.parse(storageData) as LocalStorageFilter;
    } catch {
        return;
    }
};

export const initialValue: AdvancedFilterContextInterface = {
    protectionLevelOptions: [
        'Endangered Species',
        'Listed Species',
        'Protected Species',
        'Game Species',
        'Forest Produce',
    ],
    commodityOptions: [],
    availableFields: [],
    legislation: [],
    legislationParts: [],
    hearingStageOptions: [],
    defaultFiltersValue: {},
    trackedSpecies: [],
};

export const AdvancedFilterContext = createContext<AdvancedFilterContextInterface>(initialValue);
