import { GlobalStyles } from '@mui/material';

export const TableGlobalStyles = () => {
    return (
        <GlobalStyles
            styles={{
                '.MuiDataGrid-menuList': {
                    '& .MuiMenuItem-root': {
                        color: 'var(--color-primary)',
                        '& .MuiSvgIcon-root': {
                            fill: 'var(--color-primary)',
                        },
                    },
                },
                '& .MuiDataGrid-panelFooter': {
                    '& .MuiButton-root': {
                        color: 'var(--color-primary)',
                    },
                },
                '& .MuiDataGrid-columnsPanel': {
                    '& .MuiFormControlLabel-label': {
                        color: 'var(--color-primary)',
                    },
                    '& .MuiSwitch-root .Mui-checked': {
                        color: 'var(--color-primary)',
                    },
                },
            }}
        />
    );
};
