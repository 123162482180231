import { Typography } from '@mui/material';
import cn from 'classnames';
import styles from './FormItem.module.css';

interface ValueProps {
    content: string;
    className?: string;
}

export const Value = ({ content, className }: ValueProps) => {
    return <Typography className={cn(styles.value, className)}>{content.replace('_', ' ')}</Typography>;
};
