import { Typography } from '@mui/material';
import cn from 'classnames';
import styles from './RowColumn.module.css';

interface ColumnRowProps {
    className?: string;
    children: string;
}

export const RowColumn = ({ children, className }: ColumnRowProps) => {
    return (
        <Typography title={children} className={cn(styles.column, className)}>
            {children}
        </Typography>
    );
};
