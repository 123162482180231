import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './Messages.module.css';
import { Typography } from '@mui/material';

interface ErrorMessageProps {
    message?: ReactNode;
    className?: string;
    marginLeft?: number;
}

export const ErrorMessage = ({ message, className, marginLeft }: ErrorMessageProps) => {
    return (
        <Typography style={{ marginLeft }} className={cn(styles.errorMessage, className)}>
            {message}
        </Typography>
    );
};
