import { parse, format } from 'date-fns';

export const stringifyTime = (date?: Date) => {
    return format(date || new Date(), 'hh:mm:ss');
};

export const parseTime = (dateString?: string) => {
    if (!dateString) {
        return new Date();
    }

    return parse(dateString, 'hh:mm:ss', new Date());
};

export const stringifyDate = (date?: Date) => {
    if (date?.toString() === 'Invalid Date') {
        return format(new Date(), 'yyyy-MM-dd');
    }

    return format(date || new Date(), 'yyyy-MM-dd');
};

export const parseDate = (dateString?: string) => {
    if (!dateString) {
        return;
    }

    return parse(dateString, 'yyyy-MM-dd', new Date());
};
