import { gql } from '@apollo/client';

const GET_CHARGES = gql`
    query indictmentCharges {
        indictmentCharges {
            id
            indictment {
                courtCase {
                    docket
                }
                defendant {
                    name
                }
                outcomeDate
            }
            charge {
                legislation
                part
                penaltyPart
            }
            proposedCustodialMonths
            proposedFine
            proposedCommunityServiceHours
            proposedSuspendedMonths
            chargeWithdrawn
            guiltyPlea
            guiltyVerdict
            isConcurrent
        }
    }
`;

export default GET_CHARGES;
