import React from 'react';
import { GridAggregationFunction } from '@mui/x-data-grid-premium';
import { Tooltip } from '@mui/material';
import { GridValueFormatterParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const dateFormatter = (params: GridValueFormatterParams<string>) => {
    return DateTime.fromISO(params.value).toISODate();
};

const dateTimeFormatter =
    (format: string = 'yyyy-LL-dd hh:mm') =>
    (params: GridValueFormatterParams<string>) => {
        const date = DateTime.fromISO(params.value);
        if (!date.isValid) {
            return '--';
        }
        return date.toFormat(format);
    };

const currencyFormatter = (value: number | undefined, currency: string | undefined) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: currency ? 'currency' : 'decimal',
        currency: currency,
    });

    return value ? formatter.format(value) : '';
};

const renderString = (value: string | undefined) => {
    return (
        value || (
            <Tooltip title="No data" enterDelay={1000}>
                <HorizontalRuleIcon color="disabled" />
            </Tooltip>
        )
    );
};

const renderCurrency = (value: number | undefined, currency: string | null) => {
    return value ? (
        currencyFormatter(value, currency ? currency : undefined)
    ) : (
        <Tooltip title="No data" enterDelay={1000}>
            <HorizontalRuleIcon color="disabled" />
        </Tooltip>
    );
};

const renderNumber = (value: number | undefined | null, formatter?: (params: number | undefined | null) => string) => {
    return value !== undefined && value !== null && value !== 0 ? (
        `${formatter ? formatter(value) : value}`
    ) : (
        <Tooltip title="No data" enterDelay={1000}>
            <HorizontalRuleIcon color="disabled" />
        </Tooltip>
    );
};

const renderBoolean = (value: boolean | undefined | null, title?: string) => {
    if (value === undefined || value === null) {
        return (
            <Tooltip title="No data" enterDelay={1000}>
                <HorizontalRuleIcon color="disabled" />
            </Tooltip>
        );
    }

    const content = value ? <CheckCircleOutlineIcon color="secondary" /> : <HighlightOffIcon color="primary" />;

    return title ? (
        <Tooltip title={title} enterDelay={1000}>
            {content}
        </Tooltip>
    ) : (
        content
    );
};

const aggregateCompareFunction = (field: string, mode: 'min' | 'max'): GridAggregationFunction<any, number | null> => {
    const getResult = (currentValue: number, currentResult: number | null) => {
        if (currentResult === null) {
            currentResult = currentValue[field];
        } else {
            switch (mode) {
                case 'min':
                    if (currentResult > currentValue[field]) {
                        currentResult = currentValue[field];
                    }
                    break;
                case 'max':
                    if (currentResult < currentValue[field]) {
                        currentResult = currentValue[field];
                    }
                    break;
                default:
                    currentResult = currentValue[field];
            }
        }
        return currentResult;
    };

    return {
        label: `${field} ${mode} func`,
        getCellValue: ({ row }) => ({ [field]: row[field] }),
        apply: (params) => {
            let result: null | number = null;

            params.values.forEach((value) => {
                if (value[field] === undefined || value[field] === null) {
                    return;
                }
                result = getResult(value, result);
            });
            return result;
        },
        columnTypes: ['number'],
    };
};

export const tableHelpers = {
    dateFormatter,
    dateTimeFormatter,
    currencyFormatter,
    renderCurrency,
    renderNumber,
    renderString,
    renderBoolean,
    aggregateCompareFunction,
};
