import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

type ReturnType = [string[], (newCount: number) => void, (keys: string[]) => void];

const genKeys = (count: number): string[] => {
    return new Array(count).fill('').map(() => uuid());
};

export const useUUID = (count: number, dependencies?: any[]): ReturnType => {
    const [currentCount, setCurrentCount] = useState(count);
    const [keys, setKeys] = useState<string[]>(genKeys(count));

    useEffect(() => {
        setKeys(genKeys(currentCount));
    }, dependencies || []);

    const setCount = (newCount: number) => {
        setKeys(genKeys(newCount));
        setCurrentCount(newCount);
    };

    const setOrder = (keys: string[]) => {
        setKeys(keys);
    };

    return [keys, setCount, setOrder];
};
