import ChartRoot from './ChartRoot';
import ChartHeader from './ChartHeader';
import ChartContent from './ChartContent';
import { ChartProps } from './types';

export { default as ChartGradients } from './Gradients';
export * from './types';
export * from './constants';

export default function Chart(props: ChartProps): JSX.Element {
    return (
        <ChartRoot>
            <ChartHeader actions={props.actions} />
            <ChartContent {...props} activeMarkIndex={0} />
        </ChartRoot>
    );
}
