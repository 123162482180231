import cn from 'classnames';
import { IconButton } from '../../../../../../components/IconButton';
import { ReactComponent as TrashIcon } from '../../../../../../static/icons/svg/outlined/LIGHT trash.svg';
import styles from './DeleteButton.module.css';

interface DeleteButtonProps {
    onClick?: () => void;
    className?: string;
    color?: 'gray';
}

const colors = {
    gray: styles.gray,
};

export const DeleteButton = ({ color, onClick, className }: DeleteButtonProps) => {
    return (
        <IconButton
            className={cn(styles.deleteButton, color && colors[color], className)}
            icon={<TrashIcon className={styles.deleteIcon} />}
            onClick={onClick}
        />
    );
};
