import { gql } from '@apollo/client';

const GET_DASHBOARDS = gql`
    query GetDashboards {
        user {
            dashboardConfigs {
                id
                title
            }
        }
    }
`;

export default GET_DASHBOARDS;
