import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { z } from 'zod';
import { Hearing, schema } from './Hearing';
import { useFormsStore } from '../../store';
import { useFormContext } from '../../context/FormsContext';
import styles from './Hearings.module.css';

interface HearingsFormProps {
    hearingFormKey?: string;
}

interface HearingForm {
    key: string;
    payload?: z.infer<typeof schema>;
}

const preparePayload = (payload: any) => {
    if (!payload) {
        return [];
    }

    const currentPayload = payload as Record<string, z.infer<typeof schema>>;

    return Object.entries(currentPayload).map(([key, payload]) => {
        return {
            key,
            payload,
        };
    });
};

export const Hearings = ({ hearingFormKey }: HearingsFormProps) => {
    const { setSectionStatus } = useFormContext();
    const payload = useFormsStore((state) => state.sectionForms.hearings?.payload);
    const setSectionForm = useFormsStore((state) => state.setSectionForm);
    const setSectionFormToRemove = useFormsStore((state) => state.setSectionFormToRemove);
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const currentPayload = useRef(payload);

    const [hearingForms, setHearingForms] = useState<HearingForm[]>(preparePayload(payload));

    useEffect(() => {
        const errorsCount = Object.values(errors).filter(Boolean).length;
        setSectionStatus('hearings', errorsCount === 0);
    }, [errors]);

    useEffect(() => {
        const keys = hearingForms.map((form) => form.key);

        setErrors((prev) => {
            const cloned = { ...prev };
            Object.keys(cloned).forEach((key) => {
                if (!keys.includes(key)) {
                    delete cloned[key];
                }
            });

            return cloned;
        });
    }, [hearingForms]);

    useEffect(() => {
        setHearingForms(preparePayload(payload));
        currentPayload.current = payload;
    }, [payload]);

    useEffect(() => {
        if (hearingFormKey) {
            setSectionForm('hearings', { ...payload, [hearingFormKey]: {} });
        }
    }, [hearingFormKey]);

    const onChange = (key: string, result?: z.infer<typeof schema>) => {
        setSectionForm('hearings', { ...currentPayload.current, [key]: result });
    };

    const onDelete = (key: string) => {
        const clone = { ...currentPayload.current };
        if (clone?.id) {
            setSectionFormToRemove('hearings', clone.id);
        }

        delete clone[key];

        setSectionForm('hearings', clone);
    };

    return (
        <Box display="flex" flexDirection="column" className={styles.wrapper}>
            {!hearingForms.length && <Typography className={styles.noText}>No Hearings forms.</Typography>}
            {hearingForms.map((item) => (
                <Hearing
                    key={item.key}
                    defaultValues={item.payload}
                    onChange={(value) => {
                        onChange(item.key, value);
                    }}
                    onDelete={() => {
                        onDelete(item.key);
                    }}
                    onValid={(isValid) => {
                        setErrors((prev) => {
                            return { ...prev, [item.key]: !isValid };
                        });
                    }}
                />
            ))}
        </Box>
    );
};
