import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { OfficerComponent, OfficerType } from './Officer';
import { AddButton } from '../../../components/AddButton';
import { List } from '../../../components/ListComponents';
import styles from './Officers.module.css';

interface OfficersProps {
    defaultValues?: Array<OfficerType>;
    editMode?: boolean;
    onChange: (value: Array<OfficerType | undefined>) => void;
    onDisconnectOfficer?: (officerId: string) => void;
}

interface OfficerItem {
    key: string;
    value?: OfficerType;
}

const prepareOfficers = (officers?: OfficerType[]) => {
    return officers
        ? officers.map((item) => {
              return item ? { key: item.id ?? uuidv4(), value: item } : { key: uuidv4() };
          })
        : [];
};

export const Officers = ({ defaultValues, editMode, onChange: propOnChange, onDisconnectOfficer }: OfficersProps) => {
    const [officerItems, setOfficerItems] = useState<OfficerItem[]>(prepareOfficers(defaultValues));
    const officerItemsRef = useRef(officerItems);

    useEffect(() => {
        officerItemsRef.current = officerItems;
        propOnChange(officerItems?.map((item) => item.value) || []);
    }, [officerItems]);

    const addNew = () => {
        setOfficerItems((prev) => prev.concat([{ key: uuidv4() }]));
    };

    const onDelete = (key: string) => {
        const officer = officerItemsRef.current.find((item) => item.key === key);

        if (officer?.value?.id) {
            onDisconnectOfficer && onDisconnectOfficer(officer.value.id);
        }
        setOfficerItems(officerItemsRef.current.filter((item) => item.key !== key));
    };

    const onChange = (key: string) => {
        return (value?: OfficerType) => {
            setOfficerItems((prev) => {
                const cloned = prev.slice();
                const index = cloned.findIndex((item) => item.key === key);

                if (index !== -1) {
                    cloned[index].value = value;
                }
                return cloned;
            });
        };
    };

    return (
        <Box display="flex" flexDirection="column" className={styles.officers}>
            <Box display="flex" justifyContent="space-between" className={styles.officersHeader}>
                <Typography className={styles.subsectionTitle}>Officers</Typography>
                {editMode && <AddButton className={styles.addButton} title="+ Add new" onClick={addNew} />}
            </Box>
            <List>
                {officerItems.map((item) => (
                    <OfficerComponent
                        key={item.key}
                        defaultValues={item.value}
                        onDelete={() => onDelete(item.key)}
                        editMode={editMode}
                        onChange={onChange(item.key)}
                    />
                ))}
            </List>
        </Box>
    );
};
