import { useMemo, useId } from 'react';
import { buildChartTheme } from '@visx/xychart';
import { Typography, Box } from '@mui/material';
import useChartData from '../useChartData';
import Choropleth from '../Choropleth';
import XYChart from '../XYChart';
import Donut from '../Donut';
import MultiBar from '../MultiBar';
import Violin from '../Violin';
import { ChartProps, MultiSeries } from '../types';

const ChartRenderer = ({
    width,
    height,
    title,
    description,
    data,
    noDataImgSrc,
    error,
    errorImgSrc,
    axes,
    palette,
    markOptions,
    activeMarkIndex,
    domainY,
    legendValues,
    formatLegendLabel,
    groupAccessor = (key: string) => key,
    xLines,
    yLines,
}: ChartProps) => {
    const chartId = useId();

    const activeMark: string = useMemo(() => {
        return (markOptions ?? [])[activeMarkIndex || 0];
    }, [markOptions, activeMarkIndex]);

    const chartTheme = useMemo(() => {
        return buildChartTheme({
            backgroundColor: 'transparent',
            colors: palette,
            gridColor: '#bbb',
            gridColorDark: '#fafafa',
            tickLength: 6,
        });
    }, [palette]);

    const { keys, chartData } = useChartData(data, activeMark, groupAccessor);

    if (error) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={errorImgSrc} width={340} height="auto" alt="error occurred" />
                <Typography variant="body1" color="text.caption">
                    Oops! Something went wrong.
                </Typography>
            </Box>
        );
    }

    if (chartData) {
        if (keys.length === 0) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={noDataImgSrc} width={340} height="auto" alt="no results" />
                    <Typography variant="body1" color="text.caption">
                        No results found.
                    </Typography>
                </Box>
            );
        }

        switch (activeMark) {
            case 'Violin':
                return (
                    <Violin
                        id={chartId}
                        title={title}
                        description={description}
                        width={width}
                        height={height}
                        data={chartData as MultiSeries[]}
                        axes={axes}
                    />
                );
            case 'Choropleth':
                if (chartData.length > 0) {
                    return (
                        <Choropleth
                            title={title}
                            description={description}
                            width={width}
                            height={height}
                            data={chartData[0].data}
                            axes={axes}
                            palette={palette}
                        />
                    );
                } else {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img src={noDataImgSrc} width={340} height="auto" alt="no results" />
                            <Typography variant="body1" color="text.caption">
                                No results found.
                            </Typography>
                        </Box>
                    );
                }
            case 'Donut':
                return (
                    <Donut
                        id={chartId}
                        width={width}
                        height={height}
                        title={title}
                        description={description}
                        data={chartData}
                        axes={axes}
                        legendValues={legendValues}
                        groupAccessor={groupAccessor}
                        formatLegendLabel={formatLegendLabel}
                    />
                );
            case 'MultiBar':
                return (
                    <MultiBar
                        id={chartId}
                        width={width}
                        height={height}
                        title={title}
                        description={description}
                        data={chartData}
                        axes={axes}
                        formatLegendLabel={formatLegendLabel}
                        domainY={domainY}
                        groupAccessor={groupAccessor}
                        xLines={xLines}
                        yLines={yLines}
                    />
                );
            default:
                return (
                    <XYChart
                        id={chartId}
                        theme={chartTheme}
                        width={width}
                        height={height}
                        title={title}
                        description={description}
                        data={chartData}
                        activeMark={activeMark}
                        axes={axes}
                        legendValues={legendValues}
                        formatLegendLabel={formatLegendLabel}
                        groupAccessor={groupAccessor}
                        xLines={xLines}
                        yLines={yLines}
                    />
                );
        }
    }
    return null;
};

export default ChartRenderer;
