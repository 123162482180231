import { useMemo } from 'react';
import { LineSeries as LineSeriesPrimitive } from '@visx/xychart';
import { MarkProps } from '../types';
import { concat } from 'lodash';

export interface LineSeriesProps extends MarkProps {
    selectedSeries: string[];
}

export default function LineSeries({ data, colorAccessor, selectedSeries }: LineSeriesProps) {
    const lines = useMemo(() => {
        const selectedLines = data.filter((d) => selectedSeries.includes(d.label));
        const unselectedLines = data.filter((d) => !selectedSeries.includes(d.label));

        return concat(unselectedLines, selectedLines);
    }, [data, selectedSeries]);

    return (
        <>
            {lines.map(({ key, label, data }: { key: string; label: string; data: any[] }) => (
                <LineSeriesPrimitive
                    key={key}
                    data={data}
                    dataKey={label}
                    xAccessor={(d: any) => d.label}
                    yAccessor={(d: any) => d.data}
                    colorAccessor={(d: any) => colorAccessor(label)}
                />
            ))}
        </>
    );
}
