import { useEffect, useMemo, useState, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { debounce } from 'lodash';
import { formatDate } from '../../../utils';
import { seizedCommoditySchema } from './SeizedCommodities/SeizedCommodity';
import { useFormsStore } from '../../store';
import { useFormContext } from '../../context/FormsContext';
import { FormItem } from '../../components/FormItem';
import { NotesField } from '../../components/NotesField';
import { MultiFormTemplate } from '../../components/FormTemplates';
import { FormAutocomplete } from '../../components/Autocomplete';
import { FormDatePicker } from '../../components/FormDatePicker';
import { FormRow } from '../../components/FormTemplates/FormRow';
import { LongTextView } from '../../../../../../components/LongTextView';
import { EmptyFormBlockWrapper } from '../../components/FormTemplates/EmptyFormBlockWrapper';
import { SeizedCommodities } from './SeizedCommodities';
import { Officers } from './Officers';
import { officerSchema } from './Officers/Officer';
import { DEBOUNCE_WAIT, EMPTY_PLACEHOLDER } from '../../constants';
import styles from './Seizures.module.css';

export const schema = z.object({
    id: z.coerce.string().optional(),
    date: z.coerce.date(),
    time: z.string().nullable().optional(),
    locationName: z.string().optional(),
    streetAddress: z.string().optional(),
    administrativeLevel2Id: z.string(),
    notes: z.string().optional(),

    seizedCommodities: z.array(seizedCommoditySchema),
    officers: z.array(officerSchema),

    disconnectOfficerIds: z.string().array().optional(),
    locationGeometry: z.string().optional().nullable(),
});

type SeizureType = z.infer<typeof schema>;

interface SeizureProps {
    defaultValues?: SeizureType;
    onChange?: (result: SeizureType, updateStatus?: boolean) => void;
    onDelete?: () => void;
    onValid?: (isValid: boolean) => void;
}

export const Seizure = memo(
    ({ defaultValues, onChange, onDelete: propOnDelete, onValid }: SeizureProps) => {
        const { onSubmitListener } = useFormContext();
        const administrativeLevel2s = useFormsStore((state) => state.lookups.administrativeLevel2s);
        const lookupsIsLoading = useFormsStore((state) => state.lookupsIsLoading);
        const contextEditMode = useFormsStore((state) => state.editMode);
        const [initialValue] = useState({
            ...defaultValues,
            officers: defaultValues?.officers ?? [],
        });
        const {
            control,
            formState: { errors },
            handleSubmit,
            setValue,
            watch,
        } = useForm<z.infer<typeof schema>>({
            resolver: zodResolver(schema),
            defaultValues: initialValue,
        });

        const [disconnectOfficerIds, setDisconnectOfficerIds] = useState<string[]>([]);

        useEffect(() => {
            setValue('disconnectOfficerIds', disconnectOfficerIds);
        }, [disconnectOfficerIds]);

        useEffect(() => {
            const errorsCount = Object.keys(errors).length;

            onValid?.(errorsCount === 0);
        }, [JSON.stringify(errors)]);

        useEffect(() => {
            let currentData: SeizureType;
            const onChangeDebounce = debounce(() => {
                onChange && onChange(currentData);
            }, DEBOUNCE_WAIT);

            const { unsubscribe } = watch((data) => {
                currentData = data as SeizureType;
                onChangeDebounce();
            });

            return unsubscribe;
        }, [watch]);

        useEffect(() => {
            return onSubmitListener('seizures', () => {
                return new Promise((resolve) => {
                    handleSubmit(
                        (data) => {
                            resolve({ type: 'payload', result: data });
                        },
                        () => {
                            resolve({ type: 'payload', result: false });
                        }
                    )();
                });
            });
        }, []);

        const editMode = useMemo(() => contextEditMode === 'edit', [contextEditMode]);

        const getAdministrativeLevel2 = (levelId?: string) => {
            return administrativeLevel2s.values.find((item) => item.id === levelId);
        };

        const onDelete = () => {
            propOnDelete && propOnDelete();
        };

        const onDisconnectOfficer = (officerId: string) => {
            setDisconnectOfficerIds(disconnectOfficerIds.filter((id) => id !== officerId).concat([officerId]));
        };

        return (
            <MultiFormTemplate
                editMode={editMode}
                headerButtonIsAbsolute
                FormBlockWrapper={EmptyFormBlockWrapper}
                header={
                    <>
                        <Box sx={{ width: '100%' }}>
                            <Typography className={styles.subsectionTitle}>Event Details</Typography>
                        </Box>
                        <FormRow>
                            <Controller
                                name="date"
                                control={control}
                                render={({ field }) => (
                                    <FormItem
                                        errorMessage={errors.date?.message}
                                        editMode={editMode}
                                        label="Date"
                                        value={field.value}
                                        renderValue={formatDate}
                                        renderInput={(value) => (
                                            <FormDatePicker
                                                errorMessage={errors.date?.message}
                                                value={value}
                                                onChange={(value) => {
                                                    field.onChange({ target: { value } });
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Controller
                                name="locationName"
                                control={control}
                                render={({ field }) => (
                                    <FormItem
                                        errorMessage={errors?.locationName?.message}
                                        className={styles.headerField}
                                        editMode={editMode}
                                        label="Location"
                                        value={field.value}
                                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value },
                                            });
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="streetAddress"
                                control={control}
                                render={({ field }) => (
                                    <FormItem
                                        errorMessage={errors?.streetAddress?.message}
                                        editMode={editMode}
                                        label="Street Address"
                                        value={field.value}
                                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value },
                                            });
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                name="administrativeLevel2Id"
                                control={control}
                                render={({ field }) => (
                                    <FormItem
                                        errorMessage={errors?.administrativeLevel2Id?.message}
                                        isLoading={lookupsIsLoading}
                                        editMode={editMode}
                                        label={administrativeLevel2s.localName}
                                        value={field.value}
                                        onChange={(value) => {
                                            field.onChange({
                                                target: { value },
                                            });
                                        }}
                                        renderValue={(value) =>
                                            getAdministrativeLevel2(value)?.name || EMPTY_PLACEHOLDER
                                        }
                                        renderInput={(value) => (
                                            <FormAutocomplete
                                                disablePortal={false}
                                                errorMessage={errors?.administrativeLevel2Id?.message}
                                                label={null}
                                                options={administrativeLevel2s.values}
                                                getOptionLabel={(option) => option.name || EMPTY_PLACEHOLDER}
                                                value={getAdministrativeLevel2(value)}
                                                onChange={(_e, newValue) => {
                                                    field.onChange({ target: { value: newValue?.id } });
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </FormRow>
                    </>
                }
                formBlocks={[
                    <Controller
                        key="seizedCommodities"
                        name="seizedCommodities"
                        control={control}
                        render={({ field }) => (
                            <SeizedCommodities
                                onChange={(value) => {
                                    field.onChange({
                                        target: {
                                            value,
                                        },
                                    });
                                }}
                                editMode={editMode}
                                defaultValues={defaultValues?.seizedCommodities}
                            />
                        )}
                    />,
                    <Controller
                        key="officers"
                        name="officers"
                        control={control}
                        render={({ field }) => (
                            <Officers
                                onChange={(value) => {
                                    field.onChange({
                                        target: {
                                            value,
                                        },
                                    });
                                }}
                                editMode={editMode}
                                defaultValues={initialValue?.officers}
                                onDisconnectOfficer={onDisconnectOfficer}
                            />
                        )}
                    />,
                ]}
                notes={
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field }) => (
                            <FormItem
                                errorMessage={errors.notes?.message}
                                className={styles.notesFormItem}
                                editMode={editMode}
                                label="Additional Notes:"
                                value={field.value}
                                renderValue={(value) => <LongTextView>{value || ''}</LongTextView>}
                                renderInput={(value) => (
                                    <NotesField
                                        onChange={(e) => {
                                            field.onChange({
                                                target: { value: e.target.value },
                                            });
                                        }}
                                        value={value}
                                        errorMessage={errors.notes?.message}
                                    />
                                )}
                            />
                        )}
                    />
                }
                onDelete={onDelete}
            />
        );
    },
    /*
        This optimization is possible because the props we pass do not necessarily need to be always up-to-date;
        For such components, initialization is sufficient.
     */
    () => true
);
