import { AreaStack as AreaStackPrimitive, AreaSeries as AreaSeriesPrimitive } from '@visx/xychart';
import { MarkProps } from '../types';

export default function AreaStack({ data, colorAccessor }: MarkProps) {
    return (
        <AreaStackPrimitive order="descending">
            {data.map(({ key, label, data }: { key: string; label: string; data: any[] }) => (
                <AreaSeriesPrimitive
                    key={key}
                    data={data}
                    dataKey={label}
                    xAccessor={(d: any) => d.label}
                    yAccessor={(d: any) => d.data}
                    fill={colorAccessor(label)}
                    renderLine={true}
                    lineProps={{
                        stroke: '#fff',
                        strokeWidth: 1,
                    }}
                />
            ))}
        </AreaStackPrimitive>
    );
}
