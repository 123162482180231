import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router';
import { Box, Typography, IconButton } from '@mui/material';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridColDef, GridActionsCellItem, GridActionsColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { SearchInput } from '../components/SearchInput';
import { ChangePermissionsSettingsFunc, PermissionSettings } from '../superUserTableTools';
import { TableBox } from '../components/TableBox';
import { BackButton } from '../components/BackButton';
import { AddButton } from '../components/AddButton';
import { AddUserDialog } from '../components/AddUserDialog';
import { DeleteUserDialog } from '../components/DeleteUserDialog';
import { CustomTable } from '../../../../../components/CustomTable';
import { ColumnHeaderName } from '../../../../../components/CustomTable/ColumnHeaderName';
import { RowColumn } from '../../../../../components/CustomTable/RowColumn';
import { openSnackBar } from '../../../../../components/Snackbar';
import { PermissionDropdown } from '../components/PermissionDropdown';
import { AccountContext, AccountContextInterface } from '../../../AccountContext';
import { SuperUserOutletInterface } from '../index';
import { GET_USERS } from '../../../../../api/apollo/query';
import { UPDATE_USER_PERMISSION, UPDATE_USER_ADMIN_STATUS } from '../../../../../api/apollo/mutation';
import DELETE_USER from '../../../../../api/apollo/mutation/DeleteUser';
import { User } from '../../../../../types/user';
import superUserStyles from '../SuperUser.module.css';
import styles from './Users.module.css';

type PermissionsObject = Record<string, { id: string; name: string }>;

interface Permission {
    codename: string;
    id: string;
    name: string;
}

const viewPermissions: PermissionsObject = {
    view_courtcase: {
        id: '96',
        name: 'View Court Cases',
    },
    view_details: {
        id: '270',
        name: 'View Court Case Details',
    },
    view_defendant: {
        id: '104',
        name: 'View Defendants',
    },
    view_hearing: {
        id: '204',
        name: 'View Hearings',
    },
    view_seizure: {
        id: '84',
        name: 'View Seizures',
    },
};

const exportPermissions: PermissionsObject = {
    can_export_tables: {
        id: '269',
        name: 'Export Tables',
    },
    can_export_charts: {
        id: '270',
        name: 'Export Charts',
    },
};

const userPermissions: PermissionsObject = {
    case_form_editor: {
        id: '277',
        name: 'Editor',
    },
    case_form_reviewer: {
        id: '278',
        name: 'Reviewer',
    },
    isAdmin: {
        id: 'isAdmin',
        name: 'Admin',
    },
};

const getPermissions = (
    permissionsObj: Record<string, { id: string; name: string }>,
    currentPermissions: Permission[]
) => {
    return Object.entries(permissionsObj).map(([code, { id, name }]) => {
        return {
            code,
            id,
            name,
            checked: !!currentPermissions.find((item) => item.codename === code),
        };
    });
};

const prepareColumns = (
    _clickOnDelete: (userId: string) => void,
    changePermissionsSettings: ChangePermissionsSettingsFunc
): (GridColDef | GridActionsColDef)[] => {
    return [
        {
            field: 'firstName',
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            minWidth: 120,
            flex: 1,
            groupable: false,
            disableColumnMenu: true,
            renderHeader: () => <ColumnHeaderName>First Name</ColumnHeaderName>,
            renderCell: ({ value }) => <RowColumn>{value}</RowColumn>,
        },
        {
            field: 'lastName',
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            minWidth: 120,
            flex: 1,
            groupable: false,
            disableColumnMenu: true,
            renderHeader: () => <ColumnHeaderName>Last Name</ColumnHeaderName>,
            renderCell: ({ value }) => <RowColumn>{value}</RowColumn>,
        },
        {
            field: 'email',
            type: 'string',
            headerAlign: 'left',
            align: 'left',
            minWidth: 240,
            flex: 1,
            groupable: false,
            disableColumnMenu: true,
            renderHeader: () => <ColumnHeaderName>Email</ColumnHeaderName>,
            renderCell: ({ value }) => <RowColumn>{value}</RowColumn>,
        },
        {
            field: 'viewPermissions',
            headerAlign: 'left',
            align: 'left',
            minWidth: 200,
            flex: 1,
            groupable: false,
            disableColumnMenu: true,
            valueGetter: (params) => {
                return getPermissions(viewPermissions, params.row.permissions).filter(
                    (permission) => permission.checked
                ).length;
            },
            renderHeader: () => <ColumnHeaderName>Tables</ColumnHeaderName>,
            renderCell: (params) => {
                return (
                    <PermissionDropdown
                        emptyTitle="None"
                        permissions={getPermissions(viewPermissions, params.row.permissions)}
                        onChange={(status, codeName) => {
                            changePermissionsSettings(status, codeName, params.row.id);
                        }}
                    />
                );
            },
        },
        {
            field: 'export',
            headerAlign: 'left',
            align: 'left',
            minWidth: 200,
            flex: 1,
            groupable: false,
            disableColumnMenu: true,
            valueGetter: (params) => {
                return getPermissions(exportPermissions, params.row.permissions).filter(
                    (permission) => permission.checked
                ).length;
            },
            renderHeader: () => <ColumnHeaderName>Export</ColumnHeaderName>,
            renderCell: (params) => {
                return (
                    <PermissionDropdown
                        emptyTitle="None"
                        permissions={getPermissions(exportPermissions, params.row.permissions)}
                        onChange={(status, codeName) => {
                            changePermissionsSettings(status, codeName, params.row.id);
                        }}
                    />
                );
            },
        },
        {
            field: 'user',
            headerAlign: 'left',
            align: 'left',
            minWidth: 200,
            flex: 1,
            groupable: false,
            disableColumnMenu: true,
            valueGetter: (params) => {
                return getPermissions(userPermissions, params.row.permissions).filter(
                    (permission) => permission.checked
                ).length;
            },
            renderHeader: () => <ColumnHeaderName>User</ColumnHeaderName>,
            renderCell: (params) => {
                return (
                    <PermissionDropdown
                        emptyTitle="None"
                        isAdmin={params.row.isAdmin}
                        permissions={getPermissions(userPermissions, params.row.permissions)}
                        onChange={(status, codeName) => {
                            changePermissionsSettings(status, codeName, params.row.id);
                        }}
                    />
                );
            },
        },
        {
            field: 'actions',
            type: 'actions',
            width: 40,
            getActions: (params: GridRowParams) => [
                //@ts-ignore
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => _clickOnDelete(params.row.id)}
                />,
            ],
        },
    ];
};

export const UsersContext = createContext<PermissionSettings>({});

export const UsersTable = () => {
    const { user } = useOutletContext<SuperUserOutletInterface>();
    const { setTitle } = useContext<AccountContextInterface>(AccountContext);
    const [openUserInviteDialog, setOpenUserInviteDialog] = useState<boolean>(false);
    const [openRemoveDialog, setOpenRemoveDialog] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [users, setUsers] = useState<User[]>([]);
    const [search, setSearch] = useState('');
    /*
        permissionsSettings -> {userId: {'codename': true | false}}
    */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [permissionsSettings, setPermissionsSettings] = useState<PermissionSettings>({});
    const [userIdForDelete, setUserIdForDelete] = useState<string | undefined>();
    const {
        data,
        loading: loadingUsers,
        refetch,
        networkStatus,
    } = useQuery(GET_USERS, {
        notifyOnNetworkStatusChange: true,
    });
    const [deleteUser] = useMutation(DELETE_USER);
    const [updateUserPermission] = useMutation(UPDATE_USER_PERMISSION);
    const [updateUserAdminStatus] = useMutation(UPDATE_USER_ADMIN_STATUS);

    useEffect(() => {
        setTitle(
            <UsersTableTitle
                user={user}
                openAddUserDialog={() => {
                    setOpenUserInviteDialog(true);
                }}
            />
        );
    }, [user, setOpenUserInviteDialog]);

    useEffect(() => {
        if (loadingUsers || !data) {
            return;
        }
        setUsers(
            data.users.filter((item) => {
                if (!item?.name) {
                    return false;
                }
                return search ? item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 : true;
            })
        );
    }, [loadingUsers, data, search]);

    const onDelete = (userId: string) => {
        setUserIdForDelete(userId);
        setOpenRemoveDialog(true);
    };

    const changePermissionsSettings = async (status: boolean, codename: string, userId: string) => {
        setPermissionsSettings((prev) => {
            const userObj = users.find((item) => item.id === userId) as User;

            const permission =
                codename !== 'isAdmin'
                    ? userObj?.permissions?.find((permission) => permission.codename === codename)
                    : userObj.isAdmin;

            if ((permission && status) || (!permission && !status)) {
                const cloned = prev ? { ...prev } : { [userId]: {} };
                delete cloned[userId][codename];
                return cloned;
            }

            return {
                ...prev,
                [userId]: { ...prev[userId], [codename]: status },
            };
        });

        if (codename === 'isAdmin') {
            await updateUserAdminStatus({ variables: { user: { id: userId } } });
        } else {
            await updateUserPermission({ variables: { user: { id: userId }, codename } });
        }
    };

    const clickOnDeleteUser = async () => {
        const index = users.findIndex((user) => user.id === userIdForDelete);
        if (index !== -1) {
            const usersArrClone = [...users];
            const [userObj] = usersArrClone.splice(index, 1);

            setIsLoading(true);
            try {
                await deleteUser({ variables: { user: { id: userObj.id } } });
            } catch {
                openSnackBar({ type: 'error', message: 'Something went wrong.' });
            }

            setIsLoading(false);
            setUsers(usersArrClone);
        }
        setUserIdForDelete(undefined);
    };

    const onUpdateList = () => {
        refetch();
    };

    const columns = useMemo(() => {
        return prepareColumns(onDelete, changePermissionsSettings);
    }, [permissionsSettings, users]);

    const onSearch = (searchStr: string) => {
        setSearch(searchStr);
        setPage(0);
    };

    return (
        <UsersContext.Provider value={permissionsSettings}>
            <TableBox isLoading={isLoading}>
                <Box display="flex" flexDirection="column" className={styles.wrapper}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography className={styles.tableHeaderTitle}>Users</Typography>
                        <Box className={styles.tableHeaderToolbar} display="flex" alignItems="center">
                            <SearchInput placeholder="Search users" onChange={onSearch} />
                            <IconButton onClick={onUpdateList}>
                                <RefreshOutlinedIcon className={styles.toolbarIcon} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className={styles.usersTable}>
                        <CustomTable
                            columns={columns}
                            rows={users || []}
                            loading={loadingUsers || networkStatus === NetworkStatus.refetch}
                            pagination={{
                                pageSize: 10,
                                page: page,
                                onPage: setPage,
                            }}
                        />
                    </Box>
                </Box>
            </TableBox>
            <AddUserDialog open={openUserInviteDialog} setOpen={setOpenUserInviteDialog} onUpdateList={onUpdateList} />
            <DeleteUserDialog
                deleteUserID={userIdForDelete}
                open={openRemoveDialog}
                setOpen={setOpenRemoveDialog}
                onClick={clickOnDeleteUser}
            />
        </UsersContext.Provider>
    );
};

const UsersTableTitle = ({ user, openAddUserDialog }: { user: User; openAddUserDialog: () => void }) => {
    const navigate = useNavigate();

    const back = () => {
        navigate('/account');
    };

    return (
        <Box
            className={superUserStyles.titleContainer}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <BackButton onClick={back} tooltipContent="Back to Profile" buttonText="Back" />
            {(user.isAdmin || user.isSuperuser) && <AddButton buttonText="Add user" onClick={openAddUserDialog} />}
        </Box>
    );
};
