import { Box } from '@mui/material';
import { CreateCaseFormButton } from './CreateCaseForm';
import { CaseFormType } from '../../../../types';
import styles from './CaseFormsToolbar.module.css';

interface CaseFormsToolbarProps {
    caseForms: CaseFormType[];
}

export const CaseFormsToolbar = ({ caseForms }: CaseFormsToolbarProps) => {
    return (
        <Box display="flex" alignItems="center" className={styles.toolbarWrapper}>
            <CreateCaseFormButton caseForms={caseForms} />
        </Box>
    );
};
