import { gql } from '@apollo/client';

const GET_HEARINGS_PRESENT = gql`
    query getHearingsPresent {
        hearings {
            defendantsPresent {
                id
                firstName
                lastName
                sex
                birthdate
            }
            defenseAttorneysPresent {
                id
                firstName
                lastName
                sex
                birthdate
            }
            prosecutorsPresent {
                id
                firstName
                lastName
                sex
                birthdate
            }
        }
    }
`;

export default GET_HEARINGS_PRESENT;
