import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { sortBy, startCase } from 'lodash';
import { FormControl, Typography, Stack } from '@mui/material';
import { Autocomplete, DatePicker, ToggleButton, ToggleButtonGroup } from '@c4ads/c4blocks';
import CommoditySelect from './components/CommoditySelect';
import { useAuth } from '../../../api/auth';
import { Court } from '../../../types/Court';
import { Region } from '../../../types/Region';
import { Species, SpeciesGroup as SpeciesGroupType } from '../../../types/Lookups';
import { useQueryBuilderContext } from '../context';
import styles from '../filterFields.module.css';

export const specieWithoutGroup = 'Other';

// @ts-ignore
export const defaultIsOptionEqualToValue = <T,>(a: T, b: T) => a?.id === b?.id;

export default function FilterFields({
    visualization,
    control,
    setValue,
    renderValue,
    watch,
    activeProductFilter,
    setActiveProductFilter,
    activeLocationFilter,
    setActiveLocationFilter,
}) {
    const { user } = useAuth();
    const { lookups } = useQueryBuilderContext();

    const selectedY = watch('y');
    const selectedStart = watch('filters.start');
    const selectedEnd = watch('filters.end');

    const handleActiveProductFilter = useCallback(
        (event, value) => {
            setValue('filters.commodities', []);
            setValue('filters.species', []);
            setValue('filters.speciesGroup', []);
            setValue('filters.protectionLevels', []);
            setActiveProductFilter(value);
        },
        [setActiveProductFilter, setValue]
    );

    const handleActiveLocationFilter = useCallback(
        (event, value) => {
            setValue('filters.courts', []);
            setValue('filters.administrativeLevel1', []);
            setValue('filters.regions', []);
            setActiveLocationFilter(value);
        },
        [setActiveLocationFilter, setValue]
    );

    const speciesOptions = useMemo(() => {
        return sortBy(
            (lookups?.species || []).map(({ id, commonName, speciesGroup = 'Other' }) => ({
                id,
                commonName,
                speciesGroup,
            })),
            ['speciesGroup.name', 'commonName']
        );
    }, [lookups]);

    return (
        <>
            <FormControl>
                <Typography variant="overline" color="#9e9e9e">
                    Date Range
                </Typography>
                <Stack spacing={2} direction="row">
                    {selectedY !== 'OUTSTANDING_COURT_CASES' && (
                        <Controller
                            name="filters.start"
                            control={control}
                            render={({ field }) => (
                                <DatePicker label="Start" maxDate={selectedEnd} locale="en-mw" {...field} />
                            )}
                        />
                    )}
                    <Controller
                        name="filters.end"
                        control={control}
                        render={({ field }) => (
                            <DatePicker label="End" minDate={selectedStart} locale="en-mw" {...field} />
                        )}
                    />
                </Stack>
            </FormControl>
            <FormControl>
                <Typography variant="overline" color="#9e9e9e">
                    Product
                </Typography>
                <ToggleButtonGroup
                    color="primary"
                    size="small"
                    exclusive
                    fullWidth
                    value={activeProductFilter}
                    onChange={handleActiveProductFilter}
                >
                    <ToggleButton className={styles.toggleButton} value="COMMODITIES">
                        Commodity
                    </ToggleButton>
                    <ToggleButton className={styles.toggleButton} value="SPECIES">
                        Species
                    </ToggleButton>
                    <ToggleButton className={styles.toggleButton} value="SPECIES_GROUP">
                        Species Group
                    </ToggleButton>
                    <ToggleButton className={styles.toggleButton} value="PROTECTION_LEVELS">
                        Prot. Level
                    </ToggleButton>
                </ToggleButtonGroup>
                {activeProductFilter === 'COMMODITIES' && <CommoditySelect user={user} control={control} />}
                {activeProductFilter === 'SPECIES' && (
                    <Controller
                        name="filters.species"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                label={
                                    <Typography variant="overline" color="#9e9e9e">
                                        Species
                                    </Typography>
                                }
                                className={styles.autocomplete}
                                placeholder="All"
                                autocompleteProps={{
                                    isOptionEqualToValue: defaultIsOptionEqualToValue,
                                    value: field.value,
                                    multiple: true,
                                    options: speciesOptions,
                                    getOptionLabel: (option: Species) => option?.commonName ?? '',
                                    getOptionKey: (option: Species) => option.id,
                                    groupBy: (option: Species) => option.speciesGroup?.name ?? 'Other',
                                }}
                                {...field}
                                onChange={(value) => {
                                    field.onChange({
                                        target: { value: value.map(({ id, commonName }) => ({ id, commonName })) },
                                    });
                                }}
                            />
                        )}
                    />
                )}
                {activeProductFilter === 'SPECIES_GROUP' && (
                    <Controller
                        name="filters.speciesGroup"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                name={field.name}
                                onBlur={field.onBlur}
                                label={
                                    <Typography variant="overline" color="#9e9e9e">
                                        Species Group
                                    </Typography>
                                }
                                className={styles.autocomplete}
                                placeholder="All"
                                autocompleteProps={{
                                    isOptionEqualToValue: (optionA, optionB) => optionA === optionB,
                                    value: field.value,
                                    multiple: true,
                                    options: lookups?.speciesGroups.map(({ id, name }) => ({ id, name })) || [],
                                    getOptionLabel: (option: SpeciesGroupType) => option.name,
                                    getOptionKey: (option: SpeciesGroupType) => option.id,
                                }}
                                value={field.value}
                                onChange={(speciesGroup: SpeciesGroupType) => {
                                    field.onChange({ target: { value: speciesGroup } });
                                }}
                            />
                        )}
                    />
                )}
                {activeProductFilter === 'PROTECTION_LEVELS' && (
                    <Controller
                        name="filters.protectionLevels"
                        control={control}
                        render={({ field }) => (
                            <Autocomplete
                                name={field.name}
                                onBlur={field.onBlur}
                                label={
                                    <Typography variant="overline" color="#9e9e9e">
                                        Protection Level
                                    </Typography>
                                }
                                className={styles.autocomplete}
                                placeholder="All"
                                autocompleteProps={{
                                    isOptionEqualToValue: (optionA, optionB) => optionA === optionB,
                                    value: field.value,
                                    multiple: true,
                                    options: lookups?.protectionLevels.map(({ id }) => id) || [],
                                    getOptionLabel: (option: string) =>
                                        startCase(
                                            lookups?.protectionLevels
                                                .find(({ id }) => id === option)
                                                ?.label.toLowerCase() || ''
                                        ),
                                    getOptionKey: (option) => option,
                                }}
                                value={field.value}
                                onChange={(protectionLevels) => {
                                    field.onChange({ target: { value: protectionLevels } });
                                }}
                            />
                        )}
                    />
                )}
            </FormControl>
            {visualization === 'CHART' && (
                <FormControl>
                    <Typography variant="overline" color="#9e9e9e">
                        Location
                    </Typography>
                    <ToggleButtonGroup
                        color="primary"
                        size="small"
                        exclusive
                        fullWidth
                        value={activeLocationFilter}
                        onChange={handleActiveLocationFilter}
                    >
                        <ToggleButton className={styles.toggleButton} value="COURTS">
                            Court
                        </ToggleButton>
                        <ToggleButton className={styles.toggleButton} value="ADMINISTRATIVE_LEVEL_1">
                            {renderValue('ADMINISTRATIVE_LEVEL_1')}
                        </ToggleButton>
                        {(lookups?.regions.values ?? []).length > 0 && (
                            <ToggleButton className={styles.toggleButton} value="REGIONS">
                                Region
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                    {activeLocationFilter === 'COURTS' && (
                        <Controller
                            name="filters.courts"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    label={
                                        <Typography variant="overline" color="#9e9e9e">
                                            Courts
                                        </Typography>
                                    }
                                    className={styles.autocomplete}
                                    placeholder="All"
                                    autocompleteProps={{
                                        isOptionEqualToValue: defaultIsOptionEqualToValue,
                                        getOptionKey: (option: Court) => option.id,
                                        value: field.value,
                                        multiple: true,
                                        options: lookups?.courts.map(({ id, name }) => ({ id, name })) || [],
                                        getOptionLabel: (option: Court) => option.name || '',
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    )}
                    {activeLocationFilter === 'ADMINISTRATIVE_LEVEL_1' && (
                        <Controller
                            name="filters.administrativeLevel1"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    label={
                                        <Typography variant="overline" color="#9e9e9e">
                                            {lookups?.administrativeLevel1s.localName || ''}
                                        </Typography>
                                    }
                                    className={styles.autocomplete}
                                    placeholder="All"
                                    autocompleteProps={{
                                        isOptionEqualToValue: defaultIsOptionEqualToValue,
                                        getOptionKey: (option: Region) => option.id,
                                        value: field.value,
                                        multiple: true,
                                        options:
                                            lookups?.administrativeLevel1s.values.map(({ id, name }) => ({
                                                id,
                                                name,
                                            })) || [],
                                        getOptionLabel: (option: Region) => option.name || '',
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    )}
                    {activeLocationFilter === 'REGIONS' && (
                        <Controller
                            name="filters.regions"
                            control={control}
                            render={({ field }) => (
                                <Autocomplete
                                    label={
                                        <Typography variant="overline" color="#9e9e9e">
                                            {lookups?.regions.localName || 'Region'}
                                        </Typography>
                                    }
                                    className={styles.autocomplete}
                                    placeholder="All"
                                    autocompleteProps={{
                                        isOptionEqualToValue: defaultIsOptionEqualToValue,
                                        getOptionKey: (option: Region) => option.id,
                                        value: field.value,
                                        multiple: true,
                                        options: lookups?.regions.values.map(({ id, name }) => ({ id, name })) || [],
                                        getOptionLabel: (option: Region) => option.name || '',
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    )}
                </FormControl>
            )}
        </>
    );
}
