import { useEffect } from 'react';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useFormContext } from '../../context/FormsContext';
import { FormItem } from '../../components/FormItem';
import { EMPTY_PLACEHOLDER } from '../../constants';
import styles from './Hearings.module.css';

export const courtClerkSchema = z.object({
    firstName: z.string().optional(),
    lastName: z.string().optional(),
});

export type CourtClerkType = z.infer<typeof courtClerkSchema>;

interface CourtClerkProps {
    initialValues?: CourtClerkType;
    onCourtClerk: (value?: CourtClerkType) => void;
    editMode: boolean;
}

export const CourtClerk = ({ initialValues, onCourtClerk, editMode }: CourtClerkProps) => {
    const { onSubmitListener } = useFormContext();
    const {
        control,
        formState: { errors },
        watch,
        handleSubmit,
    } = useForm<CourtClerkType>({
        resolver: zodResolver(courtClerkSchema),
        defaultValues: initialValues,
    });

    useEffect(() => {
        return onSubmitListener('hearings', () => {
            return new Promise((resolve) => {
                const validateFunc = () => {
                    resolve({ type: 'validate', result: true });
                };
                handleSubmit(validateFunc, validateFunc)();
            });
        });
    }, []);

    useEffect(() => {
        const { unsubscribe } = watch((data) => {
            const result = courtClerkSchema.safeParse(data);

            result.success ? onCourtClerk(result.data) : onCourtClerk();
        });

        return unsubscribe;
    }, []);

    return (
        <>
            <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                    <FormItem
                        errorMessage={errors.firstName?.message}
                        className={styles.row}
                        editMode={editMode}
                        label="Court Clerk First Name"
                        value={field.value}
                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                        onChange={(value) => {
                            field.onChange({
                                target: { value },
                            });
                        }}
                    />
                )}
            />
            <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                    <FormItem
                        errorMessage={errors.lastName?.message}
                        className={styles.row}
                        editMode={editMode}
                        label="Court Clerk Last Name"
                        value={field.value}
                        renderValue={(value) => value || EMPTY_PLACEHOLDER}
                        onChange={(value) => {
                            field.onChange({
                                target: { value },
                            });
                        }}
                    />
                )}
            />
        </>
    );
};
