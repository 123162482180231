import { gql } from '@apollo/client';

const UPDATE_PERSON = gql`
    mutation updatePerson($input: UpdatePersonInputType!) {
        updatePerson(input: $input) {
            person {
                id
                firstName
                lastName
                birthdate
                sex
            }
        }
    }
`;

export default UPDATE_PERSON;
