import { useMemo, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { Avatar, Button } from '@c4ads/c4blocks';
import { stringAvatar } from '../../../../../helpers/avatarHelpers';
import { CaseFormComment, CaseFormCommentAuthor } from '../../../../../types/CaseFormComments';
import styles from './Comments.module.css';

interface CommentProps {
    value: CaseFormComment;
}

interface AuthorProps {
    author: CaseFormCommentAuthor;
    commentId: string;
}

const Author = ({ author, commentId }: AuthorProps) => {
    const name = [author.firstName, author.lastName].filter(Boolean).join(' ');

    return (
        <Box display="flex" flexDirection="column" className={styles.avatarContainer}>
            <Tooltip key={author.email} title={name}>
                <Avatar className={styles.avatar} {...stringAvatar(name, { width: 24, height: 24 })}>
                    {name.trim().slice(0, 1)}
                </Avatar>
            </Tooltip>
            <Typography className={styles.commentId}>#{commentId} - Court Case Form</Typography>
            <Typography title={name} className={styles.authorName}>
                {name}
            </Typography>
        </Box>
    );
};

export const Comment = ({ value }: CommentProps) => {
    const maxLength = 68;
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const message = useMemo(() => {
        if (!showMore && value.text.length > maxLength) {
            return `${value.text.slice(0, maxLength)}...`;
        }

        return value.text;
    }, [showMore, value.text]);

    return (
        <Box display="flex" flexDirection="column" className={styles.commentObj}>
            <Author author={value.user} commentId={value.id} />
            <Typography className={styles.message}>{message}</Typography>
            {value.text.length > maxLength && (
                <Button onClick={toggleShowMore} className={styles.showMoreButton} variant="text">
                    {showMore ? 'Show less' : 'Show more'}
                </Button>
            )}
        </Box>
    );
};
