import { gql } from '@apollo/client';

export const GET_CASE_FORMS = gql`
    query caseForms {
        caseForms {
            id
            status
            title
            version
            createdAt
            createdBy {
                email
                firstName
                lastName
            }
            lastEditedAt
            lastEditedBy {
                email
                firstName
                lastName
            }
            lastReviewedAt
            lastReviewedBy {
                email
                firstName
                lastName
            }
        }
    }
`;
