import { ReactNode } from 'react';
import { Avatar, AvatarGroup } from '@c4ads/c4blocks';
import { Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { tableHelpers } from '../../../../helpers/tableHelpers';

const renderOngoing = (isOngoing: boolean | undefined | null): ReactNode => {
    if (isOngoing === null || isOngoing === undefined) {
        /*
            this code return the <DoNotDisturbIcon />
        */
        return tableHelpers.renderBoolean(isOngoing);
    }

    return tableHelpers.renderBoolean(!isOngoing, isOngoing ? 'Ongoing' : undefined);
};

const CommodityCell = ({ params }: { params: GridRenderCellParams }) => (
    <AvatarGroup max={4}>
        {params.row.commodities.map(
            ({ id, species, unit }: { id: string; species: { commonName: string }; unit: string }) => (
                <Tooltip key={id} title={species.commonName + ' ' + unit}>
                    <Avatar>{species.commonName.trim().slice(0, 1)}</Avatar>
                </Tooltip>
            )
        )}
    </AvatarGroup>
);

const columns: GridColDef[] = [
    {
        field: 'docket',
        headerName: 'Docket',
        headerAlign: 'center',
        align: 'center',
        width: 180,
        groupable: false,
    },
    {
        field: 'administrativeLevel1',
        headerName: 'District',
        headerAlign: 'center',
        width: 180,
        groupable: false,
        valueGetter: ({ row }) => row.administrativeLevel1?.name,
    },
    {
        field: 'court',
        headerName: 'Court',
        headerAlign: 'center',
        width: 180,
        groupable: false,
        valueGetter: ({ row }) => row.court?.name,
    },
    {
        field: 'name',
        headerName: 'Name',
        headerAlign: 'center',
        width: 180,
        groupable: false,
    },
    {
        field: 'firstEvent',
        type: 'date',
        headerName: 'First Event',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }) => (row.firstEvent ? `${row.firstEvent.date} (${row.firstEvent.stage})` : '--'),
    },
    {
        field: 'latestEvent',
        type: 'date',
        headerName: 'Latest Event',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        valueGetter: ({ row }) => (row.latestEvent ? `${row.latestEvent.date} (${row.latestEvent.stage})` : '--'),
    },
    {
        field: 'isConcluded',
        type: 'boolean',
        headerName: 'Ongoing',
        headerAlign: 'center',
        align: 'center',
        width: 180,
        groupable: false,
        renderCell: ({ value }) => renderOngoing(value),
    },
    {
        field: 'caseDurationDays',
        type: 'number',
        headerName: 'Duration (days)',
        headerAlign: 'center',
        width: 160,
        groupable: false,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'commodities',
        headerName: 'Commodities',
        headerAlign: 'center',
        width: 200,
        groupable: false,
        align: 'center',
        valueGetter: ({ row }) =>
            row.commodities.map(({ species, unit }) => species.commonName + ' ' + unit).join(' / '),
        renderCell: (params: GridRenderCellParams) => <CommodityCell params={params} />,
    },
    {
        field: 'defenseAttorneyCount',
        type: 'number',
        headerName: 'Defense Attorney Count',
        headerAlign: 'center',
        align: 'center',
        width: 180,
        groupable: false,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
];

export default columns;
