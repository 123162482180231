import { AnimatedBarSeries } from '@visx/xychart';
import { MarkProps } from '../types';

export default function Bar({ data, colorAccessor }: MarkProps) {
    return (
        <>
            {data.map(({ key, label, data }: { key: string; label: string; data: any[] }) => (
                <AnimatedBarSeries
                    key={key}
                    data={data}
                    dataKey={label}
                    xAccessor={(d: any) => d.label}
                    yAccessor={(d: any) => d.data}
                    colorAccessor={(d: any) => colorAccessor(label)}
                />
            ))}
        </>
    );
}
