import { Box, Grid, ListItemText, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { listItemTextProps } from '../../index';
import { ApprehensionTeam, SeizedCommodity, Seizure } from '../../../../types';
import styles from '../../CourtCaseDialog.module.css';
import { startCase } from 'lodash';

interface SeizureItemProps {
    item: Seizure;
}

export const SeizureItem = ({ item }: SeizureItemProps) => {
    const { date, apprehensionTeams, seizedCommodities, seizedAccessories, locationName } = item;

    return (
        <Box className={styles.wrapper}>
            <Box className={styles.item}>
                <Grid container className={styles.itemTitleBlock}>
                    <Grid item xs={2}>
                        <ListItemText
                            className={styles.subtitle}
                            primary={`Date: ${date ? DateTime.fromISO(date).toISODate() : '--'}`}
                            {...listItemTextProps}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <ListItemText
                            className={styles.subtitle}
                            primary={`Location: ${locationName}`}
                            {...listItemTextProps}
                        />
                    </Grid>
                </Grid>
                <Box className={styles.tableHeaderContainer}>
                    <Grid container>
                        <Grid item xs={2}>
                            <Typography className={styles.tableText}>Protection Level</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={styles.tableText}>Commodity</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={styles.tableText}>Count</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography className={styles.tableText}>Weight</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={styles.tableText}>Value</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={styles.tableText}>Origin</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={styles.tableText}>Destination</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={styles.itemElements}>
                    {seizedCommodities.map(
                        ({
                            id,
                            protectionLevel,
                            species,
                            unit,
                            count,
                            weightGrams,
                            value,
                            valueCurrency,
                            originName,
                        }: SeizedCommodity) => (
                            <Box className={styles.gridRow} key={id}>
                                <Grid className={styles.gridRowInner} container>
                                    <Grid item xs={2}>
                                        <Typography className={cn(styles.tableText, styles.tableTextValue)}>
                                            {protectionLevel ? startCase(protectionLevel.label) : '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={cn(styles.tableText, styles.tableTextValue)}>
                                            {species.commonName + unit}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography className={cn(styles.tableText, styles.tableTextValue)}>
                                            {count}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography className={cn(styles.tableText, styles.tableTextValue)}>
                                            {weightGrams ? `${weightGrams}g` : '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={cn(styles.tableText, styles.tableTextValue)}>
                                            {value ? `${value}${valueCurrency}` : '--'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={cn(styles.tableText, styles.tableTextValue)}>
                                            {originName || '--'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    )}
                </Box>
            </Box>
            <Box className={styles.itemFooter}>
                <Box className={styles.itemAdditionalInfo}>
                    <ListItemText
                        className={cn(styles.subtitle, styles.itemAdditionalInfoTitle)}
                        primary="Apprehension Team:"
                        {...listItemTextProps}
                    />
                    <Typography className={cn(styles.tableTextValue, styles.additionalText)}>
                        {apprehensionTeams !== undefined && apprehensionTeams.length > 0
                            ? apprehensionTeams
                                  .filter((item) => !!item)
                                  .map(({ name }: ApprehensionTeam) => name)
                                  .join(', ')
                            : '--'}
                    </Typography>
                </Box>
                <Box className={styles.itemAdditionalInfo}>
                    <ListItemText
                        className={cn(styles.subtitle, styles.itemAdditionalInfoTitle)}
                        primary="Seized Instrumentalities:"
                        {...listItemTextProps}
                    />
                    <Typography className={cn(styles.tableTextValue, styles.additionalText)}>
                        {seizedAccessories !== undefined && seizedAccessories.length > 0
                            ? seizedAccessories
                                  .filter((item) => !!item)
                                  .map(({ accessory, count }) =>
                                      `${accessory?.name} ${count && count > 0 ? '(' + count + ')' : ''}`.trim()
                                  )
                                  .join(', ')
                            : '--'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
