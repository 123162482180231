import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { SeizedCommodityComponent, seizedCommoditySchema, SeizedCommodityType } from './SeizedCommodity';
import { AddButton } from '../../../components/AddButton';
import { List } from '../../../components/ListComponents';
import styles from './SeizedCommodities.module.css';

interface SeizedCommoditiesProps {
    defaultValues?: Array<SeizedCommodityType>;
    editMode?: boolean;
    onChange: (value: Array<SeizedCommodityType | undefined>) => void;
}

interface SeizedCommodityItem {
    key: string;
    value?: SeizedCommodityType;
}

const prepareCommodities = (commodities?: SeizedCommodityType[]) => {
    return commodities
        ? commodities.map((item) => {
              return item ? { key: item.id || uuidv4(), value: item } : { key: uuidv4() };
          })
        : [];
};

export const SeizedCommodities = ({ defaultValues, editMode, onChange: propOnChange }: SeizedCommoditiesProps) => {
    const [seizedCommodityItems, setSeizedCommodityItems] = useState<SeizedCommodityItem[]>(
        prepareCommodities(defaultValues)
    );

    useEffect(() => {
        propOnChange(seizedCommodityItems?.map((item) => item.value) || []);
    }, [seizedCommodityItems]);

    const addNew = () => {
        setSeizedCommodityItems((prev) => prev.concat([{ key: uuidv4() }]));
    };

    const onDelete = useCallback((key: string) => {
        return () => {
            setSeizedCommodityItems((prev) => prev.filter((item) => item.key !== key));
        };
    }, []);

    const onChange = useCallback((key: string) => {
        return (value?: Partial<z.infer<typeof seizedCommoditySchema>>) => {
            setSeizedCommodityItems((prev) => {
                const cloned = prev.slice();
                const index = cloned.findIndex((item) => item.key === key);

                if (index !== -1) {
                    // @ts-ignore
                    cloned[index].value = value;
                }
                return cloned;
            });
        };
    }, []);

    return (
        <Box display="flex" flexDirection="column" className={styles.commodities}>
            <Box display="flex" justifyContent="space-between" className={styles.commoditiesHeader}>
                <Typography className={styles.subsectionTitle}>Seized Commodities</Typography>
                {editMode && <AddButton className={styles.addButton} title="+ Add new" onClick={addNew} />}
            </Box>
            <List>
                {seizedCommodityItems.map((item) => (
                    <SeizedCommodityComponent
                        key={item.key}
                        defaultValues={item.value}
                        onDelete={onDelete(item.key)}
                        editMode={editMode}
                        onChange={onChange(item.key)}
                    />
                ))}
            </List>
        </Box>
    );
};
