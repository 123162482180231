import { gql } from '@apollo/client';


export const CREATE_CASE_FORM_COMMENT = gql`
    mutation createCaseFormComment($input: CaseFormCommentCreationType!) {
        createCaseFormComment(input: $input) {
            caseFormComment {
                id
                text
                timestamp
                user {
                    id
                    email
                    firstName
                    lastName
                }
            }
        }
    }
`;
