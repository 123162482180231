import React from 'react';
import { TextField } from '../TextField';
import styles from './NotesField.module.css';

interface NotesFieldProps {
    errorMessage?: string;
    value?: string;
    placeholder?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const NotesField = ({ value, placeholder, errorMessage, onChange }: NotesFieldProps) => {
    return (
        <TextField
            className={styles.notes}
            fullWidth
            errorMessage={errorMessage}
            value={value}
            multiline
            maxRows={4}
            minRows={2}
            placeholder={placeholder ?? 'Add notes here...'}
            onChange={onChange}
        />
    );
};
