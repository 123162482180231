import { ReactNode } from 'react';
import { Box } from '@mui/material';
import styles from './FormColWrapper.module.css';

interface FormColWrapperProps {
    children?: ReactNode | ReactNode[];
    wrap?: boolean;
}

export const FormColWrapper = ({ children, wrap }: FormColWrapperProps) => {
    return (
        <Box className={styles.col} display="flex" flexDirection="column" flexWrap={wrap ? 'wrap' : 'nowrap'}>
            {children}
        </Box>
    );
};
