import { ReactNode, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Box, Typography, BoxProps } from '@mui/material';
import styles from './NavigationSidebar.module.css';

interface NavigationSidebarLink<T> {
    key: T;
    href?: string;
    label: ReactNode;
}

interface LinkBoxProps extends BoxProps {
    to?: string;
}

interface NavigationSidebarProps<T> {
    links: NavigationSidebarLink<T>[];
    activeLink?: T;
    onActiveLink?: (activeLink?: T) => void;
    header?: ReactNode;
    footer?: ReactNode;
}

export const NavigationSidebar = <T,>({
    links,
    activeLink,
    onActiveLink,
    footer,
    header,
}: NavigationSidebarProps<T>) => {
    const [currentActiveLink, setCurrentActiveLink] = useState<T | undefined>(activeLink);

    useEffect(() => {
        setCurrentActiveLink(activeLink);
    }, [activeLink]);

    const onChange = (key: T) => {
        return () => {
            setCurrentActiveLink(key);
            onActiveLink && onActiveLink(key);
        };
    };

    return (
        <Box display="flex" flexDirection="column">
            {header}
            <Box display="flex" flexDirection="column" className={styles.linksContainer}>
                {links.map((link) => {
                    const LinkWrapper = link.href ? Link : LinkBox;

                    return (
                        <LinkWrapper
                            to={link.href ?? ''}
                            className={cn(styles.link, currentActiveLink === link.key && styles.activeLink)}
                            onClick={onChange(link.key)}
                            key={link.key as string}
                        >
                            {typeof link.label === 'string' ? (
                                <Typography className={styles.linkText}>{link.label}</Typography>
                            ) : (
                                link.label
                            )}
                        </LinkWrapper>
                    );
                })}
            </Box>

            {footer}
        </Box>
    );
};

const LinkBox = ({ to, ...props }: LinkBoxProps) => {
    return <Box {...props} />;
};
