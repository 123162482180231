import React, { useCallback, useState } from 'react';
import { Box, CircularProgress, TextField } from '@mui/material';
import { Button } from '@c4ads/c4blocks';
import { useMutation } from '@apollo/client';
import { CREATE_CASE_FORM_COMMENT } from '../../../../../api/apollo/mutation/CreateCaseFormComment';
import { Comment } from './Comment';
import { FormSeparator } from '../components/FormSeparator';
import { CaseFormComment } from '../../../../../types/CaseFormComments';
import styles from './Comments.module.css';
import { useAuth } from '../../../../../api/auth';

interface CommentsProps {
    id: string;
    comments: CaseFormComment[];
}

const prepareComments = (propComments: CaseFormComment[]) => {
    return propComments.sort((a, b) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });
};

export const Comments = ({ id, comments: propComments }: CommentsProps) => {
    const { user } = useAuth();
    const [comments, setComments] = useState<CaseFormComment[]>(prepareComments(propComments));
    const [commentText, setCommentText] = useState<string>();
    const [createCaseFormComment, { loading }] = useMutation<{
        createCaseFormComment: {
            caseFormComment: CaseFormComment;
        };
    }>(CREATE_CASE_FORM_COMMENT);

    const onChangeComment = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCommentText(e.target.value);
    }, []);

    const addComment = useCallback(async () => {
        const response = await createCaseFormComment({
            variables: {
                input: {
                    caseFormId: id,
                    text: commentText,
                },
            },
        });
        setCommentText('');

        const newComment = response.data?.createCaseFormComment?.caseFormComment;

        if (newComment) {
            setComments((prev) => {
                return [newComment].concat(prev);
            });
        }
    }, [createCaseFormComment, id, commentText]);

    return user?.permissions?.map(({ codename }) => codename).includes('case_form_editor') ? (
        <Box display="flex" flexDirection="column" className={styles.container}>
            <Box display="flex" flexDirection="column" className={styles.textFieldContainer}>
                <TextField
                    className={styles.textField}
                    onChange={onChangeComment}
                    value={commentText}
                    label={null}
                    multiline
                    rows={6}
                    placeholder="Write your comment..."
                />
                <Button
                    startIcon={loading ? <CircularProgress size={16} /> : null}
                    disabled={loading}
                    className={styles.addCommentButton}
                    color="primary"
                    variant="contained"
                    onClick={addComment}
                >
                    Add Comment
                </Button>
            </Box>
            <Box>
                {comments.length > 0 && <FormSeparator />}
                <Box display="flex" flexDirection="column" className={styles.commentsBlock}>
                    {comments.map((comment, index) => (
                        <React.Fragment key={comment.id}>
                            <Comment value={comment} />
                            {index !== comments.length - 1 && <FormSeparator />}
                        </React.Fragment>
                    ))}
                </Box>
            </Box>
        </Box>
    ) : null;
};
