import { useMemo, useState } from 'react';
import { Text } from '@visx/text';
import { PatternLines } from '@visx/pattern';
import { Group } from '@visx/group';
import { ViolinPlot, BoxPlot } from '@visx/stats';
import { scaleBand, scaleLinear } from '@visx/scale';
import { withTooltip, Tooltip, defaultStyles as defaultTooltipStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { ChartWrapper } from '../ChartWrapper';
import { AxesProps, MultiSeries, Palette } from '../types';
import { CHART_CONTENT_CLASSNAME } from '../constants';
import { lowerCase } from 'lodash';
import { DateTime } from 'luxon';

interface Datum {
    value: number;
    count: number;
}

type PlotData = {
    x: string;
    min?: number;
    max?: number;
    median?: number;
    firstQuartile?: number;
    thirdQuartile?: number;
    outliers?: number[];
    binData: Datum[];
};

interface TooltipData {
    name?: string;
    min?: number;
    median?: number;
    max?: number;
    firstQuartile?: number;
    thirdQuartile?: number;
}

export type ViolinProps = {
    id: string;
    width: number;
    height: number;
    data: MultiSeries[];
    title?: string;
    description?: string;
    palette?: Palette;
    axes?: AxesProps;
};

// accessors
const x = (d: PlotData) => d.x;
const min = (d: PlotData) => d.min;
const max = (d: PlotData) => d.max;
const median = (d: PlotData) => d.median;
const firstQuartile = (d: PlotData) => d.firstQuartile;
const thirdQuartile = (d: PlotData) => d.thirdQuartile;
const outliers = (d: PlotData) => d.outliers;

const MARGIN_TOP = 24;
const MARGIN_BOTTOM = 42;
const MIN_WIDTH = 40;

const getMedian = (arr: number[]) => {
    if (!arr.length) {
        return;
    }
    const halfLength = arr.length / 2;

    let median = arr[0];
    if (arr.length >= 2) {
        median =
            halfLength > Math.floor(halfLength)
                ? arr[Math.floor(halfLength)]
                : (arr[halfLength - 1] + arr[halfLength]) / 2;
    }

    return median;
};

const Violin = withTooltip<ViolinProps, TooltipData>(
    ({
        title,
        description,
        width,
        height,
        tooltipOpen,
        tooltipLeft,
        tooltipTop,
        tooltipData,
        showTooltip,
        hideTooltip,
        axes,
        data,
    }: ViolinProps & WithTooltipProvidedProps<TooltipData>) => {
        const [currentChartHeight, setCurrentChartHeight] = useState(0);

        // bounds
        const xMax = width;
        const yMax = currentChartHeight - MARGIN_TOP * 2;

        const dateFormat = useMemo(() => {
            const interval = axes?.bottom?.interval || '';
            if (typeof interval === 'string') {
                switch (lowerCase(interval)) {
                    case 'month':
                        return 'LLL';
                    case 'quarter':
                        return 'q y';
                    case 'year':
                        return 'y';
                    default:
                        return 'D';
                }
            }
            return 'D';
        }, [axes?.bottom]);

        const formatLabel = (d: number | string, dateFormat: string, axes?: AxesProps) => {
            const formatDateFromMillis = (d: number, format: string) => {
                const prefix = axes?.bottom?.interval === 'quarter' ? 'Q' : '';
                return prefix + DateTime.fromMillis(d).toFormat(format);
            };

            if (axes?.bottom?.isDate && typeof d === 'number') {
                return formatDateFromMillis(d, dateFormat);
            }

            if (typeof d === 'string') {
                if (axes?.bottom?.isDate) {
                    const prefix = axes?.bottom?.interval === 'quarter' ? 'Q' : '';
                    return prefix + DateTime.fromISO(d).toFormat(dateFormat);
                }
                return d;
            }
        };

        const plotsData = useMemo<PlotData[]>(() => {
            return data
                .map((multiSeries) => {
                    const sortedSeries = multiSeries.data
                        .map((item) => item.data)
                        .sort((a, b) => {
                            return a - b;
                        });
                    if (!sortedSeries.length) {
                        return [];
                    }

                    const label = formatLabel(multiSeries.label || '', dateFormat, axes);

                    const halfLength = sortedSeries.length / 2;

                    const min = sortedSeries[0];
                    const max = sortedSeries[sortedSeries.length - 1];

                    const median = getMedian(sortedSeries);
                    const firstQuartile = getMedian(sortedSeries.slice(0, halfLength));
                    const thirdQuartile = getMedian(sortedSeries.slice(halfLength));

                    let outliers: number[] | undefined;
                    if (firstQuartile !== undefined && thirdQuartile !== undefined) {
                        const interquartileRange = thirdQuartile - firstQuartile;
                        outliers = [firstQuartile - 1.5 * interquartileRange, thirdQuartile + 1.5 * interquartileRange];
                    }

                    const binData: Record<number, { value: number; count: number }> = {};
                    multiSeries.data.reduce((acc, value) => {
                        if (!acc[value.data]) {
                            acc[value.data] = { value: value.data, count: 0 };
                        }

                        acc[value.data].count += 1;

                        return acc;
                    }, binData);

                    return {
                        x: label,
                        min,
                        max,
                        median,
                        firstQuartile,
                        thirdQuartile,
                        outliers,
                        binData: Object.values(binData),
                    };
                })
                .filter(Boolean) as PlotData[];
        }, [data, axes?.bottom, dateFormat]);

        // scales
        const xScale = useMemo(() => {
            return scaleBand<string>({
                range: [0, xMax],
                round: true,
                domain: plotsData.map(x),
                padding: 0.4,
            });
        }, [plotsData, xMax]);

        const boxValues = useMemo(() => {
            const values = plotsData.reduce((allValues, boxPlot) => {
                boxPlot.min !== undefined && allValues.push(boxPlot.min);
                boxPlot.max !== undefined && allValues.push(boxPlot.max);
                boxPlot.outliers !== undefined && allValues.push(...boxPlot.outliers);

                return allValues;
            }, [] as number[]);

            const minYValue = Math.min(...values);
            const maxYValue = Math.max(...values);

            return { values, maxYValue, minYValue };
        }, [plotsData]);

        const yScale = useMemo(() => {
            return scaleLinear<number>({
                range: [yMax - MARGIN_BOTTOM, 0],
                round: true,
                domain: [boxValues.minYValue, boxValues.maxYValue],
            });
        }, [boxValues, yMax]);

        const boxWidth = xScale.bandwidth();
        const constrainedWidth = Math.min(MIN_WIDTH, boxWidth);

        const boxPlotTooltipController = (
            tooltipData: TooltipData,
            tooltipPosGetter: () => { top?: number; left?: string }
        ) => {
            const { top, left } = tooltipPosGetter();

            const tooltipTop = top !== undefined ? yScale(top) : MARGIN_TOP;
            const tooltipLeft = left !== undefined ? xScale(left)! : 0;

            return {
                onMouseOver: () => {
                    showTooltip({
                        tooltipTop,
                        tooltipLeft: tooltipLeft + constrainedWidth + 5,
                        tooltipData,
                    });
                },
                onMouseLeave: () => {
                    hideTooltip();
                },
            };
        };

        if (width < 10) {
            return null;
        }

        return (
            <div style={{ position: 'relative' }}>
                <ChartWrapper width={width} height={height} chartLabel={{ title, description }}>
                    {({ chartTop, chartHeight }) => {
                        setCurrentChartHeight(chartHeight);

                        return (
                            <foreignObject y={chartTop} x={0} width={width} height={chartHeight}>
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '100%',
                                    }}
                                >
                                    <svg className={CHART_CONTENT_CLASSNAME} width={width} height={chartHeight}>
                                        <PatternLines
                                            id="hViolinLines"
                                            height={3}
                                            width={3}
                                            stroke="#122945"
                                            strokeWidth={1}
                                            orientation={['horizontal']}
                                        />
                                        <Group top={MARGIN_TOP}>
                                            {plotsData.map((dataItem, i) => (
                                                <g key={i}>
                                                    <ViolinPlot
                                                        data={dataItem.binData}
                                                        stroke="#122945"
                                                        left={xScale(x(dataItem))!}
                                                        width={constrainedWidth}
                                                        valueScale={yScale}
                                                        fill="url(#hViolinLines)"
                                                    />
                                                    <BoxPlot
                                                        min={min(dataItem)}
                                                        max={max(dataItem)}
                                                        left={xScale(x(dataItem))! + 0.3 * constrainedWidth}
                                                        firstQuartile={firstQuartile(dataItem)}
                                                        thirdQuartile={thirdQuartile(dataItem)}
                                                        median={median(dataItem)}
                                                        boxWidth={constrainedWidth * 0.4}
                                                        fill="#122945"
                                                        fillOpacity={0.3}
                                                        stroke="#122945"
                                                        strokeWidth={2}
                                                        valueScale={yScale}
                                                        outliers={outliers(dataItem)}
                                                        minProps={boxPlotTooltipController(
                                                            {
                                                                min: min(dataItem),
                                                                name: x(dataItem),
                                                            },
                                                            () => ({
                                                                top: min(dataItem),
                                                                left: x(dataItem),
                                                            })
                                                        )}
                                                        maxProps={boxPlotTooltipController(
                                                            {
                                                                max: max(dataItem),
                                                                name: x(dataItem),
                                                            },
                                                            () => ({
                                                                top: max(dataItem),
                                                                left: x(dataItem),
                                                            })
                                                        )}
                                                        boxProps={boxPlotTooltipController(
                                                            {
                                                                ...dataItem,
                                                                name: x(dataItem),
                                                            },
                                                            () => ({
                                                                top: median(dataItem),
                                                                left: x(dataItem),
                                                            })
                                                        )}
                                                        medianProps={{
                                                            style: {
                                                                stroke: '#122945',
                                                            },
                                                            ...boxPlotTooltipController(
                                                                {
                                                                    median: median(dataItem),
                                                                    name: x(dataItem),
                                                                },
                                                                () => ({
                                                                    top: median(dataItem),
                                                                    left: x(dataItem),
                                                                })
                                                            ),
                                                        }}
                                                    />
                                                    <Text
                                                        x={xScale(x(dataItem))! + 0.5 * constrainedWidth}
                                                        textAnchor="middle"
                                                        y={chartHeight - MARGIN_BOTTOM}
                                                        fill="#122945"
                                                        fontSize={16}
                                                    >
                                                        {dataItem.x}
                                                    </Text>
                                                </g>
                                            ))}
                                        </Group>
                                    </svg>
                                    {tooltipOpen && tooltipData && (
                                        <Tooltip
                                            top={tooltipTop}
                                            left={tooltipLeft}
                                            style={{
                                                ...defaultTooltipStyles,
                                                backgroundColor: '#283238',
                                                color: 'white',
                                            }}
                                        >
                                            <div>
                                                <strong>{tooltipData.name}</strong>
                                            </div>
                                            <div style={{ marginTop: '5px', fontSize: '12px' }}>
                                                {tooltipData.max !== undefined && <div>Max: {tooltipData.max}</div>}
                                                {tooltipData.thirdQuartile !== undefined && (
                                                    <div>Third quartile: {tooltipData.thirdQuartile}</div>
                                                )}
                                                {tooltipData.median !== undefined && (
                                                    <div>Median: {tooltipData.median}</div>
                                                )}
                                                {tooltipData.firstQuartile !== undefined && (
                                                    <div>First quartile: {tooltipData.firstQuartile}</div>
                                                )}
                                                {tooltipData.min !== undefined && <div>Min: {tooltipData.min}</div>}
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                            </foreignObject>
                        );
                    }}
                </ChartWrapper>
            </div>
        );
    }
);

export default Violin;
