import cn from 'classnames';
import styles from './FormSeparator.module.css';

interface FormSeparatorProps {
    bold?: boolean;
    className?: string;
}

export const FormSeparator = ({ bold, className }: FormSeparatorProps) => {
    return <div className={cn(styles.line, bold && styles.boldLine, className)} />;
};
