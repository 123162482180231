import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import cn from 'classnames';
import { truncateText } from '../../helpers';
import styles from './LongTextView.module.css';

interface LongTextViewProps {
    children: string;
    maxLength?: number;
    className?: string;
}

export const LongTextView = ({ children, className, maxLength = 512 }: LongTextViewProps) => {
    const [showMoreActive, setShowMoreActive] = useState(children.length > maxLength);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setShowMoreActive(children.length > maxLength);
    }, [maxLength, children]);

    const toggleOpen = () => {
        setOpen((prev) => !prev);
    };

    return (
        <Box>
            <Typography className={cn(styles.text, className)}>
                {open ? children : truncateText(children, maxLength)}
            </Typography>
            {showMoreActive && (
                <Typography onClick={toggleOpen} className={cn(styles.text, styles.showMore, className)}>
                    {open ? 'Show less' : 'Show more'}
                </Typography>
            )}
        </Box>
    );
};
