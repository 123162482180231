import { GridColDef } from '@mui/x-data-grid-premium';
import { Officer } from '../../../../types';
import { tableHelpers } from '../../../../helpers/tableHelpers';
import { SeizureDataGrid } from './index';

const columns: GridColDef<SeizureDataGrid>[] = [
    {
        field: 'docket',
        type: 'string',
        headerName: 'Docket',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'date',
        type: 'date',
        headerName: 'Date',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        aggregable: false,
        width: 140,
        valueFormatter: tableHelpers.dateFormatter,
    },
    {
        field: 'locationName',
        type: 'string',
        headerName: 'Location',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'administrativeLevel1',
        type: 'string',
        headerName: 'District',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
        valueGetter: ({ value }) => value?.name,
    },
    {
        field: 'arrestingOfficers',
        type: 'string',
        headerName: 'Arresting Officers',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 140,
        valueGetter: ({ value }: { value: Officer[] }) =>
            value
                ? value
                      .map((item) =>
                          //   item.rank +
                          //   ' ' +
                          (item.firstName + ' ' + item.lastName)
                              //   (item.unit ? ' (' + item.unit.name + ')' : '')
                              .trim()
                      )
                      .join(', ')
                : '',
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'seizedCommodityProtectionLevel',
        type: 'string',
        headerName: 'Protection Level',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 180,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
    {
        field: 'seizedCommodityCommodity',
        type: 'string',
        headerName: 'Commodity',
        headerAlign: 'center',
        align: 'left',
        groupable: false,
        aggregable: false,
        width: 200,
        renderCell: ({ value }) =>
            tableHelpers.renderString(value ? value.replace('Unknown ', '').replace('Other', '') : value),
    },
    {
        field: 'seizedCommodityWeightGrams',
        type: 'number',
        headerName: 'Weight (kg)',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value ? value / 1000 : value),
    },
    {
        field: 'seizedCommodityCount',
        type: 'number',
        headerName: 'Count',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderNumber(value),
    },
    {
        field: 'seizedCommodityValue',
        type: 'number',
        headerName: 'Value',
        headerAlign: 'center',
        align: 'center',
        groupable: false,
        width: 140,
        renderCell: ({ row, value }) => tableHelpers.renderCurrency(value, row.seizedCommodityValueCurrency),
    },
    {
        field: 'origin',
        type: 'string',
        headerName: 'Origin',
        headerAlign: 'center',
        align: 'left',
        aggregable: false,
        width: 140,
        renderCell: ({ value }) => tableHelpers.renderString(value),
    },
];

export default columns;
