import React, { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomTooltip } from '../../../../../../../components/CustomTooltip';
import { FormColWrapper } from '../../../components/FormColWrapper';
import { FormRow } from '../../../components/FormTemplates/FormRow';
import { FormCheckbox } from '../../../components/FormCheckbox';
import { FormItem } from '../../../components/FormItem';
import { Label } from '../../../components/FormItem/Label';
import { DropDown } from '../../../../../../../components/DropDown';
import { ErrorMessage } from '../../../../../../../components/Messages';
import { DefendantSchemaType } from '../Defendant';
import { EMPTY_PLACEHOLDER } from '../../../constants';
import styles from './Sentencing.module.css';

interface SentencingProps {
    errors: FieldErrors<DefendantSchemaType>;
    control: Control<DefendantSchemaType>;
    editMode?: boolean;
    disableSentencing: boolean;
}

const inputProps = {
    type: 'number' as 'text' | 'number',
    min: 0,
    step: 1,
};

const sentencingKeys = [
    'fine',
    'suspendedFine',
    'custodialMonths',
    'suspendedMonths',
    'communityServiceHours',
    'appealMade',
    'appealGranted',
];

const errorsContainOneOfTheKeys = (errors: any) => {
    return !!sentencingKeys.find((key) => errors[key]);
};

export const Sentencing = ({ control, errors, disableSentencing, editMode }: SentencingProps) => {
    const [showTitleErrorMessage, setShowTitleErrorMessage] = useState(false);

    useEffect(() => {
        setShowTitleErrorMessage(errorsContainOneOfTheKeys(errors));
    }, [showTitleErrorMessage, JSON.stringify(errors)]);

    return (
        <DropDown
            trigger={(onClick) => (
                <Box display="flex" alignItems="center" className={styles.trigger}>
                    <Box display="flex" alignItems="center">
                        <Label onClick={onClick} className={styles.triggerLabel} content="Sentencing" />
                        {showTitleErrorMessage && <ErrorMessage marginLeft={8} message="Required" />}
                    </Box>
                    <CustomTooltip title="Defendant needs to be convicted on at least one charge to enable sentencing fields.">
                        <InfoOutlinedIcon className={styles.infoIcon} />
                    </CustomTooltip>
                </Box>
            )}
            content={
                <FormColWrapper>
                    <FormRow>
                        <Controller
                            name="fine"
                            control={control}
                            disabled={disableSentencing}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.fine?.message}
                                    inputProps={inputProps}
                                    editMode={editMode}
                                    disabled={disableSentencing}
                                    label="Fine:"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value: Number(value) },
                                        });
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="suspendedFine"
                            control={control}
                            disabled={disableSentencing}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.suspendedFine?.message}
                                    disabled={disableSentencing}
                                    inputProps={inputProps}
                                    editMode={editMode}
                                    label="Suspended Fine:"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value: Number(value) },
                                        });
                                    }}
                                />
                            )}
                        />
                    </FormRow>
                    <FormRow>
                        <Controller
                            name="custodialMonths"
                            control={control}
                            disabled={disableSentencing}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.custodialMonths?.message}
                                    disabled={disableSentencing}
                                    inputProps={inputProps}
                                    editMode={editMode}
                                    label="Custodial Sentence (Months):"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value: Number(value) },
                                        });
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="suspendedMonths"
                            control={control}
                            disabled={disableSentencing}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.suspendedMonths?.message}
                                    inputProps={inputProps}
                                    editMode={editMode}
                                    disabled={disableSentencing}
                                    label="Suspended Sentence (Months):"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value: Number(value) },
                                        });
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="communityServiceHours"
                            control={control}
                            disabled={disableSentencing}
                            render={({ field }) => (
                                <FormItem
                                    errorMessage={errors.communityServiceHours?.message}
                                    disabled={disableSentencing}
                                    inputProps={inputProps}
                                    editMode={editMode}
                                    label="Community Service (Hours):"
                                    value={field.value}
                                    renderValue={(value) => value || EMPTY_PLACEHOLDER}
                                    onChange={(value) => {
                                        field.onChange({
                                            target: { value: Number(value) },
                                        });
                                    }}
                                />
                            )}
                        />
                    </FormRow>
                    <FormRow>
                        <Controller
                            name="appealMade"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    editMode={editMode}
                                    label="Appeal Made"
                                    value={field.value}
                                    renderValue={(value) => {
                                        return value ? 'Yes' : 'No';
                                    }}
                                    renderInput={(value) => (
                                        <FormCheckbox
                                            disabled={disableSentencing}
                                            checked={value}
                                            onChange={(value) => {
                                                field.onChange({ target: { value } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                        <Controller
                            name="appealGranted"
                            control={control}
                            render={({ field }) => (
                                <FormItem
                                    editMode={editMode}
                                    label="Appeal Granted"
                                    value={field.value}
                                    renderValue={(value) => {
                                        return value ? 'Yes' : 'No';
                                    }}
                                    renderInput={(value) => (
                                        <FormCheckbox
                                            checked={value}
                                            disabled={disableSentencing}
                                            onChange={(value) => {
                                                field.onChange({ target: { value } });
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormRow>
                </FormColWrapper>
            }
        />
    );
};
