import React, { ReactNode, useMemo } from 'react';
import cn from 'classnames';
import { TextField } from '../TextField';
import { Label } from './Label';
import { Value } from './Value';
import { FormItemWrapper } from './FormItemWrapper';
import { AbsoluteLoader } from '../../../../../../components/Loader/AbsouteLoader';
import { ERROR_CLASSNAME } from '../../constants';

interface FormItemProps<T> {
    inputProps?: {
        type?: 'text' | 'number';
        step?: number;
        min?: number;
    };
    label: ReactNode;
    value: T;
    onChange?: (value: T) => void;
    editMode?: boolean;
    renderValue?: (value: T) => ReactNode | string;
    renderInput?: (value: T) => ReactNode;
    disabled?: boolean;
    className?: string;
    isLoading?: boolean;
    errorMessage?: string;
}

export const FormItem = <T,>({
    inputProps,
    label,
    value,
    editMode,
    renderInput,
    renderValue,
    onChange,
    className,
    isLoading,
    disabled,
    errorMessage,
}: FormItemProps<T>) => {
    const inputComponent = useMemo(() => {
        if (!editMode) {
            return null;
        }

        return renderInput ? (
            renderInput(value)
        ) : (
            <TextField
                disabled={disabled}
                inputProps={inputProps}
                defaultValue={value}
                errorMessage={errorMessage}
                onChange={(e) => {
                    onChange && onChange(e.target.value as T);
                }}
            />
        );
    }, [renderInput, editMode, value, disabled, errorMessage]);

    const labelComponent = useMemo(() => {
        if (editMode) {
            return null;
        }

        const component = renderValue ? renderValue(value) : <Value content={value as string} />;

        return typeof component === 'string' ? <Value content={component} /> : component;
    }, [renderValue, editMode, value]);

    return (
        <FormItemWrapper className={cn(className, errorMessage && ERROR_CLASSNAME)}>
            {isLoading && <AbsoluteLoader size={20} />}
            {typeof label === 'string' ? <Label type={errorMessage ? 'error' : 'default'} content={label} /> : label}
            {inputComponent}
            {labelComponent}
        </FormItemWrapper>
    );
};
