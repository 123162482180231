import { CSSProperties } from 'react';
import { CHART_LABEL_CLASSNAME } from '../constants';

export interface ChartLabelProps {
    x: number;
    y: number;
    width: number;
    height: number;
    style?: CSSProperties;
    label?: string;
    description?: string;
}

const defaultStyles: CSSProperties = {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
};

const labelStyles: CSSProperties = {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#122945',
    lineHeight: '18px',
};

const descriptionStyles: CSSProperties = {
    fontSize: 11,
    fontWeight: 'light',
    color: '#757575',
    lineHeight: '14px',
};

export const ChartLabel = ({ x, y, width, height, style = {}, label, description }: ChartLabelProps) => {
    return (
        <foreignObject x={x} y={y} width={width} height={height}>
            <div className={CHART_LABEL_CLASSNAME} style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                <span
                    title={label}
                    style={{
                        ...defaultStyles,
                        ...labelStyles,
                        ...style,
                    }}
                >
                    {label}
                </span>
                <span
                    title={description}
                    style={{
                        ...defaultStyles,
                        ...descriptionStyles,
                        ...style,
                    }}
                >
                    {description}
                </span>
            </div>
        </foreignObject>
    );
};
