import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { BailEventComponent, bailEventSchema, BailEventType } from './BailEvent';
import { AddButton } from '../../../components/AddButton';
import { List } from '../../../components/ListComponents';
import { Label } from '../../../components/FormItem/Label';
import { DropDown } from '../../../../../../../components/DropDown';
import { ErrorMessage } from '../../../../../../../components/Messages';
import styles from './BailEvents.module.css';

interface BailEventProps {
    defaultValues?: Array<Partial<BailEventType>>;
    editMode?: boolean;
    onChange: (value: Array<Partial<BailEventType> | undefined>) => void;
}

interface BailEventItem {
    key: string;
    value?: Partial<BailEventType>;
}

const prepareBailEvents = (bailEvents?: Partial<BailEventType>[]) => {
    return bailEvents
        ? bailEvents.map((item) => {
              return item ? { key: item.id ?? uuidv4(), value: item } : { key: uuidv4() };
          })
        : [];
};

export const BailEvents = ({ defaultValues, editMode, onChange: propOnChange }: BailEventProps) => {
    const [open, setOpen] = useState(false);
    const [bailEventItems, setBailEventItems] = useState<BailEventItem[]>(prepareBailEvents(defaultValues));
    const [errors, setErrors] = useState<string[]>([]); // bail event keys

    useEffect(() => {
        propOnChange(bailEventItems?.map((item) => item.value) || []);
    }, [bailEventItems]);

    const addNew = () => {
        setBailEventItems((prev) => prev.concat([{ key: uuidv4() }]));
    };

    const onDelete = (key: string) => {
        return () => {
            setBailEventItems((prev) => prev.filter((item) => item.key !== key));
        };
    };

    const onChange = (key: string) => {
        return (value?: Partial<z.infer<typeof bailEventSchema>>) => {
            setBailEventItems((prev) => {
                const cloned = prev.slice();
                const index = cloned.findIndex((item) => item.key === key);

                if (index !== -1) {
                    cloned[index].value = value;
                }
                return cloned;
            });
        };
    };

    const onError = (key: string) => {
        return (error: boolean) => {
            setErrors((prev) => {
                return prev.filter((item) => item !== key).concat(error ? [key] : []);
            });
        };
    };

    const dropdownOnOpen = () => {
        setOpen(true);
    };

    return (
        <DropDown
            open={open}
            titleContainerClassName={styles.titleContainer}
            className={styles.bailEventsDropdown}
            trigger={(onClick) => (
                <Box display="flex" alignItems="center" justifyContent="space-between" className={styles.trigger}>
                    <Box display="flex" alignItems="center">
                        <Label onClick={onClick} className={styles.triggerLabel} content="Bail Events" />
                        {!!errors.length && (
                            <ErrorMessage marginLeft={8} message="Some of the bail events contain error" />
                        )}
                    </Box>
                    {editMode && open && <AddButton title="+ Add new" stopPropagation onClick={addNew} />}
                </Box>
            )}
            onActiveItem={() => {
                setOpen(true);
            }}
            onDisabledItem={() => {
                setOpen(false);
            }}
            content={
                <Box display="flex" flexDirection="column" className={styles.bailEvents}>
                    <List>
                        {bailEventItems.map((item) => (
                            <BailEventComponent
                                key={item.key}
                                defaultValues={item.value}
                                onDelete={onDelete(item.key)}
                                editMode={editMode}
                                onChange={onChange(item.key)}
                                onError={onError(item.key)}
                                onOpen={dropdownOnOpen}
                            />
                        ))}
                    </List>
                </Box>
            }
        />
    );
};
