import { z } from 'zod';
import { schema } from './Defendant';

export const defendantsMapper = (payload: any) => {
    if (!payload) {
        return {};
    }
    const parsed = Object.entries(payload)
        .map(([key, form]) => {
            const data = schema.deepPartial().safeParse(form);

            if (data.success) {
                return [key, data.data];
            }
            return [key, undefined];
        })
        .filter(Boolean) as [string, z.infer<typeof schema> | undefined][];

    const result: Record<string, z.infer<typeof schema> | undefined> = {};
    parsed.forEach(([key, data]) => {
        result[key] = data;
    });

    return result;
};
