import { gql } from '@apollo/client';

const GET_COURT_CASES = gql`
    query courtCases($filters: CourtCaseFilters) {
        courtCases(filters: $filters) {
            id
            caseForms {
              id
              version
              status
            }
            docket
            name
            court {
                id
                name
            }
            administrativeLevel1 {
                id
                name
            }
            commodities {
                id
                species {
                    commonName
                }
                unit
            }
            startDate
            endDate
            caseDurationDays
            isConcluded
            isConviction
            defenseAttorneyCount
            firstEvent {
                id
                date
                stage
            }
            latestEvent {
                id
                date
                stage
            }
        }
    }
`;

export default GET_COURT_CASES;
