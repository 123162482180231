import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { AxesProps, Palette } from '../types';
import { animated, useTransition, to } from '@react-spring/web';
import { DateTime } from 'luxon';
import { lowerCase, chain, indexOf } from 'lodash';
import { Stack } from '@mui/material';
import Legend, { SeriesLabel } from '../Legend';
import { ChartWrapper } from '../ChartWrapper';
import Gradients from '../Gradients';
import useColorAccessor from '../useColorAccessor';
import { CHART_CONTENT_CLASSNAME } from '../constants';

const defaultPalette: Palette = {
    background: 'transparent',
    text: '#757575',
    grid: '#ddd',
    axes: '#757575',
    marks: {
        default: '#122945',
        blur: '#e9edf2',
        hover: '#f8d6d5',
        active: ['#122945', '#f75151', '#3bbc97', '#a0b9d0', '#414042'],
    },
};

type PieRecord = {
    key: number | string;
    group: number;
    label: string;
    data: number;
    geometry?: {
        type: string;
        coordinates: any[];
    };
    __typename?: string;
    groupLabel?: string;
};

type DonutProps = {
    id: string;
    width: number;
    height: number;
    title?: string;
    description?: string;
    data: any;
    axes?: AxesProps;
    palette?: Palette;
    legendValues?: string[];
    formatLegendLabel?: (label: string) => string;
    groupAccessor?: (key: string) => string;
};

export default function Donut({
    id,
    width,
    height,
    title,
    description,
    data,
    axes,
    legendValues,
    groupAccessor,
    formatLegendLabel,
    palette = defaultPalette,
}: DonutProps) {
    const svgRef = useRef<SVGSVGElement>(null);
    const innerSvg = useRef<SVGSVGElement>(null);
    const legendRef = useRef<HTMLDivElement>(null);
    // eslint-disable-next-line
    const [legendComponent, setLegendComponent] = useState<React.ReactNode | undefined>(undefined);
    const [selectedSeries, setSelectedSeries] = useState<string[]>(data.length === 1 ? ['Yes'] : []);
    const [hoverSeries, setHoverSeries] = useState<string | null>(null);

    useEffect(() => {
        if (!legendRef.current || !svgRef.current) {
            return;
        }

        const boxes = legendRef.current.querySelectorAll('.selectedSeriesBox');
        const svgBounds = svgRef.current.getBoundingClientRect();
        const components = Array.from(boxes).map((box) => {
            // @ts-ignore
            const [markerBlock, textBlock] = box.children;
            const markerBounds = markerBlock.getBoundingClientRect();
            const textBounds = textBlock.getBoundingClientRect();

            const { backgroundColor: markerBoundsBgColor, borderColor: markerBorderColor } =
                getComputedStyle(markerBlock);
            const { color } = getComputedStyle(textBlock);

            return (
                <>
                    <rect
                        x={markerBounds.x - svgBounds.x}
                        y={markerBounds.y - svgBounds.y}
                        width={markerBounds.width}
                        height={markerBounds.height}
                        rx={4}
                        ry={4}
                        fill={markerBoundsBgColor}
                        stroke={markerBorderColor}
                    />
                    <Text
                        x={textBounds.x - svgBounds.x}
                        y={textBounds.y - svgBounds.y + 6}
                        verticalAnchor="start"
                        fontSize={12}
                        fontWeight="light"
                        fill={color}
                    >
                        {textBlock.innerText}
                    </Text>
                </>
            );
        });

        setLegendComponent(<>{components}</>);
    }, [legendRef.current, selectedSeries, width]);

    // eslint-disable-next-line
    const [{ tooltipTop, tooltipLeft }, setTooltipPosition] = useState<{ tooltipTop: number; tooltipLeft: number }>({
        tooltipTop: 0,
        tooltipLeft: 0,
    });

    const { chartWidth, legendWidth } = useMemo(() => {
        const legendWidth = 180;
        const chartWidth = data.length > 1 ? Math.max(width - 180, 0) : Math.max(width, 0);
        return { chartWidth, legendWidth };
    }, [data, width]);

    const legendHeight = useMemo(() => {
        return Math.max(height - 98, 100);
    }, [height]);

    const gap = 16;
    const isPercentage = data.length === 1;
    const formattedDonutData = useMemo(() => {
        if (data.length === 1) {
            // percentage data series
            return data[0].data.map((d) => [
                { ...d, groupLabel: d.label, label: 'Yes' },
                {
                    data: 100 - d.data,
                    label: 'No',
                    __typename: 'Record',
                    key: `${d.key}-remainder`,
                    group: d.group,
                    groupLabel: d.label,
                },
            ]) as PieRecord[][];
        } else {
            const groupedData = chain(data)
                .flatMap(({ key, label, data }, i) => {
                    return data.map((d) => ({
                        ...d,
                        group: d.key,
                        groupLabel: d.label,
                        label: groupAccessor ? groupAccessor(label) : label,
                    }));
                })
                .groupBy('groupLabel')
                .value();

            return Object.values(groupedData) as PieRecord[][];
        }
    }, [data, formatLegendLabel]);

    const desiredMinPieWidth = 200;
    const cols = Math.min(6, Math.floor(width / desiredMinPieWidth));
    const gapWidth = gap * cols;
    const pieWidth = (chartWidth - gapWidth) / cols;

    const dateFormat = useMemo(() => {
        const interval = axes?.bottom?.interval || '';
        if (typeof interval === 'string') {
            switch (lowerCase(interval)) {
                case 'month':
                    return 'LLL y';
                case 'quarter':
                    return 'q y';
                case 'year':
                    return 'y';
                default:
                    return 'D';
            }
        }
        return 'D';
    }, [axes?.bottom]);

    const formatLabel = useCallback(
        (label: string | number) => {
            const formatDateFromMillis = (d: number, format: string) => {
                const prefix = axes?.bottom?.interval === 'quarter' ? 'Q' : '';
                return prefix + DateTime.fromMillis(d).toFormat(format);
            };

            if (axes?.bottom?.isDate && typeof label === 'number') {
                return formatDateFromMillis(label, dateFormat);
            }

            if (typeof label === 'string') {
                return label;
            }
        },
        [axes]
    );

    const seriesLabels = isPercentage
        ? ['Yes', 'No']
        : legendValues
        ? legendValues
        : data.map(({ label }) => `${label}`);

    const colorAccessor = useColorAccessor({ chartId: id, hoverSeries, selectedSeries });

    const donutChartMarginBottom = 24;

    const donutWrapperHeight = Math.ceil(formattedDonutData.length / cols) * pieWidth;
    const donutChartContentHeight = donutWrapperHeight <= 0 ? height : donutChartMarginBottom + donutWrapperHeight;

    return (
        <div style={{ position: 'relative' }}>
            <ChartWrapper ref={svgRef} width={width} height={height} chartLabel={{ title, description }}>
                {({ chartTop, chartHeight }) => (
                    <>
                        <foreignObject y={chartTop} x={0} width={chartWidth} height={chartHeight}>
                            <div
                                style={{
                                    height: '100%',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                }}
                            >
                                <svg
                                    className={CHART_CONTENT_CLASSNAME}
                                    width={chartWidth}
                                    height={donutChartContentHeight}
                                    style={{ position: 'relative', overflowY: 'auto' }}
                                    ref={innerSvg}
                                    onMouseMove={(e) => {
                                        const { x, y } = innerSvg.current
                                            ? innerSvg.current.getBoundingClientRect()
                                            : { x: 0, y: 0 };
                                        setTooltipPosition({ tooltipTop: e.clientY - y, tooltipLeft: e.clientX - x });
                                    }}
                                >
                                    <Gradients chartId={id} palette={palette} />
                                    {formattedDonutData.map((d, i) => {
                                        const row = Math.floor(i / cols);
                                        const col = Math.floor(i - row * cols);
                                        const top = pieWidth / 2 + row * pieWidth;
                                        const left = 32 + pieWidth / 2 + col * pieWidth + gap;

                                        return (
                                            <Group key={i} top={top} left={left}>
                                                <Pie
                                                    data={d}
                                                    pieValue={(dd) => dd.data}
                                                    outerRadius={pieWidth / 2 - gap}
                                                    innerRadius={pieWidth / 2 - gap - 30}
                                                    startAngle={0}
                                                    cornerRadius={3}
                                                    padAngle={0.01}
                                                >
                                                    {(pie) => (
                                                        <AnimatedPie<PieRecord>
                                                            {...pie}
                                                            animate={true}
                                                            getKey={(arc) => `${arc.data.key}`}
                                                            getLabel={(arc) =>
                                                                isPercentage
                                                                    ? (arc.data.data || 0).toFixed(1)
                                                                    : `${arc.data.data}`
                                                            }
                                                            onClickDatum={({ data: { label } }) => console.log(label)}
                                                            getColor={(arc) => colorAccessor(arc.data.label || '')}
                                                        />
                                                    )}
                                                </Pie>
                                                <Text
                                                    x={0}
                                                    y={pieWidth / 2 + 10}
                                                    textAnchor="middle"
                                                    fill="#122945"
                                                    fontSize={12}
                                                    width={pieWidth}
                                                >
                                                    {formatLabel(d[0].groupLabel || '')}
                                                </Text>
                                            </Group>
                                        );
                                    })}
                                </svg>
                            </div>
                        </foreignObject>
                        {typeof palette.marks.active !== 'string' && data.length > 1 && (
                            <foreignObject x={chartWidth} y={64} width={legendWidth} height={legendHeight}>
                                <div
                                    ref={legendRef}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        zIndex: -1,
                                        opacity: 0,
                                        left: 0,
                                    }}
                                >
                                    <Stack
                                        spacing={1}
                                        sx={{
                                            overflow: 'auto',
                                            px: 2,
                                        }}
                                    >
                                        {selectedSeries.map((label) => {
                                            const activeIndex = indexOf(selectedSeries, label);

                                            const bgColor =
                                                activeIndex > -1 ? palette.marks.active[activeIndex] : 'transparent';
                                            const borderColor =
                                                activeIndex > -1 ? palette.marks.active[activeIndex] : '#e9edf2';

                                            return (
                                                <SeriesLabel
                                                    key={label}
                                                    bgColor={bgColor}
                                                    borderColor={borderColor}
                                                    label={label}
                                                    formatLegendLabel={formatLegendLabel}
                                                    className="selectedSeriesBox"
                                                />
                                            );
                                        })}
                                    </Stack>
                                </div>
                                <Legend
                                    seriesLabels={seriesLabels}
                                    hoverSeries={hoverSeries}
                                    setHoverSeries={setHoverSeries}
                                    selectedSeries={selectedSeries}
                                    setSelectedSeries={setSelectedSeries}
                                    palette={palette.marks.active}
                                    height={legendHeight}
                                    width={legendWidth}
                                    formatLegendLabel={formatLegendLabel}
                                />
                            </foreignObject>
                        )}
                    </>
                )}
            </ChartWrapper>
        </div>
    );
}

// react-spring transition definitions
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
    // enter from 360° if end angle is > 180°
    startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
    startAngle,
    endAngle,
    opacity: 1,
});

type AnimatedPieProps<Datum> = ProvidedProps<Datum> & {
    animate?: boolean;
    getKey: (d: PieArcDatum<Datum>) => string;
    getLabel: (d: PieArcDatum<Datum>) => string;
    getColor: (d: PieArcDatum<Datum>) => string;
    onClickDatum: (d: PieArcDatum<Datum>) => void;
    delay?: number;
};

function AnimatedPie<Datum>({
    animate,
    arcs,
    path,
    getKey,
    getLabel,
    getColor,
    onClickDatum,
}: AnimatedPieProps<Datum>) {
    const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
        from: animate ? fromLeaveTransition : enterUpdateTransition,
        enter: enterUpdateTransition,
        update: enterUpdateTransition,
        leave: animate ? fromLeaveTransition : enterUpdateTransition,
        keys: getKey,
    });
    return transitions((props, arc, { key }) => {
        const [centroidX, centroidY] = path.centroid(arc);
        const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

        return (
            <g key={key}>
                <animated.path
                    d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
                        path({
                            ...arc,
                            startAngle,
                            endAngle,
                        })
                    )}
                    fill={getColor(arc)}
                    onClick={() => onClickDatum(arc)}
                    onTouchStart={() => onClickDatum(arc)}
                />
                {hasSpaceForLabel && (
                    <animated.g style={{ opacity: props.opacity }}>
                        <text
                            fill="white"
                            x={centroidX}
                            y={centroidY}
                            dy=".33em"
                            fontSize={9}
                            textAnchor="middle"
                            pointerEvents="none"
                        >
                            {getLabel(arc)}
                        </text>
                    </animated.g>
                )}
            </g>
        );
    });
}
