import { useContext, useState } from 'react';
import { Button, ResponsiveGrid } from '@c4ads/c4blocks';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box } from '@mui/material';
import { pdf } from '@react-pdf/renderer';
import { useMutation } from '@apollo/client';
import { DashboardsContext } from '../DashboardsContext';
import DashboardContext from './DashboardContext';
import useDashboard from './useDashboard';
import Placeholder from '../../../components/PlaceHolder';
import QueryBuilderDialog from '../../../components/QueryBuilderDialog';
import Footer from '../../../components/Footer';
import { UPDATE_DASHBOARD_CONFIG } from '../../../api/apollo/mutation';
import { PDFRenderer } from '../../../components/PDFRenderer';
import { ExportableImage, ExportCallbackType } from '../../../types/DashboardContextType';
import { downloadBlob } from '../../../helpers';
import { FullScreenLoader } from '../../../components/Loader/FullScreenLoader';
import { saveDashboardToLocalStorage } from '../dashboardsLocalStorageManager';
import { useAuth } from '../../../api/auth';
import styles from './Dashboard.module.css';

interface DashboardInterface {
    id: string;
    title: string;
    onAbortFunction: (abort: () => void) => void;
}

export const Dashboard = ({ id, title, onAbortFunction }: DashboardInterface) => {
    const { user } = useAuth();
    const [updateDashboardConfig] = useMutation(UPDATE_DASHBOARD_CONFIG);
    const { addLocalStorageTask, setSavedStatus } = useContext(DashboardsContext);
    const [saved, setSaved] = useState<boolean>(true);
    const {
        editConfig,
        setEditConfig,
        handleEditConfig,
        chartBlocks,
        handleInitChart,
        handleCreateChart,
        handleUpdateChartConfig,
        handleLayoutChange,
        handleRemoveChart,
        handleResetDashboard,
        dashboardConfig,
    } = useDashboard(id, onAbortFunction, (newDashboardConfig) => {
        addLocalStorageTask(() => {
            if (!user) {
                setSaved(false);
                return;
            }
            saveDashboardToLocalStorage(user.id, id, title, newDashboardConfig);
            setSaved(false);
        });
    });
    const [exportableCharts, setExportableCharts] = useState<Record<string, ExportCallbackType>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false); // eslint-disable-line
    const [pdfRendering, setPdfRendering] = useState<boolean>(false);

    const onExport = (callback: ExportCallbackType) => {
        const key = `${new Date().getTime()}`;
        setExportableCharts((prev) => {
            const clonedPrev = { ...prev };

            clonedPrev[key] = callback;
            return clonedPrev;
        });

        return () => {
            setExportableCharts((prev) => {
                const clonedPrev = { ...prev };

                delete clonedPrev[key];
                return clonedPrev;
            });
        };
    };

    // eslint-disable-next-line
    const generatePdfDocument = async () => {
        setPdfRendering(true);
        const keys = Object.keys(exportableCharts);

        const images: ExportableImage[] = [];
        for (let i = 0; i < keys.length; i++) {
            const imgObj = await exportableCharts[keys[i]]();
            if (!imgObj) {
                continue;
            }
            images.push(imgObj);
        }

        const doc = <PDFRenderer images={images} />;

        const blob = await pdf(doc).toBlob();
        downloadBlob(blob, `Dashboard-${new Date().toUTCString()}.pdf`);

        setPdfRendering(false);
    };

    const save = async () => {
        setIsLoading(true);
        await updateDashboardConfig({
            variables: {
                dashboardConfig: {
                    id,
                    title,
                    config: JSON.stringify(dashboardConfig),
                },
            },
        });
        setSaved(true);
        setSavedStatus(id, true);
        setIsLoading(false);
    };

    return (
        <>
            {pdfRendering && <FullScreenLoader />}
            <DashboardContext.Provider
                value={{ dashboardConfig, handleEditConfig, handleUpdateChartConfig, handleRemoveChart, onExport }}
            >
                <Box sx={{ minHeight: 'calc(100vh - 92px - 64px)' }}>
                    {chartBlocks ? (
                        <>
                            <Box className={styles.dashboardButtons}>
                                <Button
                                    className={styles.dashboardButton}
                                    onClick={save}
                                    disabled={saved}
                                    startIcon={<CloudUploadIcon />}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={styles.dashboardButton}
                                    onClick={() => handleInitChart()}
                                    startIcon={<AddIcon />}
                                >
                                    Add
                                </Button>
                                {/* <Button
                                    className={styles.dashboardButton}
                                    disabled={Object.keys(exportableCharts).length === 0}
                                    onClick={generatePdfDocument}
                                    startIcon={<DownloadIcon />}
                                >
                                    Export
                                </Button> */}
                                <Button
                                    className={styles.dashboardButton}
                                    onClick={() => handleResetDashboard()}
                                    startIcon={<RestartAltIcon />}
                                >
                                    Reset
                                </Button>
                            </Box>
                            <ResponsiveGrid layouts={dashboardConfig.layouts} onLayoutChange={handleLayoutChange}>
                                {chartBlocks}
                            </ResponsiveGrid>
                        </>
                    ) : (
                        <Placeholder
                            title="Get Started"
                            message="Click here to create a new chart"
                            onClick={() => handleInitChart()}
                        />
                    )}
                </Box>
                <Footer />
                <QueryBuilderDialog
                    editConfig={editConfig}
                    setEditConfig={setEditConfig}
                    handleCreateChart={handleCreateChart}
                    handleUpdateChartConfig={handleUpdateChartConfig}
                />
            </DashboardContext.Provider>
        </>
    );
};
